define('ceannate-web/mixins/register-confirm-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isDirty: false,
    hasDirtyRelationship: false,
    hasHitNext: false,

    activate: function activate() {
      this.setProperties({
        isDirty: false,
        hasDirtyRelationship: false,
        hasHitNext: false
      });

      window.onbeforeunload = function () {
        return 'Are you sure you want to leave, your changes will not be saved.';
      };
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.controller.get('models')) {
          this.controller.get('models').forEach(function (model) {
            if (model.get('hasDirtyAttributes')) {
              _this.set('isDirty', true);
            }
          });
        } else if (this.controller.get('model')) {
          if (this.controller.get('model.hasDirtyAttributes')) {
            this.set('isDirty', true);
          }
        }

        if ((this.get('isDirty') || this.get('hasDirtyRelationship')) && !this.get('hasHitNext')) {
          var confirmTransition = confirm('Are you sure you want to leave, your changes will not be saved.');
          if (!confirmTransition) {
            transition.abort();
          }
        }
      },
      MarkButtonAsPressed: function MarkButtonAsPressed() {
        this.set('hasHitNext', true);
      },
      MakeRelationshipDirty: function MakeRelationshipDirty() {
        this.set('hasDirtyRelationship', true);
      }
    }
  });
});