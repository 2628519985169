define('ceannate-web/services/eula', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),

    acceptedEula: false,

    eulaRedirectLSToken: 'eula-redirect',

    assignRedirect: function assignRedirect(redirectRoute) {
      if (redirectRoute) {
        window.localStorage.setItem(this.eulaRedirectLSToken, redirectRoute);
        this.set('redirectPath', redirectRoute);
      }
      this.get('router').transitionTo('web.provider.eula');
    },
    handleRedirect: function handleRedirect() {
      var _this = this;

      Ember.run.later(this, function () {
        _this.set('redirectPath', null);
        window.localStorage.removeItem(_this.eulaRedirectLSToken);
      }, 2000);

      this.get('router').transitionTo(window.localStorage.getItem(this.eulaRedirectLSToken) || 'web.provider.workspace.chats');
    },
    accept: function accept(document) {
      this.set('acceptedEula', document);
      if (this.get('session.isAuthenticated')) {
        return this.sign();
      }
    },
    sign: function sign() {
      var acceptedEula = this.get('acceptedEula');

      if (acceptedEula) {
        var signature = this.get('store').createRecord('signature', {
          document: acceptedEula
        });

        return signature.save();
      } else {
        return Error('A EULA has not been accepted.');
      }
    }
  });
});