define('ceannate-web/web/provider/register/create-account/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    'user.firstName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.user.firstName', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().firstName);
      })
    }),
    'user.lastName': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.user.lastName', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().lastName);
      })
    }),
    'user.email': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.user.email', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().email);
      })
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      disabled: Ember.computed('model.user.email', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().email);
      })
    }), (0, _emberCpValidations.validator)('ds-error')],
    'user.password': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.user.password', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().password);
      })
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],
    'user.passwordConfirm': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.user.passwordConfirmation', function () {
        return !Ember.isPresent(this.get('model.user').changedAttributes().passwordConfirm);
      })
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      messageKey: 'form.password-no-match'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'user.password',
      messageKey: 'form.password-no-match',
      disabled: Ember.computed.not('model.user.passwordConfirm')
    })]
  });

  exports.default = Ember.Controller.extend(Validations, {
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    selectedProfessionId: 0,
    selectedDoctorStatus: false,
    hitError: false,
    placeSelected: Ember.computed.bool('practice.location.latitude'),
    practice: Ember.computed.alias('model.practice'),

    isFormInvalid: Ember.computed('validations.{isTruelyValid,isTruelyInvalid,isValidating,isValid,isInvalid}', 'selectedProfessionId', 'selectedDoctorStatus', 'user.{firstName,lastName,email,password,passwordConfirm}', 'practice.location.address', function () {
      var properties = this.get('user').getProperties('firstName', 'lastName', 'email', 'password', 'passwordConfirm', 'isDoctor');
      if (Ember.isEmpty(properties.firstName) || Ember.isEmpty(properties.lastName) || Ember.isEmpty(properties.email) || Ember.isEmpty(properties.password) || Ember.isEmpty(properties.passwordConfirm) || !this.get('selectedDoctorStatus') || this.get('selectedProfessionId') === 0 || Ember.isEmpty(this.get('practice.location.address'))) return true;
      if (this.get('validations.isValidating')) return true;else if (this.get('validations.isTruelyInvalid') || this.get('validations.isInvalid') || this.get('showPracticeAddressErrors') || this.get('showPracticeLatErrors')) return true;else if (this.get('validations.isTruelyValid') || this.get('validations.isValid')) return false;
    }),

    shouldShowErrorAlert: Ember.computed.alias('hitError'),

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$(function () {
        var practice = _this.get('practice');
        _this.$('.place-autocomplete--input').on('change keyup keydown input', function () {
          if (practice && practice.get('location')) {
            var value = _this.$('.place-autocomplete--input').val();
            if (value === '') {
              _this.send('clearPlaceSelected');
            }
          }
        });
        _this.$('#practice-tel').mask('000-000-0000');
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
    },


    focusedOutPracticeAddress: false,
    hasPracticeAddressContent: Ember.computed.notEmpty('practice.location.address'),
    showPracticeAddressValidations: Ember.computed.or('focusedOutPracticeAddress', 'hasPracticeAddressContent'),
    showPracticeAddressErrors: Ember.computed.and('showPracticeAddressValidations', 'practice.validations.attrs.location.address.isInvalid'),
    showPracticeLatErrors: Ember.computed.and('hasPracticeAddressContent', 'focusedOutPracticeAddress', 'practice.validations.attrs.location.latitude.isInvalid'),

    actions: {
      changeProfession: function changeProfession(id) {
        this.set('selectedProfessionId', id);
        this.set('user.profession', this.get('professions').findBy('id', id).name);
      },
      changeProviderDoctor: function changeProviderDoctor(choice) {
        if (choice) {
          this.get('user').set('prefix', 'Dr.');
        }
        this.set('selectedDoctorStatus', true);
      },
      focusOutPracticeAddress: function focusOutPracticeAddress() {
        this.set('focusedOutPracticeAddress', true);
      },
      clearPlaceSelected: function clearPlaceSelected() {
        var practice = this.get('practice');
        practice.set('location', Ember.Object.create());
        this.set('placeSelected', false);
      },
      onPlaceChange: function onPlaceChange(place) {
        this.send('clearPlaceSelected');
        var practice = this.get('practice');
        if (place && place.geometry) {
          practice.set('location.latitude', place.geometry.location.lat());
          practice.set('location.longitude', place.geometry.location.lng());
          this.set('placeSelected', true);
        } else {
          this.set('placeSelected', false);
        }
      }
    }
  });
});