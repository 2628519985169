define('ceannate-web/mirage/factories/specialty-category', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.word();
    },
    profession: function profession() {
      return _emberCliMirage.faker.random.arrayElement(['Mental Health', 'Lawyer']);
    },

    afterCreate: function afterCreate(specialtyCategory, server) {
      if (specialtyCategory.id !== 'acceptanceTest1' && specialtyCategory.id !== 'acceptanceTest2') {
        var specialties = server.createList('specialty', 10, {
          specialtyCategory: specialtyCategory
        });
        specialtyCategory.specialties = specialties;
      }
    }
  });
});