define('ceannate-web/models/counseling-session', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    streamType: _emberData.default.attr('string'),
    provider: _emberData.default.belongsTo('provider'),
    student: _emberData.default.belongsTo('student'),
    tokboxSessionId: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('string'),
    endedAt: _emberData.default.attr('string')
  });
});