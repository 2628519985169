define('ceannate-web/web/provider/workspace/protips/route', ['exports', 'ceannate-web/utils/article-infinity-model-options'], function (exports, _articleInfinityModelOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },

    deactivate: function deactivate() {
      this.controller.set('selectedArticleId', null);
    },
    model: function model() {
      return Ember.RSVP.hash({
        articles: this.get('infinity').model('article', _articleInfinityModelOptions.default)
      });
    }
  });
});