define('ceannate-web/models/provider-university-charge', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    universityBillableAmount: _emberData.default.attr('number'),
    providerClaimBillableAmount: _emberData.default.attr('number'),
    reimbursedAmount: _emberData.default.attr('number'),
    reimbursedDate: _emberData.default.attr('date'),
    university: _emberData.default.belongsTo('university'),
    student: _emberData.default.belongsTo('student'),
    charge: _emberData.default.belongsTo('charge'),
    providerUniversityProgram: _emberData.default.belongsTo('provider-university-program'),
    program: _emberData.default.belongsTo('program'),
    paymentOption: _emberData.default.belongsTo('payment_option')
  });
});