define('ceannate-web/mirage/factories/session', ['exports', 'ember-cli-mirage', 'ceannate-web/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: function id() {
      return (0, _utils.getToken)();
    }
  });
});