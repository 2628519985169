define('ceannate-web/web/provider/workspace/settings/previous-invoices/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    model: function model() {
      var providerId = this.get('currentUser.provider.id');

      return Ember.RSVP.hash({
        charges: this.store.query('charge', {
          providerId: providerId,
          include: 'target, creator,student_charge,provider_university_charge.university,provider_university_charge.program,counseling_sessions'
        }),
        providerId: providerId
      });
    }
  });
});