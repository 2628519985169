define('ceannate-web/helpers/stripe-oauth-href', ['exports', 'ceannate-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stripeOauthHref = stripeOauthHref;
  function stripeOauthHref(params /*, hash*/) {
    var href = 'https://connect.stripe.com/oauth/authorize?client_id=' + _environment.default.stripeClientID;
    if (params[0]) {
      href = href + '&redirect_uri=' + params[0] + '&scope=read_write&response_type=code';
    }

    return href;
  }

  exports.default = Ember.Helper.helper(stripeOauthHref);
});