define('ceannate-web/services/phone-input', ['exports', 'ember-phone-input/services/phone-input'], function (exports, _phoneInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _phoneInput.default;
    }
  });
});