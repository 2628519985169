define('ceannate-web/components/provider/license-credentials/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    licenseNumber: (0, _emberCpValidations.validator)('presence', true),
    region: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = Ember.Component.extend(Validations, {
    classNames: ['component__license-credentials'],
    store: Ember.inject.service(),

    credentials: null,
    readOnly: false,
    provider: null,
    regions: null,

    onChangeDocument: null,

    licenseNumber: null,
    region: null,

    hasLicense: Ember.computed.notEmpty('licenseNumber'),
    hasRegion: Ember.computed.notEmpty('region'),

    filteredCredentials: Ember.computed.filterBy('provider.credentials', 'isDeleted', false),

    actions: {
      addNewCredential: function addNewCredential() {
        var provider = this.get('provider');

        var hipaaDocument = this.get('store').createRecord('document', {
          type: 'hipaa',
          active: true,
          provider: provider
        });

        var consentDocument = this.get('store').createRecord('document', {
          type: 'consent',
          active: true,
          provider: provider
        });

        var credential = this.get('store').createRecord('credential', {
          licenseNumber: null,
          region: null,
          documents: [hipaaDocument, consentDocument]
        });

        this.get('provider.credentials').pushObject(credential);
      }
    }
  });
});