define('ceannate-web/web/provider/workspace/protips/controller', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_bem.default, {
    blockName: 'r_protips',

    queryParams: ['page', 'size'],
    page: 1,
    size: 25,

    selectedArticleId: null,

    filteredArticles: Ember.computed.filterBy('model.articles', 'status', 'published'),

    actions: {
      viewArticle: function viewArticle(article) {
        this.set('selectedArticleId', article.get('id'));
        this.transitionToRoute('web.provider.workspace.protips.article', article);
      }
    }
  });
});