define('ceannate-web/models/document', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    src: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    src: _emberData.default.attr('string'),
    html: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    signedDate: _emberData.default.attr('date'),
    signature: _emberData.default.hasMany('signature'),
    provider: _emberData.default.belongsTo('provider'),
    credential: _emberData.default.belongsTo('credential'),
    isValid: Ember.computed('validations.{isValid,isTruelyValid}', function () {
      return this.get('validations.isValid') || this.get('validations.isTruelyValid');
    }),
    active: _emberData.default.attr('boolean')
  });
});