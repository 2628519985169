define('ceannate-web/models/article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    articleType: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    notificationTitle: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    notificationBody: _emberData.default.attr('string'),
    contentHtml: _emberData.default.attr('string'),
    hasViewed: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    publishedAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    editedAt: _emberData.default.attr('date'),

    author: _emberData.default.belongsTo('user')
  });
});