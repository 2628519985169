define('ceannate-web/components/hours-of-operation/component', ['exports', 'moment', 'ember-cli-bem/mixins/bem'], function (exports, _moment, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    classNames: ['component__hours-of-operation'],
    blockName: 'c_hours-of-operation',

    i18n: Ember.inject.service(),

    selectableTimes: null,
    timezones: null,
    emptyError: null,
    emptyErrorBoolean: Ember.computed.bool('emptyError'),
    businessHours: null,
    onCreateBusinessHours: null,
    onDestroyBusinessHours: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('timezones', Ember.A(['PMT', 'MST', 'CST', 'EST']));
      this.set('selectableTimes', Ember.A([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(function (hour) {
        if (hour === 24) {
          return (0, _moment.default)().hour(23).minute(59).format('hh:mm A');
        }
        return (0, _moment.default)().hour(hour).format('h A');
      })));
    },
    didUpdate: function didUpdate() {
      var hasNoHours = !this.get('businessHours.length');
      // Prevent infinite loop
      if (hasNoHours && this.get('emptyError') !== null) return;
      if (hasNoHours) {
        this.set('emptyError', this.get('i18n').t('components.hours-of-operation.empty-error'));
      } else if (this.get('emptyError') !== null) {
        this.set('emptyError', null);
      }
    }
  });
});