define('ceannate-web/utils/encrypt', ['exports', 'cryptojs', 'ceannate-web/config/environment'], function (exports, _cryptojs, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.encode = encode;
  exports.decode = decode;
  function encode(val) {
    return encodeURI(_cryptojs.default.AES.encrypt(val.toString(), _environment.default.urlHash).toString());
  }

  function decode(val) {
    var bytes = _cryptojs.default.AES.decrypt(decodeURI(val).toString(), _environment.default.urlHash);
    return bytes.toString(_cryptojs.default.enc.Utf8);
  }
});