define('ceannate-web/components/consent-documents/document-list/component', ['exports', 'ember-cli-bem/mixins/bem', 'moment'], function (exports, _bem, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_document-list',
    mods: ['status'],

    modalOpen: false,
    patientRelationship: null,
    documents: null,

    disabled: false,

    activeDocuments: Ember.computed.filterBy('documents', 'active', true),

    status: Ember.computed.readOnly('patientRelationship.status'),

    titles: Ember.computed('documents.[]', 'status', function () {
      var _this = this;

      return this.get('documents').map(function (document) {
        var title = '';

        if (_this.get('status') === 'pending') title += 'Unsigned ';else if (_this.get('status') === 'active') title += 'Signed ';

        if (document.get('type') === 'hipaa') title += 'HIPAA';else title += document.get('type');

        return title;
      });
    }),

    descriptions: Ember.computed('patientRelationship.credentialAssociatedAt', 'documents.[]', 'status', function () {
      var _this2 = this;

      return this.get('documents').map(function () {
        if (_this2.get('status') === 'pending') {
          return 'Sent On: ' + (0, _moment.default)(_this2.get('patientRelationship.credentialAssociatedAt')).format('MM/DD/YY');
        } else if (_this2.get('status') === 'active') {
          return 'Agreed On: ' + (0, _moment.default)(_this2.get('patientRelationship.signatures.firstObject.createdAt')).format('MM/DD/YY');
        }
        return '';
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      launchModal: function launchModal() {
        this.set('modalOpen', true);
      },
      onRevokeRelationship: function onRevokeRelationship() {
        this.get('onRevokeRelationship')();
        this.send('closeModal');
      },
      closeModal: function closeModal() {
        this.set('modalOpen', false);
      }
    }
  });
});