define('ceannate-web/components/provider/edit-insurance/component', ['exports', 'ember-cli-bem/mixins/bem', 'ember-lifeline'], function (exports, _bem, _emberLifeline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_provider__edit-insurance',

    showModal: false,

    identificationSrc: Ember.computed.or('recentUploadData', 'provider.identificationSrc'),

    destroy: function destroy() {
      (0, _emberLifeline.runDisposables)(this);

      this._super.apply(this, arguments);
    },


    actions: {
      addInsuranceSelections: function addInsuranceSelections(newSelections) {
        var provider = this.get('provider');
        provider.get('insuranceCompanies').pushObjects(newSelections);
      },
      updateIdentificationSrc: function updateIdentificationSrc(newSrcUrl, file) {
        var _this = this;

        var reader = new FileReader();
        (0, _emberLifeline.addEventListener)(this, reader, 'load', function () {
          _this.set('recentUploadData', reader.result);
          _this.set('provider.identificationSrc', newSrcUrl);
        });
        reader.readAsDataURL(file);
      },
      removeInsuranceCompany: function removeInsuranceCompany(company) {
        var provider = this.get('provider');
        provider.get('insuranceCompanies').removeObject(company);
      },
      toggleInsurance: function toggleInsurance() {
        this.toggleProperty('provider.doesNotAcceptInsurance');

        if (this.get('provider.doesNotAcceptInsurance')) {
          this.get('provider.insuranceCompanies').clear();
        }
      }
    }
  });
});