define('ceannate-web/mixins/file-upload-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    globalError: Ember.inject.service(),

    fileName: null,
    fileType: null,
    fileRecord: null,
    blob: null,
    success: false,
    error: false,
    percentage: 0,
    finishedUpload: false,
    tmpFile: null,

    generateFileRecord: function generateFileRecord() {
      // Create new file record for this mixin
      this.set('fileRecord', this.get('store').createRecord('file', {}));
    },
    setFileRecordData: function setFileRecordData(filename, type) {
      this.setProperties({ fileName: filename, fileType: type });
      this.get('fileRecord').setProperties({
        filename: filename,
        type: type
      });
    },
    saveRecord: function saveRecord(file) {
      var formFile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var _this2 = this;

      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var funcObj = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      return this.get('fileRecord').save().then(function (record) {
        _this2.setProperties({
          success: true,
          error: false
        });

        if (formFile) _this2.uploadToPresignedURL(file, record, funcObj);else _this2.uploadDataToPresignedURL(file, record, data, funcObj);
        return record;
      }).catch(function () {
        _this2.setProperties({
          success: false,
          error: true
        });
        _this2.get('globalError').addError('There was an error uploading your file. Please check your connection and try again.');
      });
    },
    buildRequestHeaders: function buildRequestHeaders() {
      return {
        'x-amz-server-side-encryption': 'AES256'
      };
    },


    /**
     * Upload form data to S3, this case should only be used when working with
     * formData and not when working with blob data that's used by cropping.
     *
     * @param file FileList
     */
    uploadToPresignedURL: function uploadToPresignedURL(file, record, funcObj) {
      var _this = this,
          presignedURL = record.get('src'),
          formData = new FormData();

      formData.append('file', file);

      Ember.$.ajax(presignedURL, {
        method: 'PUT',
        data: file,
        processData: false,
        contentType: false,
        headers: this.buildRequestHeaders(),
        xhr: function xhr() {
          var xhr = new window.XMLHttpRequest();

          // Progress
          xhr.upload.addEventListener('progress', function (evt) {
            _this.set('finishedUpload', false);
            if (evt.lengthComputable) {
              var percentCompleted = Math.round(evt.loaded / evt.total * 100, 1);
              if (percentCompleted > 100) percentCompleted = 100;

              Ember.run(function () {
                _this.set('percentage', percentCompleted);

                if (percentCompleted >= 100) {
                  _this.set('finishedUpload', true);
                }
              });
            }
          });

          return xhr;
        }
      }).done(function () {
        Ember.run(function () {
          _this.set('percentage', 100);
          _this.set('finishedUpload', true);
          var tmpUrl = presignedURL.split('?')[0];
          _this.set('tmpFile', tmpUrl);
          if (funcObj && funcObj.success) {
            funcObj.success(tmpUrl);
          }
        });
      }).catch(function () {});
    },
    uploadDataToPresignedURL: function uploadDataToPresignedURL(file, record, data, funcObj) {
      var _this3 = this;

      var presignedURL = record.get('src');

      // Convert base64 to blob
      fetch(data).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        Ember.$.ajax(presignedURL, {
          method: 'PUT',
          data: blob,
          processData: false,
          contentType: false,
          headers: _this3.buildRequestHeaders()
        }).done(function () {
          Ember.run(function () {
            var tmpUrl = presignedURL.split('?')[0];
            if (funcObj && funcObj.success) {
              funcObj.success(tmpUrl);
            }
          });
        }).catch(function () {});
      });
    },
    removeVideo: function removeVideo() {
      this.set('fileRecord', this.get('store').createRecord('file', {}));
      this.setProperties({
        fileName: null,
        fileType: null,
        success: false,
        error: false,
        percentage: 0,
        finishedUpload: false
      });
    }
  });
});