define('ceannate-web/mirage/resources/s3', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockAmazonS3;
  function mockAmazonS3(server) {
    server.put('https://ora-bucket.s3.amazonaws.com/video/**', function () {
      return {};
    }, 200, { timing: 2000 });
  }
});