define('ceannate-web/components/provider/specialisation-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__specialisation-selection'],

    categories: null,
    user: null,
    hasUserInteracted: false,
    hasSpecialisationsSelected: false,

    showError: Ember.computed('hasUserInteracted', 'hasSpecialisationsSelected', function () {
      return this.get('hasUserInteracted') && !this.get('hasSpecialisationsSelected');
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);

      // offset to fix issues with not being valid on load,
      // this requires the didRender since this rendered
      // multiple times and changes so it needs to be able
      // to track this count properly
      this.set('hasSpecialisationsSelected', this.get('specialisations.length') > 0);
    },


    actions: {
      selectSpecialty: function selectSpecialty(specialty) {
        this.set('hasUserInteracted', true);
        var specialisations = this.get('specialisations').indexOf(specialty);
        if (specialisations > -1) {
          this.get('specialisations').removeObject(specialty);
        } else {
          this.get('specialisations').addObject(specialty);
        }

        this.set('hasSpecialisationsSelected', this.get('specialisations.length') > 0);

        if (this.get('onChangeSpecialties')) {
          this.get('onChangeSpecialties')();
        }
      }
    }
  });
});