define('ceannate-web/authorizers/ceannate', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),

    authorize: function authorize(data, block) {
      block('Authorization', 'Bearer ' + this.get('session.data.token'));
    }
  });
});