define('ceannate-web/utils/article-infinity-model-options', ['exports', 'ceannate-web/utils/jsonapi-infinity-model-options'], function (exports, _jsonapiInfinityModelOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Object.assign({
    infinityCache: 0,
    include: 'user',
    filter: {
      article_type: 'provider'
    },
    sort: '-published_at'
  }, _jsonapiInfinityModelOptions.default);
});