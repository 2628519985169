define('ceannate-web/mirage/factories/message', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    body: function body() {
      return _emberCliMirage.faker.lorem.sentence();
    },

    createdAt: function createdAt() {
      return new Date('December 17, 1995 03:24:00');
    }
  });
});