define('ceannate-web/services/notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    threadNotification: function threadNotification(data) {
      if (data.meta.creator_id !== this.get('currentUser.provider.userUuid')) {
        if (document.hidden) {
          Push.create('New Message', {
            body: 'A student has messaged you',
            icon: '/assets/images/app-icon.png',
            timeout: 5000
          });
        }
      }
    },
    tokboxNotification: function tokboxNotification(data) {
      if (data.data.relationships.creator.data.id !== this.get('currentUser.provider.id')) {
        if (document.hidden) {
          if (data.data.attributes.stream_type === 'video') {
            Push.create('Incoming Video Call', {
              body: 'A student is calling you',
              icon: '/assets/images/app-icon.png',
              timeout: 5000
            });
          } else if (data.data.attributes.stream_type === 'audio') {
            Push.create('Incoming Call', {
              body: 'A student is calling you',
              icon: '/assets/images/app-icon.png',
              timeout: 5000
            });
          }
        }
      }
    },
    articleNotification: function articleNotification(article) {
      var _this = this;

      if (document.hidden) {
        Push.create(article.get('notificationTitle'), {
          body: article.get('notificationBody'),
          icon: '/assets/images/app-icon.png',
          timeout: 5000,
          onClick: function onClick() {
            _this.get('routing.router').transitionTo('web.provider.workspace.protips.article', article);
            window.focus();
          }
        });
      }
    }
  });
});