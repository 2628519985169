define('ceannate-web/web/provider/workspace/clients/route', ['exports', 'ceannate-web/utils/thread-infinity-model-options'], function (exports, _threadInfinityModelOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        threads: this.get('infinity').model('thread', _threadInfinityModelOptions.default),
        blocks: this.store.query('block', {
          include: 'student,provider',
          'filter[provider_id]': this.get('currentUser.provider.id')
        }).then(function () {
          return _this.store.peekAll('block');
        })
      });
    },
    setupController: function setupController() {
      return this._super.apply(this, arguments);
    }
  });
});