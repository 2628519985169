define('ceannate-web/web/provider/workspace/settings/payment-method/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),

    deactivate: function deactivate() {
      this.controller.setProperties({
        isEditing: false,
        toastVariant: null,
        toastMessage: null
      });
      this.get('toast').hide();
    },
    model: function model() {
      var provider = this.get('currentUser.provider');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'user_subscriptions'
        })
      });
    }
  });
});