define('ceannate-web/mirage/resources/threads', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockThreads;
  function mockThreads(server) {
    server.get('/threads');
    server.get('/threads/:id');
    server.post('/threads');
    server.patch('/threads/:id');
  }
});