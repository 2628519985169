define('ceannate-web/web/provider/workspace/settings/change-password/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),
    deactivate: function deactivate() {
      this.controller.setProperties({
        isEditing: false,
        newPassword: null,
        newPasswordConfirmation: null,
        currentPassword: null,
        currentPasswordInvalid: false,
        didSubmit: false
      });
      this.get('toast').hide();
    }
  });
});