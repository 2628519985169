define('ceannate-web/models/degree', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    university: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('ds-error')],
    graduationYear: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true,
      gte: 1000,
      lt: 10000
    }), (0, _emberCpValidations.validator)('ds-error')],
    degree: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    degree: _emberData.default.attr('string'),
    university: _emberData.default.attr('string'),
    graduationYear: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    provider: _emberData.default.belongsTo('provider'),

    isValid: Ember.computed('validations.{isValid,isTruelyValid}', function () {
      return this.get('validations.isValid') || this.get('validations.isTruelyValid');
    })
  });
});