define('ceannate-web/web/provider/workspace/clients/client/report/route', ['exports', 'ceannate-web/utils/encrypt'], function (exports, _encrypt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var provider = this.get('currentUser.provider');
      var creator = provider.get('user');

      var relationshipId = (0, _encrypt.decode)(this.paramsFor('web.provider.workspace.clients.client').patient_relationship_id);

      var relationship = this.store.peekRecord('patient-relationship', relationshipId);

      return Ember.RSVP.hash({
        report: this.store.createRecord('report', {
          provider: provider,
          student: relationship.get('student'),
          creator: creator
        }),
        relationship: relationship
      });
    }
  });
});