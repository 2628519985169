define('ceannate-web/components/chat/typing-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__chats_typing-indicator'],
    classNameBindings: ['visible:visible']
  });
});