define('ceannate-web/components/chat/typing-box/component', ['exports', 'lodash/throttle'], function (exports, _throttle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__typing-box'],

    newMessage: '',

    timeout: null,
    sendTypingSignal: null,
    typingSignalInterval: 1000,

    isDisabled: Ember.computed('newMessage', function () {
      return !this.get('newMessage').length;
    }),

    resetTextAreaHeight: function resetTextAreaHeight() {
      var textarea = document.getElementsByClassName('component__chat_typing-box_input')[0];

      textarea.setAttribute('style', '');
      textarea.value = '';
    },
    textAreaAdjust: function textAreaAdjust(value, e) {
      var elm = e.target;
      elm.style.height = elm.scrollHeight + 'px';
    },
    handleUserType: function handleUserType() {
      clearTimeout(this.get('timeout'));
      this.get('handleUserTyping').bind(this)();
      this.set('timeout', setTimeout(this.get('doneTyping').bind(this), this.get('typingSignalInterval')));
    },


    handleUserTyping: (0, _throttle.default)(function () {
      this.get('sendTypingSignal')(true);
    }, 1000),

    doneTyping: function doneTyping() {
      this.get('sendTypingSignal')(false);
    },


    actions: {
      submit: function submit() {
        this.onNewMessage(this.get('newMessage'));
        this.set('newMessage', '');

        this.resetTextAreaHeight();
      },
      handleKeyDown: function handleKeyDown(value, e) {
        if (e.keyCode === 13 && e.shiftKey === false) {
          e.preventDefault();
          this.send('submit');
        }
      },
      handleKeyUp: function handleKeyUp(value, e) {
        this.textAreaAdjust(value, e);
        this.handleUserType();
      }
    }
  });
});