define('ceannate-web/web/provider/workspace/settings/controller', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_bem.default, {
    blockName: 'r_settings',
    currentUser: Ember.inject.service(),
    provider: Ember.computed.readOnly('currentUser.provider')
  });
});