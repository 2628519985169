define('ceannate-web/mixins/track-inactivity-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    inactivity: Ember.inject.service(),

    activate: function activate() {
      this._super.apply(this, arguments);
      this.get('inactivity').startTimer();
    },
    deactivate: function deactivate() {
      this.get('inactivity').stopTimer();
      this._super.apply(this, arguments);
    }
  });
});