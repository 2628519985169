define('ceannate-web/models/practice', ['exports', 'ember-data', 'ember-cp-validations', 'ceannate-web/utils/model-utils'], function (exports, _emberData, _emberCpValidations, _modelUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')],
    'location.address': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')],
    'location.latitude': {
      validators: [(0, _emberCpValidations.validator)('presence', true)],
      messageKey: 'form.address-error'
    },
    'location.longitude': {
      validators: [(0, _emberCpValidations.validator)('presence', true)],
      messageKey: 'form.address-error'
    },
    paymentOptions: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 500 }), (0, _emberCpValidations.validator)('ds-error')],
    phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'phone'
    })],
    providerPhoneNumber: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    location: _emberData.default.attr('object'),
    phone: _emberData.default.attr('phone'),
    paymentOptions: _emberData.default.attr('string'),
    isAcceptingClients: _emberData.default.attr('boolean', { defaultValue: true }),

    provider: _emberData.default.belongsTo('provider'),
    businessHours: _emberData.default.hasMany('business-hour', {
      defaultValue: [],
      async: false
    }),

    dashNotatedPhone: (0, _modelUtils.dashNotatedPhone)('phone'),

    shouldNotifySms: _emberData.default.attr('boolean'),
    providerPhoneNumber: _emberData.default.attr('string')
  });
});