define('ceannate-web/adapters/subscription-order', ['exports', 'ceannate-web/adapters/application', 'ember-inflector'], function (exports, _application, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    }
  });
});