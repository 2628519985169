define('ceannate-web/models/block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    provider: _emberData.default.belongsTo('provider', { async: false }),
    student: _emberData.default.belongsTo('student', { async: false }),
    report: _emberData.default.belongsTo('report', { async: false })
  });
});