define('ceannate-web/components/provider/provider-signup-program/de-enrolled-programs/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_de-enrolled-programs',
    tagName: 'tr',
    classNames: [''],
    infinity: Ember.inject.service(),
    store: Ember.inject.service(),
    historyProviderUniversityPrograms: null,
    provider: null,
    hasMore: Ember.computed('historyProviderUniversityPrograms.meta.pagination', function () {
      return this.get('historyProviderUniversityPrograms.meta.pagination.current_page') <= this.get('historyProviderUniversityPrograms.meta.pagination.total_pages') ? true : false;
    }),
    actions: {
      loadMore: function loadMore() {
        var _this = this;

        if (this.get('hasMore')) {
          return this.get('infinity').model('provider-university-program', {
            page: {
              number: this.get('historyProviderUniversityPrograms.meta.pagination.current_page') + 1,
              size: this.get('historyProviderUniversityPrograms.meta.pagination.per_page')
            },
            'filter[active]': 0,
            'filter[provider_id]': this.get('provider').id,
            sort: '-created_at',
            include: 'provider,university_program,payment_option.license_type.region'
          }).then(function (res) {
            if (res.toArray() == '') {
              _this.set('hasMore', null);
            }
            _this.get('historyProviderUniversityPrograms').addObjects(res);
            _this.set('historyProviderUniversityPrograms.meta.pagination.current_page', res.meta.pagination.current_page);
            _this.set('historyProviderUniversityPrograms.meta.pagination.per_page', res.meta.pagination.per_page);
          });
        } else {
          return;
        }
      }
    }
  });
});