define('ceannate-web/components/modals/user-validated/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),

    tagName: '',
    open: false,

    didRender: function didRender() {
      var cs = this.get('cookies');
      var today = new Date();
      // Expires in 30 Days
      today.setHours(today.getHours() + 720);
      if (!cs.exists('uvModal')) {
        cs.write('uvModal', true, {
          expires: today
        });
        this.set('open', true);
      } else {
        this.set('open', false);
      }
    }
  });
});