define('ceannate-web/mirage/resources/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockUsers;
  function mockUsers(server) {
    server.get('/users');
    server.get('/users/:id');
    server.post('/users');
    server.put('/users/:id');
    server.patch('/users/:id');
    server.del('/users/:id');
  }
});