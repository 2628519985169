define('ceannate-web/web/provider/workspace/settings/insurance/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        provider: this.get('store').findRecord('provider', this.get('currentUser.provider.id'), {
          include: 'insurance_companies',
          reload: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      var provider = model.provider;
      provider.set('doesNotAcceptInsurance', provider.get('insuranceCompanies.length') === 0);

      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      var provider = this.modelFor(this.routeName).provider;
      provider.rollbackAttributes();
    }
  });
});