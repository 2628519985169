define('ceannate-web/mirage/resources/credentials', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockCredentials;
  function mockCredentials(server) {
    server.get('/credentials');
    server.get('/credentials/:id');
    server.post('/credentials');
    server.put('/credentials/:id');
    server.patch('/credentials/:id');
    server.del('/credentials/:id');
  }
});