define('ceannate-web/web/provider/register/subscription/route', ['exports', 'ceannate-web/mixins/register-confirm-transition'], function (exports, _registerConfirmTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_registerConfirmTransition.default, {
    eula: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        subscription: this.store.createRecord('subscription-order')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('orders', this.store.peekAll('subscription-order'));
    }
  });
});