define('ceannate-web/models/insurance-plan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    memberId: _emberData.default.attr('string'),
    groupId: _emberData.default.attr('string'),
    information: _emberData.default.attr('string'),
    cardholderFirstName: _emberData.default.attr('string'),
    cardholderLastName: _emberData.default.attr('string'),
    cardholderDateOfBirth: _emberData.default.attr('string'),
    insuranceSource: _emberData.default.attr('string'),
    student: _emberData.default.belongsTo('student', { async: false }),
    insuranceCompany: _emberData.default.belongsTo('insurance-company', { async: false })
  });
});