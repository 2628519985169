define('ceannate-web/serializers/subscription', ['exports', 'ceannate-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var value = snapshot.attr(key);
      if (!value) {
        return;
      }
      this._super(snapshot, json, key, attributes);
    }
  });
});