define('ceannate-web/adapters/student-charge', ['exports', 'ceannate-web/adapters/application', 'ceannate-web/config/environment', 'ember-inflector'], function (exports, _application, _environment, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'student_charges';
    },

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'query') {
        var providerId = query.providerId;

        return _environment.default.apiUrl + '/' + _environment.default.apiNS + '/providers/' + providerId + '/relationships/' + Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
      }
      return this._super.apply(this, arguments);
    }
  });
});