define('ceannate-web/web/provider/workspace/chats/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('web/provider/workspace/chats');
      return {
        threads: parentModel.threads
      };
    }
  });
});