define('ceannate-web/components/provider/language-selector/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    classNames: ['component__language-selection'],
    blockName: 'c_language-selection',

    categories: null,
    user: null,
    hasUserInteracted: false,
    hasLanguagesSelected: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('providerLanguages.length') === 0) {
        this.get('providerLanguages').addObject(this.get('languages').findBy('name', 'English'));
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      // offset to fix issues with not being valid on load,
      // this requires the didRender since this rendered
      // multiple times and changes so it needs to be able
      // to track this count properly
      this.set('hasLanguagesSelected', this.get('providerLanguages.length') > 0);
    },


    actions: {
      selectLanguage: function selectLanguage(language) {
        this.set('hasUserInteracted', true);
        var languages = this.get('providerLanguages').indexOf(language);
        if (languages > -1) {
          this.get('providerLanguages').removeObject(language);
        } else {
          this.get('providerLanguages').addObject(language);
        }

        this.set('hasLanguagesSelected', this.get('providerLanguages.length') > 0);

        if (this.get('onChangeLanguages')) {
          this.get('onChangeLanguages')();
        }
      }
    }
  });
});