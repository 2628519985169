define('ceannate-web/mirage/resources/password-resets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockPasswordResets;
  function mockPasswordResets(server) {
    server.post('/password_resets');
  }
});