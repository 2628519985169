define('ceannate-web/web/provider/register/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    isCreateAccountRoute: Ember.computed.equal('routing.currentRouteName', 'web.provider.register.create-account'),
    isRegisterView: Ember.computed.not('isCreateAccountRoute'),

    steps: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('steps', [this.get('i18n').t('register.steps.intro'), this.get('i18n').t('register.steps.credentials'), this.get('i18n').t('register.steps.insurance'), this.get('i18n').t('register.steps.practice')]);
    }
  });
});