define('ceannate-web/mirage/resources/signatures', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockSignatures;
  function mockSignatures(server) {
    server.get('/signatures');
    server.get('/signatures/:id');
    server.post('/signatures');
  }
});