define('ceannate-web/adapters/session', ['exports', 'ceannate-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
      }

      return this._super.apply(this, arguments);
    }
  });
});