define('ceannate-web/web/provider/register/practice/route', ['exports', 'ceannate-web/mixins/register-confirm-transition'], function (exports, _registerConfirmTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_registerConfirmTransition.default, {
    currentUser: Ember.inject.service(),
    eula: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    },
    model: function model() {
      var provider = this.get('currentUser.user');

      return Ember.RSVP.hash({
        practice: this.store.createRecord('practice', {
          provider: provider,
          location: {
            address: null
          }
        }),
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'practice.business_hours',
          reload: true
        }),
        notifications: this.store.query('notification', {}),
        providerNotifications: this.store.query('provider-notification', {
          'filter[active]': 1,
          'filter[provider_id]': provider.get('id')
        }).then(function (providerNotifications) {
          return providerNotifications;
        }),
        providerNotificationsArray: this.store.query('provider-notification', {
          'filter[active]': 1,
          'filter[provider_id]': provider.get('id')
        }).then(function (providerNotificationsArray) {
          return providerNotificationsArray.toArray();
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('provider', model.provider);
      if (model.provider.get('practice')) {
        controller.set('practice', model.provider.get('practice'));
        controller.set('models', [model.provider, model.provider.get('practice')]);
        controller.set('providerNotifications', model.providerNotifications);
        controller.set('notifications', model.notifications);
        controller.set('providerNotificationsArray', model.providerNotificationsArray);
      } else {
        controller.set('practice', model.practice);
        controller.set('models', [model.provider, model.practice]);
        controller.set('notifications', model.notifications);
        controller.set('providerNotifications', model.providerNotifications);
        controller.set('providerNotificationsArray', model.providerNotificationsArray);
      }
    }
  });
});