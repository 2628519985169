define('ceannate-web/components/callout-container/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_callout-container',
    icon: '/assets/icons/icon-bubble.svg',
    iconAlt: ''
  });
});