define('ceannate-web/web/provider/workspace/settings/change-email-address/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() {
      return this.store.createRecord('email-verification', {
        email: this.get('currentUser.user.email')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('emailVerification', model);
    },
    deactivate: function deactivate() {
      this.controller.setProperties({
        password: null,
        focusedOutPassword: false,
        error: null
      });
    },


    actions: {
      saveEmailVerification: function saveEmailVerification() {
        var _this = this;

        this.controller.get('emailVerification').save().then(function (ev) {
          _this.controller.setProperties({
            toastMessage: _this.get('i18n').t('form.change-email-success', {
              email: ev.get('email')
            }),
            toastVisible: true
          });

          // Create new email verification model... just in case...
          _this.controller.set('emailVerification', _this.store.createRecord('email-verification', {
            email: _this.get('currentUser.user.email')
          }));
        }).catch(function () {});
      }
    }
  });
});