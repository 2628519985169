define('ceannate-web/components/provider/suffix-inputs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__suffix-inputs'],

    filterSpace: function filterSpace(event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    }
  });
});