define('ceannate-web/web/provider/forgot-password/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').addClass('beige');
    },
    deactivate: function deactivate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').removeClass('beige');
    },
    model: function model() {
      return this.store.createRecord('password-reset', {});
    },


    actions: {
      requestPasswordReset: function requestPasswordReset() {
        var _this = this;

        // Store email in localstorage...
        window.localStorage.setItem('reset-email', this.controller.get('model.email'));

        this.controller.get('model').save().then(function () {
          _this.controller.set('error', false);
          _this.controller.set('sentReset', true);
          _this.controller.set('model', _this.store.createRecord('password-reset', {}));
        }).catch(function () {
          _this.controller.set('error', true);
          _this.controller.set('sentReset', false);
        });
      }
    }
  });
});