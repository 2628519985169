define('ceannate-web/models/patient-relationship', ['exports', 'ember-data', 'ceannate-web/utils/encrypt'], function (exports, _emberData, _encrypt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    insuranceVerified: _emberData.default.attr('boolean'),
    insuranceData: _emberData.default.attr('string'),
    insuranceVerifiedDate: _emberData.default.attr('date'),
    student: _emberData.default.belongsTo('student', { async: false }),
    provider: _emberData.default.belongsTo('provider', { async: false }),
    credential: _emberData.default.belongsTo('credential'),
    credentialAssociatedAt: _emberData.default.attr('date'),
    signatures: _emberData.default.hasMany('signature'),
    // charges: DS.hasMany('student-charge', { async: true }),
    charges: _emberData.default.hasMany('charge', { async: true }),
    replacementRelationshipId: _emberData.default.attr('number'),
    isProviderEligible: _emberData.default.attr('boolean'),
    isStudentEligible: _emberData.default.attr('number'),

    obfuscatedId: Ember.computed('id', function () {
      return (0, _encrypt.encode)(this.get('id'));
    })
  });
});