define('ceannate-web/models/student', ['exports', 'ember-data', 'ceannate-web/models/user'], function (exports, _emberData, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _user.default.extend({
    user: _emberData.default.belongsTo('user'),
    patientRelationships: _emberData.default.hasMany('patient-relationship'),
    emergencyContacts: _emberData.default.hasMany('emergency-contact'),
    university: _emberData.default.belongsTo('university'),
    identificationSrc: _emberData.default.attr('string'),
    canContactByPhone: attr('boolean'),
    canContactByEmail: attr('boolean'),
    canContactByText: attr('boolean'),
    canContactByVoicemail: attr('boolean'),
    birthDate: attr('string'),
    insurancePlans: _emberData.default.hasMany('insurance-plan', { async: false }),
    location: _emberData.default.attr('object'),
    universityStudentId: _emberData.default.attr('string'),
    isEligible: _emberData.default.attr('number'),
    universityProgramType: _emberData.default.attr('string'),
    universityProgramTypeSelector: _emberData.default.attr('string'),
    studentFreeSession: _emberData.default.belongsTo('student-free-session'),
    universityProgram: _emberData.default.belongsTo('university-program')
  });
});