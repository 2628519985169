define('ceannate-web/components/video-upload/component', ['exports', 'ceannate-web/mixins/file-upload-mixin'], function (exports, _fileUploadMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileUploadMixin.default, {
    classNames: ['component__video-upload'],
    provider: null,
    url: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.generateFileRecord();
      this.element.querySelector('#video-upload').addEventListener('change', function (event) {
        // Let's prepare to grab the presigned url
        var file = event.target.files[0];

        _this.setFileRecordData(file.name, file.type);
        _this.saveRecord(file, true);
        _this.set('provider.videoSrc', _this.get('tmpFile'));
      });

      if (this.get('provider.videoSrc')) {
        this.setProperties({
          percentage: 100,
          hideProgress: true,
          finishedUpload: true,
          fileName: this.get('provider.videoSrc').split('/').pop()
        });
      }
    },


    actions: {
      selectFile: function selectFile() {
        this.element.querySelector('#video-upload').click();
      },
      removeVideoFile: function removeVideoFile() {
        this.element.querySelector('#video-upload').value = '';
        this.set('provider.videoSrc', null);
        this.removeVideo();
      }
    }
  });
});