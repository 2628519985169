define('ceannate-web/components/settings/toast-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__settings__toast-message'],
    classNameBindings: ['status']
  });
});