define('ceannate-web/components/subscription-card/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_subscription-card',
    mods: ['disabled'],
    isSelected: Ember.computed('type', 'value', function () {
      var _getProperties = this.getProperties(['type', 'value']),
          type = _getProperties.type,
          value = _getProperties.value;

      if (type && value) {
        return type === value;
      }
      return false;
    }),
    select: function select() {
      this.get('selectSubscription')(this.get('type'));
    }
  });
});