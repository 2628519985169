define('ceannate-web/web/provider/workspace/clients/client/route', ['exports', 'ceannate-web/utils/encrypt'], function (exports, _encrypt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var includes = ['credential.documents', 'provider', 'provider.credentials.license_type.region', 'provider.credentials.documents', 'signatures', 'student.emergency_contacts', 'student.insurance_plans.insurance_company', 'student.university', 'student.student_free_session', 'student.university_program.program', 'student.university_program.payment_options'];
      return Ember.RSVP.hash({
        patientRelationship: this.store.query('patient-relationship', {
          'filter[id]': (0, _encrypt.decode)(params.patient_relationship_id),
          include: includes.join(',')
        }).then(function (result) {
          return result.objectAt(0);
        })
      });
    },
    afterModel: function afterModel(model) {
      if (model.patientRelationship && model.patientRelationship.get('status') === 'stopped') {
        var thread = this.modelFor('web.provider.workspace.clients').threads.findBy('student', model.patientRelationship.get('student'));

        var newRelationship = thread.get('provider.patientRelationships').filterBy('student', model.patientRelationship.get('student')).filter(function (item) {
          return item.get('status') !== 'stopped';
        })[0];

        if (newRelationship) {
          this.transitionTo('web.provider.workspace.clients.client', (0, _encrypt.encode)(newRelationship.get('id')));
        }
      }
    }
  });
});