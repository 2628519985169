define('ceannate-web/components/meta-textarea/component', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__meta-textarea'],
    classNameBindings: ['error:component__meta-textarea--error'],

    value: null,

    charLimit: 500,
    showCharLimit: true,
    charCounted: 0,

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('onInput')) {
        throw 'onInput must be specified';
      }
    },


    textCounterCurrent: (0, _computed.default)('value', 'charLimit', function (value, limit) {
      var length = value ? value.length : 0;
      return length + '/' + limit;
    })
  });
});