define('ceannate-web/web/provider/login/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    email: null,
    password: null,
    error: null,
    recentlyDeactivated: false,
    emailVerificationToken: null,
    toastMessage: null,
    toastVisible: false,
    toastVariant: null,
    toastAutoHide: null,

    init: function init() {
      this._super.apply(this, arguments);

      // Check to see if we have a deactivation set
      var recentlyDeactivated = localStorage.getItem('deactivated');
      if (recentlyDeactivated) {
        this.setProperties({
          toastVisible: true,
          toastAutoHide: true,
          toastVariant: 'success',
          toastMessage: this.get('i18n').t('general.your-account-is-deactivated')
        });
        localStorage.removeItem('deactivated');
      }

      if (localStorage.getItem('inactivityLogout')) {
        this.setProperties({
          toastVisible: true,
          toastAutoHide: false,
          toastVariant: 'success',
          toastMessage: this.get('i18n').t('sign-in.inactivity-message')
        });
        localStorage.removeItem('inactivityLogout');
      }
    }
  });
});