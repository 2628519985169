define('ceannate-web/utils/jsonapi-infinity-model-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    page: {
      // number: 1,
      size: 10
    },
    per_page: '',
    // perPageParam: 'page[size]',
    pageParam: 'page[number]',
    totalPagesParam: 'meta.pagination.total_pages',
    countParam: 'meta.pagination.count'
  };
});