define('ceannate-web/mirage/fixtures/insurance-company', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{ id: '1', name: '1199 National Benefit Fund' }, { id: '2', name: 'AARP Medicare Complete' }, {
    id: '3',
    name: 'AARP Medicare Supplement Plan (Fixed or Hospital Indemnity)'
  }, { id: '4', name: 'ASR Health Benefits' }, { id: '5', name: 'Access Health Solutions' }, {
    id: '6',
    name: 'Aetna'
  }, { id: '7', name: 'Aetna Better Health' }, { id: '8', name: 'Aetna Better Health of Illinois' }, {
    id: '9',
    name: 'Aetna Better Health of Kentucky'
  }, { id: '10', name: 'Aetna Better Health of Louisiana' }, {
    id: '11',
    name: 'Aetna Better Health of Michigan (formerly Coventry Cares of MI)'
  }, { id: '12', name: 'Aetna Better Health of Nebraska' }, {
    id: '13',
    name: 'Aetna Better Health of New Jersey'
  }, { id: '14', name: 'Aetna Better Health of New York' }, {
    id: '15',
    name: 'Aetna Better Health of Ohio'
  }, { id: '16', name: 'Aetna Better Health of Pennsylvania' }, {
    id: '17',
    name: 'Aetna Better Health of Texas'
  }, {
    id: '18',
    name: 'Aetna Better Health of Virginia (formerly Coventry Cares of VA)'
  }, {
    id: '19',
    name: 'Aetna Better Health of West Virginia'
  }, { id: '20', name: 'Aetna Senior Supplemental' }, { id: '21', name: 'Affinity Health Plan' }, {
    id: '22',
    name: 'Affinity Medicare Advantage'
  }, { id: '23', name: 'Aflac' }, { id: '24', name: 'Aflac (Dental)' }, {
    id: '25',
    name: 'Aflac Medicare Supplement Insurance'
  }, { id: '26', name: 'Aftra Health Fund' }, { id: '27', name: 'Alabama Medicaid' }, {
    id: '28',
    name: 'Alameda Alliance For Health'
  }, { id: '29', name: 'Alan Sturm and Associates (Dental)' }, { id: '30', name: 'Alaska Medicaid' }, {
    id: '31',
    name: 'All Savers Insurance'
  }, { id: '32', name: 'Allegiance' }, { id: '33', name: 'Allied Benefit Systems Inc' }, {
    id: '34',
    name: 'Altius Health Plans'
  }, { id: '35', name: 'Altus (Dental)' }, { id: '36', name: 'Ambetter (all states)' }, {
    id: '37',
    name: 'AmeriBen'
  }, { id: '38', name: 'AmeriHealth Administrators' }, { id: '39', name: 'AmeriHealth Caritas Iowa' }, {
    id: '40',
    name: 'AmeriHealth Caritas Louisiana'
  }, { id: '41', name: 'AmeriHealth Caritas Pennsylvania' }, {
    id: '42',
    name: 'American Medical Security (AMS - UnitedHealthcare Life)'
  }, { id: '43', name: 'American National Insurance' }, {
    id: '44',
    name: 'American Postal Workers Union (APWU) Health Plan'
  }, { id: '45', name: 'American Republic Insurance Company (ARIC)' }, { id: '46', name: 'Amerigroup' }, {
    id: '47',
    name: 'Amerigroup Community Care'
  }, { id: '48', name: 'Ameritas of New York' }, { id: '49', name: 'Anthem Blue Cross California' }, {
    id: '50',
    name: 'Anthem Blue Cross and Blue Shield of Colorado'
  }, { id: '51', name: 'Anthem Blue Cross and Blue Shield of Connecticut' }, {
    id: '52',
    name: 'Anthem Blue Cross and Blue Shield of Indiana'
  }, { id: '53', name: 'Anthem Blue Cross and Blue Shield of Kentucky' }, {
    id: '54',
    name: 'Anthem Blue Cross and Blue Shield of Maine'
  }, { id: '55', name: 'Anthem Blue Cross and Blue Shield of Missouri' }, {
    id: '56',
    name: 'Anthem Blue Cross and Blue Shield of Nevada'
  }, { id: '57', name: 'Anthem Blue Cross and Blue Shield of New Hampshire' }, {
    id: '58',
    name: 'Anthem Blue Cross and Blue Shield of Ohio'
  }, { id: '59', name: 'Anthem Blue Cross and Blue Shield of Virginia' }, {
    id: '60',
    name: 'Anthem Blue Cross and Blue Shield of Wisconsin'
  }, { id: '61', name: 'Arbor Health Plan' }, { id: '62', name: 'Arise Health Plan' }, {
    id: '63',
    name: 'Arizona Medicaid (AHCCCS)'
  }, {
    id: '64',
    name: 'Arizona Physicians IPA (United Healthcare Community Plan AZ)'
  }, {
    id: '65',
    name: 'Arkansas Medicaid'
  }, { id: '66', name: 'Arkansas Medicare (Part B)' }, { id: '67', name: 'Arnett Health Plan' }, {
    id: '68',
    name: 'Asuris Northwest Health'
  }, { id: '69', name: 'AultCare' }, { id: '70', name: 'Automated Benefit Services' }, {
    id: '71',
    name: 'Avmed Health Plan'
  }, { id: '72', name: 'Bankers Life and Casualty' }, { id: '73', name: 'Banner Health Plans' }, {
    id: '74',
    name: 'Best Choice Health Plan'
  }, { id: '75', name: 'Best Life and Health' }, { id: '76', name: 'Better Health Plan (Unison)' }, {
    id: '77',
    name: 'Blue Cross Blue Shield Federal Employee Program'
  }, { id: '78', name: 'Blue Cross Blue Shield Medicare Advantage' }, {
    id: '79',
    name: 'Blue Cross Blue Shield of Alabama (Institutional)'
  }, { id: '80', name: 'Blue Cross Blue Shield of Alabama (Professional)' }, {
    id: '81',
    name: 'Blue Cross Blue Shield of Arkansas'
  }, { id: '82', name: 'Blue Cross Blue Shield of Hawaii (HMSA)' }, {
    id: '83',
    name: 'Blue Cross Blue Shield of Minnesota'
  }, { id: '84', name: 'Blue Cross Blue Shield of North Dakota' }, {
    id: '85',
    name: 'Blue Cross Blue Shield of Texas'
  }, { id: '86', name: 'Blue Cross Blue Shield of Wyoming' }, {
    id: '87',
    name: 'Blue Cross and Blue Shield of Arizona'
  }, { id: '88', name: 'Blue Cross and Blue Shield of Florida (Florida Blue)' }, {
    id: '89',
    name: 'Blue Cross and Blue Shield of Georgia'
  }, { id: '90', name: 'Blue Cross and Blue Shield of Illinois' }, {
    id: '91',
    name: 'Blue Cross and Blue Shield of Kansas'
  }, { id: '92', name: 'Blue Cross and Blue Shield of Kansas City' }, {
    id: '93',
    name: 'Blue Cross and Blue Shield of Louisiana'
  }, { id: '94', name: 'Blue Cross and Blue Shield of Massachusetts' }, {
    id: '95',
    name: 'Blue Cross and Blue Shield of Michigan'
  }, { id: '96', name: 'Blue Cross and Blue Shield of Mississippi' }, {
    id: '97',
    name: 'Blue Cross and Blue Shield of Montana'
  }, { id: '98', name: 'Blue Cross and Blue Shield of Nebraska' }, {
    id: '99',
    name: 'Blue Cross and Blue Shield of New Mexico'
  }, { id: '100', name: 'Blue Cross and Blue Shield of North Carolina' }, {
    id: '101',
    name: 'Blue Cross and Blue Shield of Oklahoma'
  }, { id: '102', name: 'Blue Cross and Blue Shield of Rhode Island' }, {
    id: '103',
    name: 'Blue Cross and Blue Shield of South Carolina'
  }, { id: '104', name: 'Blue Cross and Blue Shield of Tennessee' }, {
    id: '105',
    name: 'Blue Cross and Blue Shield of Vermont'
  }, { id: '106', name: 'Blue Cross and Blue Shield of Western New York' }, {
    id: '107',
    name: 'Blue Cross and Blue Shield of Western New York Federal Employee Plan'
  }, { id: '108', name: 'Blue Cross of Idaho' }, { id: '109', name: 'Blue Shield of California' }, {
    id: '110',
    name: 'BlueShield of Northeastern New York'
  }, {
    id: '111',
    name: 'BlueShield of Northeastern New York Federal Employee Plan'
  }, {
    id: '112',
    name: 'Boon Chapman'
  }, { id: '113', name: 'Boon Group' }, { id: '114', name: 'Boston Medical Center Health Plan' }, {
    id: '115',
    name: 'Bravo Health'
  }, { id: '116', name: 'Bridgespan' }, { id: '117', name: 'Bridgeway Health Solutions' }, {
    id: '118',
    name: 'Brokers National Dental Insurance'
  }, { id: '119', name: 'Buckeye Community Health Plan' }, { id: '120', name: 'Cal-Optima (Medi-Cal)' }, {
    id: '121',
    name: 'California Health and Wellness'
  }, { id: '122', name: 'California Medicaid (Medi-Cal)' }, { id: '123', name: 'Capital Blue Cross' }, {
    id: '124',
    name: "Capital District Physicians' Health Plan"
  }, { id: '125', name: 'Capital Health Plan' }, { id: '126', name: 'Caprock' }, {
    id: '127',
    name: 'Care Improvement Plus (Medicare Advantage)'
  }, { id: '128', name: 'Care1st Health Plan Arizona' }, {
    id: '129',
    name: 'Care1st Health Plan of California'
  }, { id: '130', name: 'CareFirst Blue Cross Blue Shield of DC' }, {
    id: '131',
    name: 'CareFirst Blue Cross Blue Shield of Maryland'
  }, { id: '132', name: 'CarePlus Health Plan' }, { id: '133', name: 'CareSource of Indiana' }, {
    id: '134',
    name: 'CareSource of Ohio'
  }, { id: '135', name: 'Carelink (Coventry)' }, { id: '136', name: 'Carelink Medicaid (Coventry)' }, {
    id: '137',
    name: 'Cariten Healthcare'
  }, { id: '138', name: 'Carolina Crescent Health Plan' }, { id: '139', name: 'CeltiCare Health Plan' }, {
    id: '140',
    name: 'Celtic Insurance Company'
  }, { id: '141', name: 'Cenpatico Behavioral Health Arizona' }, {
    id: '142',
    name: 'Cenpatico Behavioral Health Florida'
  }, { id: '143', name: 'Cenpatico Behavioral Health Georgia' }, {
    id: '144',
    name: 'Cenpatico Behavioral Health Illinois'
  }, { id: '145', name: 'Cenpatico Behavioral Health Indiana' }, {
    id: '146',
    name: 'Cenpatico Behavioral Health Kansas'
  }, { id: '147', name: 'Cenpatico Behavioral Health Kentucky' }, {
    id: '148',
    name: 'Cenpatico Behavioral Health Massachusetts'
  }, { id: '149', name: 'Cenpatico Behavioral Health Missouri' }, {
    id: '150',
    name: 'Cenpatico Behavioral Health Montana'
  }, { id: '151', name: 'Cenpatico Behavioral Health Ohio' }, {
    id: '152',
    name: 'Cenpatico Behavioral Health South Carolina'
  }, { id: '153', name: 'Cenpatico Behavioral Health Texas' }, {
    id: '154',
    name: 'Cenpatico Behavioral Health Wisconsin'
  }, { id: '155', name: 'Central California Health Alliance' }, {
    id: '156',
    name: 'ChampVA - Health Administration Center (HAC)'
  }, { id: '157', name: 'Cigna (Including Dental)' }, { id: '158', name: 'Cigna-HealthSpring' }, {
    id: '159',
    name: 'Citrus Health Care'
  }, { id: '160', name: 'Clover Health' }, { id: '161', name: 'Colorado Medicaid' }, {
    id: '162',
    name: 'Colorado Medicare (Part B)'
  }, { id: '163', name: 'Community Care of Oklahoma' }, { id: '164', name: 'Community First Health Plan' }, {
    id: '165',
    name: 'Community Health Choice'
  }, { id: '166', name: 'Community Health Plan of Washington' }, {
    id: '167',
    name: 'Community Hospital Corporation (CHC)'
  }, { id: '168', name: 'Complementary Healthcare Plan (The CHP Group)' }, {
    id: '169',
    name: 'ConnectiCare'
  }, { id: '170', name: 'ConnectiCare Medicare Advantage' }, { id: '171', name: 'Connecticut Medicaid' }, {
    id: '172',
    name: 'Continental General Insurance Company'
  }, { id: '173', name: 'Cook Childrens Health Plan' }, {
    id: '174',
    name: 'Cooperative Benefit Administrators'
  }, { id: '175', name: 'Coordinated Care' }, { id: '176', name: 'CoreSource (IL, IN, MD, NC,PA)' }, {
    id: '177',
    name: 'CoreSource Little Rock'
  }, { id: '178', name: 'CoreSource Ohio' }, { id: '179', name: 'Coventry Advantra' }, {
    id: '180',
    name: 'Coventry Cares'
  }, { id: '181', name: 'Coventry Health Care Group Health Plan' }, {
    id: '182',
    name: 'Coventry Health Care of Delaware'
  }, { id: '183', name: 'Coventry Health Care of Florida' }, {
    id: '184',
    name: 'Coventry Health Care of Georgia'
  }, { id: '185', name: 'Coventry Health Care of Illinois' }, {
    id: '186',
    name: 'Coventry Health Care of Iowa'
  }, { id: '187', name: 'Coventry Health Care of Kansas' }, {
    id: '188',
    name: 'Coventry Health Care of Louisiana'
  }, { id: '189', name: 'Coventry Health Care of Missouri' }, {
    id: '190',
    name: 'Coventry Health Care of Nebraska'
  }, { id: '191', name: 'Coventry Health Care of Nevada' }, {
    id: '192',
    name: 'Coventry Health Care of Oklahoma'
  }, { id: '193', name: 'Coventry Health Care of Texas' }, {
    id: '194',
    name: 'Coventry Health Care of Virginia (Southern Health Services)'
  }, { id: '195', name: 'Coventry Health Care of the Carolinas (WellPath)' }, {
    id: '196',
    name: 'Coventry Health and Life Tennessee'
  }, { id: '197', name: 'Coventry Healthcare National Network' }, { id: '198', name: 'Coventry One' }, {
    id: '199',
    name: 'CoventryCares of Michigan (OmniCare)'
  }, { id: '200', name: 'CoventryCares of Virginia' }, { id: '201', name: 'Culinary Health Fund' }, {
    id: '202',
    name: 'Cypress Benefit Administrators'
  }, { id: '203', name: 'DeCare Dental Health Insurance' }, { id: '204', name: 'Dean Health Plan' }, {
    id: '205',
    name: 'Definity Health (United)'
  }, { id: '206', name: 'Delaware Medicaid' }, { id: '207', name: 'Delaware Medicare (Part B)' }, {
    id: '208',
    name: 'Delta Dental Insurance Company of Alabama'
  }, { id: '209', name: 'Delta Dental Insurance Company of Florida' }, {
    id: '210',
    name: 'Delta Dental Insurance Company of Georgia'
  }, { id: '211', name: 'Delta Dental Insurance Company of Louisiana' }, {
    id: '212',
    name: 'Delta Dental Insurance Company of Mississippi'
  }, { id: '213', name: 'Delta Dental Insurance Company of Montana' }, {
    id: '214',
    name: 'Delta Dental Insurance Company of Nevada'
  }, { id: '215', name: 'Delta Dental Insurance Company of Texas' }, {
    id: '216',
    name: 'Delta Dental Insurance Company of Utah'
  }, { id: '217', name: 'Delta Dental of California' }, { id: '218', name: 'Delta Dental of Colorado' }, {
    id: '219',
    name: 'Delta Dental of Connecticut'
  }, { id: '220', name: 'Delta Dental of Delaware' }, { id: '221', name: 'Delta Dental of Hawaii' }, {
    id: '222',
    name: 'Delta Dental of Indiana'
  }, { id: '223', name: 'Delta Dental of Maryland' }, { id: '224', name: 'Delta Dental of Michigan' }, {
    id: '225',
    name: 'Delta Dental of Minnesota'
  }, { id: '226', name: 'Delta Dental of Nebraska' }, { id: '227', name: 'Delta Dental of New Jersey' }, {
    id: '228',
    name: 'Delta Dental of New York'
  }, { id: '229', name: 'Delta Dental of North Carolina' }, {
    id: '230',
    name: 'Delta Dental of North Dakota'
  }, { id: '231', name: 'Delta Dental of Ohio' }, { id: '232', name: 'Delta Dental of Oklahoma' }, {
    id: '233',
    name: 'Delta Dental of Pennsylvania'
  }, { id: '234', name: 'Delta Dental of Rhode Island' }, { id: '235', name: 'Delta Dental of Tennessee' }, {
    id: '236',
    name: 'Delta Dental of Virginia'
  }, { id: '237', name: 'Delta Dental of Washington DC' }, {
    id: '238',
    name: 'Delta Dental of Washington State'
  }, { id: '239', name: 'Delta Dental of West Virginia' }, { id: '240', name: 'Deseret Mutual' }, {
    id: '241',
    name: 'Directors Guild of America Health Fund'
  }, { id: '242', name: 'District of Columbia Medicaid' }, {
    id: '243',
    name: "Driscoll Children's Health Plan"
  }, { id: '244', name: 'EBSO Benefits' }, { id: '245', name: 'Elderplan' }, {
    id: '246',
    name: 'Emblem Health'
  }, { id: '247', name: 'Empire Blue Cross and Blue Shield' }, {
    id: '248',
    name: 'Employee Benefit Management Services (EBMS)'
  }, { id: '249', name: 'Essence Healthcare' }, { id: '250', name: 'Excellus Blue Cross and Blue Shield' }, {
    id: '251',
    name: 'Fallon Community Health Plan'
  }, { id: '252', name: 'Farm Bureau Health Plans' }, {
    id: '253',
    name: 'Federated Mutual Insurance Company'
  }, { id: '254', name: 'Fidelis Care of New York' }, { id: '255', name: 'First Health (Coventry)' }, {
    id: '256',
    name: 'Flex Compensation (Dental)'
  }, { id: '257', name: 'Florida Combined Life Insurance Company (Dental)' }, {
    id: '258',
    name: 'Florida Health Care Plan'
  }, { id: '259', name: 'Florida Medicaid' }, { id: '260', name: 'Gateway Health Medicare Assured' }, {
    id: '261',
    name: 'Gateway Health Pennsylvania'
  }, { id: '262', name: 'Geisinger Health Plan' }, { id: '263', name: 'Georgia Medicaid' }, {
    id: '264',
    name: 'Gilsbar'
  }, { id: '265', name: 'Globe Life and Accident Insurance Company' }, {
    id: '266',
    name: 'Gold Coast Health Plan'
  }, { id: '267', name: 'Golden Rule' }, {
    id: '268',
    name: 'Government Employees Health Association (GEHA)'
  }, { id: '269', name: 'Granite State Health Plan' }, { id: '270', name: 'Great West Healthcare' }, {
    id: '271',
    name: 'Group Health Cooperative'
  }, {
    id: '272',
    name: 'Group Health Cooperative of South Central Wisconsin (Dental)'
  }, {
    id: '273',
    name: 'Group and Pension Administrators'
  }, { id: '274', name: 'Guardian Life Insurance Company of America (Dental)' }, {
    id: '275',
    name: 'Harken Health'
  }, { id: '276', name: 'Harmony Health Plan of Illinois' }, {
    id: '277',
    name: 'Harmony Health Plan of Indiana'
  }, { id: '278', name: 'Harvard Pilgrim Health Care' }, {
    id: '279',
    name: 'Harvard Pilgrim Passport Connect (United)'
  }, { id: '280', name: 'Hawaii Mainland Administrators' }, { id: '281', name: 'Hawaii Medicaid' }, {
    id: '282',
    name: 'Hawaii Medical Assurance Association (Dental)'
  }, { id: '283', name: 'Hawaii Medicare (Part B)' }, {
    id: '284',
    name: 'Health Alliance Medical Plan of Illinois'
  }, { id: '285', name: 'Health Alliance Plan of Michigan' }, {
    id: '286',
    name: 'Health America (Coventry)'
  }, { id: '287', name: 'Health Choice of Arizona' }, { id: '288', name: 'Health First Health Plans' }, {
    id: '289',
    name: 'Health Net (AZ, CT, NJ, NY, PA)'
  }, { id: '290', name: 'Health Net National' }, { id: '291', name: 'Health Net of California' }, {
    id: '292',
    name: 'Health New England'
  }, { id: '293', name: 'Health Partners of Pennsylvania' }, {
    id: '294',
    name: 'Health Plan of Nevada (United Healthcare Nevada)'
  }, { id: '295', name: 'Health Plan of San Joaquin (HPSJ)' }, {
    id: '296',
    name: 'Health Plan of San Mateo (HPSM)'
  }, { id: '297', name: 'Health Tradition Health Plan (Mayo Health)' }, {
    id: '298',
    name: 'HealthChoice of Oklahoma'
  }, { id: '299', name: 'HealthComp Administrators' }, { id: '300', name: 'HealthEZ' }, {
    id: '301',
    name: 'HealthEase of Florida (WellCare)'
  }, { id: '302', name: 'HealthNow' }, { id: '303', name: 'HealthPartners Minnesota' }, {
    id: '304',
    name: 'HealthScope Benefits'
  }, { id: '305', name: 'HealthSmart Benefit Solutions' }, {
    id: '306',
    name: 'Healthcare USA (Coventry)'
  }, { id: '307', name: 'Healthfirst of New York' }, { id: '308', name: 'Healthgram' }, {
    id: '309',
    name: 'Heartland National Life Insurance Company'
  }, { id: '310', name: 'Highmark Blue Cross Blue Shield Delaware' }, {
    id: '311',
    name: 'Highmark Blue Cross Blue Shield Pennsylvania'
  }, { id: '312', name: 'Highmark Blue Cross Blue Shield West Virginia' }, {
    id: '313',
    name: 'Highmark Blue Shield Pennsylvania'
  }, { id: '314', name: 'Highmark Senior Health Company' }, { id: '315', name: 'Home State Health Plan' }, {
    id: '316',
    name: 'Hometown Health'
  }, { id: '317', name: 'Horizon Blue Cross Blue Shield of New Jersey' }, {
    id: '318',
    name: 'Horizon NJ Health'
  }, { id: '319', name: 'Hudson Health Plan' }, { id: '320', name: 'Humana' }, {
    id: '321',
    name: 'Idaho Medicaid'
  }, { id: '322', name: 'Ideal Life Insurance' }, { id: '323', name: 'Illinicare health Plan' }, {
    id: '324',
    name: 'Illinois Medicaid'
  }, { id: '325', name: 'Independence Administrators' }, {
    id: '326',
    name: 'Independence Blue Cross (Institutional)'
  }, { id: '327', name: 'Independence Blue Cross (Professional)' }, {
    id: '328',
    name: 'Independent Health'
  }, { id: '329', name: 'Indiana Medicaid' }, { id: '330', name: 'Inland Empire Health Plan' }, {
    id: '331',
    name: 'Iowa Medicaid'
  }, { id: '332', name: 'John Hopkins HealthCare LLC' }, {
    id: '333',
    name: 'Kaiser Permanente of Colorado'
  }, { id: '334', name: 'Kaiser Permanente of Georgia' }, {
    id: '335',
    name: 'Kaiser Permanente of Northern CA'
  }, { id: '336', name: 'Kaiser Permanente of Southern CA' }, {
    id: '337',
    name: 'Kaiser Permanente of the Mid Atlantic'
  }, { id: '338', name: 'Kaiser Permanente of the Northwest' }, { id: '339', name: 'Kansas Medicaid' }, {
    id: '340',
    name: 'Kentucky Medicaid'
  }, { id: '341', name: 'Kentucky Spirit' }, { id: '342', name: 'Key Benefit Administrators' }, {
    id: '343',
    name: 'Keystone First'
  }, { id: '344', name: 'Keystone Health Plan East' }, { id: '345', name: 'L.A. Care Health Plan' }, {
    id: '346',
    name: 'Liberty National Life Insurance Company'
  }, { id: '347', name: 'LifeWise Health Plan of Oregon' }, {
    id: '348',
    name: 'LifeWise Health Plan of Washington'
  }, { id: '349', name: 'Lifetime Benefit Solutions' }, { id: '350', name: 'Lincoln Financial Group' }, {
    id: '351',
    name: 'Loomis Company'
  }, { id: '352', name: 'Louisiana Healthcare Connections' }, { id: '353', name: 'Louisiana Medicaid' }, {
    id: '354',
    name: 'Louisiana Medicare (Part B)'
  }, { id: '355', name: 'MAHP MAMSI (United)' }, { id: '356', name: 'MAPFRE Life Puerto Rico' }, {
    id: '357',
    name: 'MVP Health Care'
  }, { id: '358', name: 'Magellan Health' }, { id: '359', name: 'Magnacare' }, {
    id: '360',
    name: 'Magnolia Health Plan'
  }, { id: '361', name: 'Mail Handlers Benefit Plan (MHBP)' }, { id: '362', name: 'Maine Medicaid' }, {
    id: '363',
    name: 'Managed Health Services Indiana'
  }, { id: '364', name: 'Managed Health Services Insurance Corp' }, {
    id: '365',
    name: 'Managed Health Services Wisconsin'
  }, { id: '366', name: 'Maricopa Health Plan (Arizona)' }, { id: '367', name: 'Maryland Medicaid' }, {
    id: '368',
    name: 'Maryland Medicare (Part B)'
  }, { id: '369', name: 'Massachusetts Medicaid (MassHealth)' }, {
    id: '370',
    name: 'Mayo Management Services'
  }, { id: '371', name: 'MedStar Family Choice' }, { id: '372', name: 'Medcost Benefit Services' }, {
    id: '373',
    name: 'Medica Health Plans'
  }, { id: '374', name: 'Medica Health Plans (IFB Groups)' }, {
    id: '375',
    name: 'Medical Mutual of Ohio'
  }, { id: '376', name: 'Medicare (all states)' }, { id: '377', name: 'Medicare DME Jurisdiction A' }, {
    id: '378',
    name: 'Medicare DME Jurisdiction B'
  }, { id: '379', name: 'Medicare DME Jurisdiction C' }, {
    id: '380',
    name: 'Medicare DME Jurisdiction D'
  }, { id: '381', name: 'Medico Insurance Company' }, {
    id: '382',
    name: 'Mega Life and Health Insurance'
  }, { id: '383', name: 'Mercy Care Plan' }, { id: '384', name: 'Meritain Health' }, {
    id: '385',
    name: 'Meritus Health Partners'
  }, { id: '386', name: 'Metlife Insurance Company (Dental)' }, {
    id: '387',
    name: 'Metro Plus Health Plan'
  }, { id: '388', name: 'Michigan Medicaid' }, { id: '389', name: 'Minnesota Medicaid' }, {
    id: '390',
    name: 'Mississippi Medicaid'
  }, { id: '391', name: 'Mississippi Medicare (Part B)' }, { id: '392', name: 'Missouri Medicaid' }, {
    id: '393',
    name: 'Moda Health'
  }, { id: '394', name: 'Molina Healthcare of California' }, {
    id: '395',
    name: 'Molina Healthcare of Florida'
  }, { id: '396', name: 'Molina Healthcare of Illinois' }, {
    id: '397',
    name: 'Molina Healthcare of Michigan'
  }, { id: '398', name: 'Molina Healthcare of New Mexico' }, {
    id: '399',
    name: 'Molina Healthcare of Ohio'
  }, { id: '400', name: 'Molina Healthcare of Puerto Rico' }, {
    id: '401',
    name: 'Molina Healthcare of South Carolina'
  }, { id: '402', name: 'Molina Healthcare of Texas' }, { id: '403', name: 'Molina Healthcare of Utah' }, {
    id: '404',
    name: 'Molina Healthcare of Washington'
  }, { id: '405', name: 'Molina Healthcare of Wisconsin' }, { id: '406', name: 'Montana Medicaid' }, {
    id: '407',
    name: 'Montefiore Contract Management Organization'
  }, { id: '408', name: 'Mutual Health Services' }, { id: '409', name: 'Mutual of Omaha' }, {
    id: '410',
    name: 'National Association of Letter Carriers (NALC)'
  }, { id: '411', name: 'Nebraska Medicaid' }, { id: '412', name: 'Neighborhood Health Plan' }, {
    id: '413',
    name: 'Network Health Plan'
  }, { id: '414', name: 'Nevada Medicaid' }, { id: '415', name: 'New Era Life Insurance Company' }, {
    id: '416',
    name: 'New Hampshire Healthy Families'
  }, { id: '417', name: 'New Hampshire Medicaid' }, { id: '418', name: 'New Jersey Medicaid' }, {
    id: '419',
    name: 'New Jersey Medicare (Part B)'
  }, { id: '420', name: 'New Mexico Medicaid' }, { id: '421', name: 'New Mexico Medicare (Part B)' }, {
    id: '422',
    name: 'New York Medicaid'
  }, { id: '423', name: 'New York Medicare Part B Queens' }, { id: '424', name: 'Nippon Life' }, {
    id: '425',
    name: 'North Carolina Medicaid'
  }, { id: '426', name: 'North Dakota Medicaid' }, { id: '427', name: 'Nova Healthcare Administrators' }, {
    id: '428',
    name: 'NovaSys Health'
  }, { id: '429', name: 'Ohana Health Plan' }, { id: '430', name: 'Ohio Medicaid' }, {
    id: '431',
    name: 'Oklahoma Medicare (Part B)'
  }, { id: '432', name: 'Optima Health Plan' }, { id: '433', name: 'OptumHealth Behavioral Solutions' }, {
    id: '434',
    name: 'Oregon Medicaid'
  }, { id: '435', name: 'Oscar Health Plan' }, { id: '436', name: 'Oxford Health Plan' }, {
    id: '437',
    name: 'PEHP Utah'
  }, { id: '438', name: 'PHCS Savility Payers' }, { id: '439', name: 'PacificSource Health Plans' }, {
    id: '440',
    name: 'Pan-American Life Insurance Group'
  }, { id: '441', name: 'Paramount' }, { id: '442', name: 'Parkland Community Health Plan' }, {
    id: '443',
    name: 'Passport Health Plan'
  }, { id: '444', name: 'Patriot Dental' }, { id: '445', name: 'Peach State Health Plan' }, {
    id: '446',
    name: 'Pennsylvania Health and Wellness'
  }, { id: '447', name: 'Pennsylvania Medicaid' }, { id: '448', name: 'Pennsylvania Medicare (Part B)' }, {
    id: '449',
    name: 'Physicians Health Plan of Mid-Michigan'
  }, { id: '450', name: 'Physicians Health Plan of Northern Indiana' }, {
    id: '451',
    name: 'Physicians Mutual Insurance Company'
  }, { id: '452', name: 'Physicians Plus Health Insurance' }, {
    id: '453',
    name: 'Piedmont WellStar Healthplans'
  }, { id: '454', name: 'Pinnacle Physician Management Org' }, {
    id: '455',
    name: 'Planned Administrators Inc'
  }, { id: '456', name: 'Prairie States TPA' }, { id: '457', name: 'PreferredOne Health Insurance' }, {
    id: '458',
    name: 'Premera Blue Cross'
  }, { id: '459', name: 'Premera Blue Cross Blue Shield Of Alaska' }, {
    id: '460',
    name: 'Prestige Health Choice'
  }, { id: '461', name: 'Prevea360 Health Plan' }, { id: '462', name: 'Principal Financial Group' }, {
    id: '463',
    name: 'Priority Health'
  }, { id: '464', name: 'Promina ASO (Healthcare, Inc)' }, { id: '465', name: 'Providence Health Plan' }, {
    id: '466',
    name: 'QualCare'
  }, { id: '467', name: 'QualChoice of Arkansas' }, { id: '468', name: 'Railroad Medicare' }, {
    id: '469',
    name: 'Regence BlueCross BlueShield of Oregon'
  }, { id: '470', name: 'Regence BlueCross BlueShield of Utah' }, {
    id: '471',
    name: 'Regence BlueShield of Idaho'
  }, { id: '472', name: 'Regence BlueShield of Washington' }, {
    id: '473',
    name: 'Regence Group Administrators'
  }, { id: '474', name: 'Reliance Standard Life Insurance' }, {
    id: '475',
    name: 'Reserve National Insurance Company'
  }, { id: '476', name: 'Rocky Mountain Health Plan' }, {
    id: '477',
    name: 'SAMBA (Special Agents Mutual Benefit Association)'
  }, { id: '478', name: 'San Francisco Health Plan (SFHP)' }, { id: '479', name: 'Sanford Health Plan' }, {
    id: '480',
    name: 'Santa Clara Family Health Plan'
  }, { id: '481', name: 'Scan Health Plan' }, { id: '482', name: 'Scott and White Health Plan' }, {
    id: '483',
    name: 'SecureHorizons Medicare Complete (United)'
  }, { id: '484', name: 'Securian Life Insurance Company (Dental)' }, {
    id: '485',
    name: 'Security Health Plan'
  }, { id: '486', name: 'SelectHealth of Utah' }, { id: '487', name: 'Senior Whole Health' }, {
    id: '488',
    name: 'Sharp Health Plan'
  }, { id: '489', name: 'Sierra Health Plan' }, { id: '490', name: 'Simply Healthcare' }, {
    id: '491',
    name: 'South Carolina Medicaid (Healthy Connections)'
  }, { id: '492', name: 'South Dakota Medicaid' }, {
    id: '493',
    name: 'Southern California Medicare (Part B)'
  }, { id: '494', name: 'Standard Life and Accident Insurance Company' }, {
    id: '495',
    name: 'State Farm'
  }, { id: '496', name: 'StayWell' }, { id: '497', name: 'Summacare Health Plan' }, {
    id: '498',
    name: 'Sunflower State Health Plan'
  }, { id: '499', name: 'Sunshine State Health Plan' }, { id: '500', name: 'Superior Health Plan' }, {
    id: '501',
    name: 'TRICARE (North, South, West)'
  }, { id: '502', name: 'TRICARE East' }, { id: '503', name: 'TRICARE For Life' }, {
    id: '504',
    name: 'TRICARE North'
  }, { id: '505', name: 'TRICARE Overseas' }, { id: '506', name: 'TRICARE South' }, {
    id: '507',
    name: 'TRICARE West'
  }, { id: '508', name: 'Tennessee Medicaid (TennCare)' }, {
    id: '509',
    name: 'Texas Medicaid (Acute Care)'
  }, { id: '510', name: 'Texas Medicaid (Long Term Care)' }, {
    id: '511',
    name: 'Texas Medicare (Part B)'
  }, { id: '512', name: 'The Empire Plan (United)' }, { id: '513', name: 'The Health Plan' }, {
    id: '514',
    name: "Today's Options (Pyramid)"
  }, { id: '515', name: 'Trillium Health Plan' }, { id: '516', name: 'Trustmark' }, {
    id: '517',
    name: 'Tufts Health Plan'
  }, { id: '518', name: 'Tufts Health Plan - Network Health' }, { id: '519', name: 'UCare of Minnesota' }, {
    id: '520',
    name: 'UHC West (PacifiCare)'
  }, { id: '521', name: 'UMR' }, { id: '522', name: 'UPMC Health Plan' }, {
    id: '523',
    name: 'USAA Life Insurance'
  }, { id: '524', name: 'UniCare' }, { id: '525', name: 'United American Insurance Company' }, {
    id: '526',
    name: 'United Concordia Companies (Dental)'
  }, {
    id: '527',
    name: 'United Concordia Companies Federal Employees Program (Dental)'
  }, {
    id: '528',
    name: 'United Health Care Shared Services'
  }, { id: '529', name: 'United Healthcare' }, {
    id: '530',
    name: 'United Healthcare Community Plan (AmeriChoice)'
  }, { id: '531', name: 'United Healthcare Community Plan PA (AmeriChoice)' }, {
    id: '532',
    name: 'United Healthcare Community Plan of Kansas (KanCare)'
  }, { id: '533', name: 'United Healthcare Community Plan of Michigan' }, {
    id: '534',
    name: 'United Healthcare Community Plan of New Jersey (AmeriChoice)'
  }, {
    id: '535',
    name: 'United Healthcare Community Plan of Tennessee (TennCare)'
  }, {
    id: '536',
    name: 'United Healthcare Dental'
  }, { id: '537', name: 'United Healthcare Facets Detroit' }, {
    id: '538',
    name: 'United Healthcare Facets Pittsburgh'
  }, { id: '539', name: 'United Healthcare Medicare Solutions (Evercare)' }, {
    id: '540',
    name: 'United Healthcare Student Resources (Student Insurance)'
  }, { id: '541', name: 'United Healthcare of the River Valley' }, {
    id: '542',
    name: 'Unity Health Plans Insurance Corporation'
  }, { id: '543', name: 'Univera Healthcare' }, {
    id: '544',
    name: 'University Physicians Care Advantage (AZ)'
  }, {
    id: '545',
    name: 'University of Arizona Health Plans (University Healthcare Marketplace)'
  }, {
    id: '546',
    name: 'University of Missouri Health (Coventry)'
  }, { id: '547', name: 'University of Utah Health Plans' }, { id: '548', name: 'Utah Medicaid' }, {
    id: '549',
    name: 'VNS Choice'
  }, { id: '550', name: 'Valley Health Plan' }, { id: '551', name: 'Vermont Medicaid' }, {
    id: '552',
    name: 'Veterans Affairs Fee Basis Program'
  }, { id: '553', name: 'Veterans Affairs Health Administration Center' }, {
    id: '554',
    name: 'Village Family Practice'
  }, { id: '555', name: 'Virginia Medicaid' }, { id: '556', name: 'Vista Health Plan' }, {
    id: '557',
    name: 'Viva Health'
  }, { id: '558', name: 'WEA Trust' }, { id: '559', name: 'WPS Insurance' }, {
    id: '560',
    name: 'Washington Medicaid'
  }, { id: '561', name: 'WebTPA' }, { id: '562', name: 'Wellcare' }, {
    id: '563',
    name: 'Wellmark Blue Cross and Blue Shield of Iowa'
  }, { id: '564', name: 'Wellmark Blue Cross and Blue Shield of South Dakota' }, {
    id: '565',
    name: 'West Virginia Medicaid'
  }, { id: '566', name: 'Western Health Advantage' }, { id: '567', name: 'Wisconsin Medicaid' }, {
    id: '568',
    name: 'Wisconsin Medicare (Part B)'
  }, { id: '569', name: 'Writers Guild' }, { id: '570', name: 'Wyoming Medicaid' }];
});