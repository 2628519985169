define('ceannate-web/components/meta-toast-bar/component', ['exports', 'ember-lifeline', 'ember-cli-bem/mixins/bem'], function (exports, _emberLifeline, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_meta-toast-bar',
    mods: ['variant', 'absolute'],
    classNameBindings: ['variant', 'no-bold:no-bold'],

    toast: Ember.inject.service(),

    showActions: Ember.computed.alias('toast.showActions'),
    visible: false,
    autoHide: false,
    timeout: 3000,
    scrollToTop: false,

    didReceiveAttrs: function didReceiveAttrs() {
      // Doing this in didRender instead of init
      // because these properties might change
      // after initial render

      this.get('toast').setProperties(this.getProperties(['message', 'primaryAction', 'secondaryAction', 'primaryActionLabel', 'secondaryActionLabel', 'variant', 'autoHide']));
    },
    didRender: function didRender() {
      var _this = this;

      if (this.get('visible')) {
        //eslint-disable-next-line ember-best-practices/no-global-jquery
        Ember.$(this.element).fadeIn('slow', function () {
          (0, _emberLifeline.runTask)(_this, function () {
            _this.$().parent().animate({
              scrollTop: 0
            });
          });
          if (_this.get('autoHide')) {
            (0, _emberLifeline.runTask)(_this, function () {
              //eslint-disable-next-line ember-best-practices/no-global-jquery
              Ember.$(_this.element).fadeOut('slow', function () {
                (0, _emberLifeline.runTask)(_this, function () {
                  _this.set('visible', false);
                });
              });
            }, _this.get('timeout'));
          }
        });
      }
    },
    didDestroyElement: function didDestroyElement() {
      this.set('visible', false);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      (0, _emberLifeline.runDisposables)(this);
    }
  });
});