define('ceannate-web/web/provider/workspace/settings/payment-method/update-stripe-code/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      code: {
        replace: true
      }
    },

    currentUser: Ember.inject.service(),
    resourceMetadata: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var provider = this.get('currentUser.provider');
      var code = params.code;
      if (code) {
        var _service = this.get('resourceMetadata');
        _service.write(provider, { stripe_auth_code: code });
        return provider.save().then(function () {
          _this.controllerFor('web.provider.workspace.settings.payment-method').setProperties({
            toastMessage: 'Stripe Connect account successfully linked.',
            toastVariant: 'success',
            toastVisible: 'true'
          });
          _this.get('router').replaceWith('web.provider.workspace.settings.payment-method');
        });
      }
    }
  });
});