define('ceannate-web/models/subscription', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    couponCode: [(0, _emberCpValidations.validator)('length', {
      max: 40,
      messageKey: 'subscriptions.cc-coupon-code-error'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    stripeToken: _emberData.default.attr('string'),
    subscriptionType: _emberData.default.attr('string'),
    paymentMethodId: _emberData.default.attr('string'),
    stripeId: _emberData.default.attr('string'),
    stripePlan: _emberData.default.attr('string'),
    endsAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    cardDetails: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          last4: '',
          brand: ''
        };
      }
    }),

    provider: _emberData.default.belongsTo('provider'),
    couponCode: _emberData.default.attr('string')
  });
});