define('ceannate-web/components/bootstrap/bs-modal/body/component', ['exports', 'ember-core-components-addon/components/bootstrap/bs-modal/body/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});