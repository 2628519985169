define('ceannate-web/web/provider/verify-email/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    isStudent: false,

    beforeModel: function beforeModel(_ref) {
      var queryParams = _ref.queryParams;

      if (queryParams.student === 'true') {
        this.set('isStudent', true);
      }
    },
    model: function model(params) {
      var _this = this;

      return this.store.createRecord('email', { confirmationCode: params.token }).save().catch(function () {
        _this.replaceWith('web.provider.login');
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      if (this.get('isStudent')) {
        return this.transitionTo('web.verify-email-success');
      }

      try {
        if (this.get('session.isAuthenticated')) {
          var provider = this.get('currentUser.provider');

          if (provider.get('registrationStep') === 'email_verification') {
            provider.set('registrationStep', 'general_information');
            provider.save().then(function () {
              return _this2.replaceWith('web.provider.register.information');
            }).catch(function () {
              return _this2.replaceWith('web.provider.login');
            });
          } else {
            this.replaceWith('web.provider.register');
          }
        } else {
          this.set('currentUser.emailRecentlyVerified', true);
          this.replaceWith('web.provider.login');
        }
      } catch (e) {
        this.replaceWith('web.provider.register.verify-account');
      }
    }
  });
});