define('ceannate-web/components/chat/audio-call/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tokbox-overlay', 'component_chat_audio-call'],
    classNameBindings: ['hide:hide'],

    tokbox: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isConnected: Ember.computed.readOnly('tokbox.connected'),
    store: Ember.inject.service(),
    counselingSession: null,
    counselingSessionUpdated: false,

    showCallQuality: false,

    didInsertElement: function didInsertElement() {
      this.set('tokbox.onStudentAudioDisconnect', this.onStudentAudioDisconnect.bind(this));
    },
    onStudentAudioDisconnect: function onStudentAudioDisconnect() {
      if (this.get('tokbox.callWasAccepted')) {
        if (this.get('counselingSessionUpdated') === false) {
          this.get('store').findRecord('counseling-session', this.get('counselingSession.id')).then(function (counselingSession) {
            var mysqlFormate = new Date().toISOString().slice(0, 23).replace('T', ' ');

            counselingSession.set('endedAt', mysqlFormate);

            counselingSession.save(); // => PATCH to '/posts/1'
          });
          this.set('counselingSessionUpdated', true);
        }
        this.set('showCallQuality', true);
      }
    },


    // Hide call quality if we're getting a call
    // eslint-disable-next-line ember-best-practices/no-observers
    hideShowCallQuality: Ember.observer('tokbox.{incoming,enabled}', function () {
      if (this.get('tokbox.enabled') && this.get('tokbox.incoming')) {
        this.set('showCallQuality', false);
      }
    }),

    student: Ember.computed.readOnly('tokbox.call.thread.student'),
    subtitle: Ember.computed('isConnected', 'tokbox.{outgoing,incoming}', function () {
      if (this.get('isConnected')) {
        this.set('counselingSession', null);
        this.set('counselingSessionUpdated', false);
        // store the audio call counseling session details
        if (this.get('tokbox.call.streamType') === 'audio') {
          var provider = this.get('store').peekRecord('provider', this.get('tokbox.call.thread.provider.id'));

          var student = this.get('store').peekRecord('student', this.get('tokbox.call.thread.student.id'));
          var mysqlFormate = new Date().toISOString().slice(0, 23).replace('T', ' ');

          this.set('counselingSession', this.get('store').createRecord('counseling-session', {
            provider: provider,
            tokboxSessionId: this.get('tokbox.call.id'),
            student: student,
            streamType: this.get('tokbox.call.streamType'),
            startedAt: mysqlFormate,
            endedAt: mysqlFormate
          }));
          this.get('counselingSession').save();
        }

        return this.get('i18n').t('chats.connected');
      }
      if (this.get('tokbox.incoming')) {
        return this.get('i18n').t('chats.incoming-audio-call');
      }
      if (this.get('tokbox.outgoing')) {
        return this.get('i18n').t('chats.outgoing-audio-call');
      }
    }),

    isMuted: Ember.computed.not('tokbox.micOn'),

    hide: Ember.computed('showCallQuality', 'tokbox.{enabled,call.streamType}', function () {
      if (this.get('showCallQuality')) {
        return false;
      } else {
        return !(this.get('tokbox.enabled') && this.get('tokbox.call.streamType') === 'audio');
      }
    }),

    actions: {
      toggleMic: function toggleMic() {
        this.get('tokbox').toggleAudio();
      },
      rejectCall: function rejectCall() {
        this.set('showCallQuality', false);
        this.get('tokbox').sendDisconnect();
      },
      hangUp: function hangUp() {
        this.get('tokbox').sendDisconnect();
        if (this.get('tokbox.callEnded') && this.get('tokbox.callWasAccepted') && this.get('tokbox.call.streamType') === 'audio') {
          this.set('showCallQuality', true);
        }
      },
      pickUp: function pickUp() {
        this.get('tokbox').acceptCall();
      }
    }
  });
});