define('ceannate-web/components/meta-rail-nav/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'aside',
    classNames: 'c_meta-rail-nav'
  });
});