define('ceannate-web/models/provider-student-charges', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    amount: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true
    })],
    summary: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('length', { max: 300 })],
    providerClaimBillableAmount: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      positive: true,
      gte: 0
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    amount: _emberData.default.attr('number'),
    summary: _emberData.default.attr('string'),
    target: _emberData.default.belongsTo('student'),
    creator: _emberData.default.belongsTo('provider'),
    createdAt: _emberData.default.attr('date'),
    failureMessage: _emberData.default.attr('string'),
    paymentSucceeded: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    providerClaimBillableAmount: _emberData.default.attr('string')
  });
});