define('ceannate-web/components/chat/video-call/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tokbox-overlay', 'component_chat_video-call'],
    classNameBindings: ['hide:hide', 'isConnected:video-connected'],

    tokbox: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isConnected: Ember.computed.readOnly('tokbox.connected'),
    store: Ember.inject.service(),

    counselingSession: null,
    counselingSessionUpdated: false,

    student: Ember.computed.readOnly('tokbox.call.thread.student'),
    subtitle: Ember.computed('isConnected', 'tokbox.{outgoing,incoming}', function () {
      if (this.get('isConnected')) {
        this.set('counselingSession', null);
        this.set('counselingSessionUpdated', false);
        // store the audio call counseling session details
        if (this.get('tokbox.call.streamType') === 'video') {
          var provider = this.get('store').peekRecord('provider', this.get('tokbox.call.thread.provider.id'));

          var student = this.get('store').peekRecord('student', this.get('tokbox.call.thread.student.id'));
          var mysqlFormate = new Date().toISOString().slice(0, 23).replace('T', ' ');

          this.set('counselingSession', this.get('store').createRecord('counseling-session', {
            provider: provider,
            tokboxSessionId: this.get('tokbox.call.id'),
            student: student,
            streamType: this.get('tokbox.call.streamType'),
            startedAt: mysqlFormate,
            endedAt: mysqlFormate
          }));
          this.get('counselingSession').save();
        }
      }

      if (this.get('tokbox.incoming')) {
        return this.get('i18n').t('chats.incoming-video-call');
      }
      if (this.get('tokbox.outgoing')) {
        return this.get('i18n').t('chats.outgoing-video-call');
      }
    }),

    isMuted: Ember.computed.not('tokbox.micOn'),
    videoDisabled: Ember.computed.not('tokbox.videoOn'),

    webcamStream: null,
    videoElement: null,

    showCallQuality: false,

    didInsertElement: function didInsertElement() {
      this.set('tokbox.onStudentVideoDisconnect', this.onStudentVideoDisconnect.bind(this));
    },
    onStudentVideoDisconnect: function onStudentVideoDisconnect() {
      if (this.get('tokbox.callWasAccepted')) {
        if (!this.get('counselingSessionUpdated')) {
          this.get('store').findRecord('counseling-session', this.get('counselingSession.id')).then(function (counselingSession) {
            var mysqlFormate = new Date().toISOString().slice(0, 23).replace('T', ' ');

            counselingSession.set('endedAt', mysqlFormate);

            counselingSession.save(); // => PATCH to '/posts/1'
          });
          this.set('counselingSessionUpdated', true);
        }
        this.set('showCallQuality', true);
      }
    },


    // Hide call quality if we're getting a call
    // eslint-disable-next-line ember-best-practices/no-observers
    hideShowCallQuality: Ember.observer('tokbox.{incoming,enabled}', function () {
      if (this.get('tokbox.enabled') && this.get('tokbox.incoming')) {
        this.set('showCallQuality', false);
      }
    }),

    hide: Ember.computed('showCallQuality', 'tokbox.{enabled,call.streamType}', function () {
      if (this.get('showCallQuality')) {
        return false;
      } else {
        return !(this.get('tokbox.enabled') && this.get('tokbox.call.streamType') === 'video');
      }
    }),

    // eslint-disable-next-line ember-best-practices/no-observers
    tokboxSessionChanged: Ember.observer('tokbox.session', function () {
      var _this = this;

      var session = this.get('tokbox.session');
      if (session) {
        var videoContainer = document.getElementById('subscriber');
        var theirStream = void 0;
        this.displayWebcam();
        session.on('streamCreated', function (_ref) {
          var stream = _ref.stream;

          theirStream = stream;
          var subscriber = session.subscribe(stream, { insertDefaultUI: false });
          subscriber.subscribeToAudio(false);
          subscriber.on('videoElementCreated', function (_ref2) {
            var element = _ref2.element;

            _this.set('videoElement', element);
          });
        });
        session.on('streamPropertyChanged', function (_ref3) {
          var changedProperty = _ref3.changedProperty,
              newValue = _ref3.newValue,
              stream = _ref3.stream;

          // This seems to be the only way to determine if the other person
          // has turned off their video
          if (changedProperty === 'hasVideo' && stream.id === theirStream.id) {
            if (newValue === false) {
              videoContainer.classList.add('hide');
            } else {
              videoContainer.classList.remove('hide');
            }
          }
        });
      }
    }),

    // eslint-disable-next-line ember-best-practices/no-observers
    observeVideoElement: Ember.observer('isConnected', 'videoElement', 'tokbox.{outgoing,incoming}', function () {
      var container = document.getElementById('subscriber');
      if (container.firstChild) {
        container.removeChild(container.firstChild);
      }
      if (this.get('tokbox.outgoing')) {
        if (this.get('videoElement')) {
          container.appendChild(this.get('videoElement'));
        }
      } else if (this.get('isConnected') && this.get('tokbox.incoming') && this.get('videoElement')) {
        container.appendChild(this.get('videoElement'));
      }
    }),

    displayWebcam: function displayWebcam() {
      var _this2 = this;

      var constraints = {
        video: {
          facingMode: 'user'
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        _this2.set('webcamStream', stream);
      }).catch(function (error) {
        _this2.set('webcamStream', null);
        _this2.set('tokbox.videoOn', false);
        throw new Error(error);
      });
    },


    // eslint-disable-next-line ember-best-practices/no-observers
    webcamStreamChanged: Ember.observer('webcamStream', function () {
      var webcamStream = this.get('webcamStream');
      var webcamElement = document.querySelector('.tokbox-overlay__container__webcam');
      if (webcamStream) {
        webcamElement.srcObject = webcamStream;
      } else {
        webcamElement.srcObject = null;
      }
    }),

    // eslint-disable-next-line ember-best-practices/no-observers
    tokboxVideoOnChanged: Ember.observer('tokbox.videoOn', function () {
      var videoOn = this.get('tokbox.videoOn');
      if (videoOn) {
        this.displayWebcam();
      } else {
        this.set('webcamStream', null);
      }
    }),

    actions: {
      toggleMic: function toggleMic() {
        this.get('tokbox').toggleAudio();
      },
      toggleVideo: function toggleVideo() {
        this.get('tokbox').toggleVideo();
      },
      rejectCall: function rejectCall() {
        this.set('showCallQuality', false);
        this.get('tokbox').sendDisconnect();
      },
      hangUp: function hangUp() {
        this.get('tokbox').sendDisconnect();
        if (this.get('tokbox.callEnded') && this.get('tokbox.callWasAccepted') && this.get('tokbox.call.streamType') === 'video') {
          this.set('showCallQuality', true);
        }
      },
      pickUp: function pickUp() {
        this.get('tokbox').acceptCall();
      }
    }
  });
});