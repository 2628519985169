define('ceannate-web/utils/model-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dashNotatedPhone = dashNotatedPhone;
  function dashNotatedPhone(dependentKey) {
    return Ember.computed(dependentKey, function () {
      var value = Ember.get(this, dependentKey);
      if (!value) return; // If no value, return
      var cleaned = value.replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      }
      return value;
    });
  }
});