define('ceannate-web/components/cta-button/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    disabled: false,
    tagName: 'button',
    blockName: 'c_cta-button',
    mods: ['size', 'default', 'warning'],
    attributeBindings: ['disabled', 'id', 'data-test', 'data-dismiss'],
    classNames: ['btn', 'btn-rounded'],
    classNameBindings: ['default:btn-clear:btn-peach', 'warning:btn-coral'],

    init: function init() {
      this._super.apply(this, arguments);
      this.click.bind(this);
    },
    click: function click() {
      this.onClick();
    }
  });
});