define('ceannate-web/web/provider/workspace/settings/subscription/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    deactivate: function deactivate() {
      this.set('toast.showActions', true);
      this.controller.setProperties({
        subscriptionType: this.get('currentUser.user.subscriptionType'),
        toastVisible: false
      });
    },
    model: function model() {
      var userId = this.get('currentUser.user.id');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', userId, {
          include: 'user_subscriptions'
        })
      });
    }
  });
});