define('ceannate-web/models/tokbox-session-rating', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    rating: _emberData.default.attr('number'),
    tokboxSession: _emberData.default.belongsTo('tokbox-session'),
    creator: _emberData.default.belongsTo('provider')
  });
});