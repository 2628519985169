define('ceannate-web/helpers/nl2br', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.nl2br = nl2br;
  function nl2br(params /*, hash*/) {
    var string = params[0];
    string.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return Ember.String.htmlSafe(string);
  }

  exports.default = Ember.Helper.helper(nl2br);
});