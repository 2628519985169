define('ceannate-web/components/provider/provider-signup-program/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    classNames: ['component__provider-signup-program'],
    blockName: 'c_provider-signup-program',

    i18n: Ember.inject.service(),

    universityProgram: null,
    provider: null,
    selectedUniversityPrograms: null,
    onEnrollUniversityProgram: null,
    onDeEnrollUniversityProgram: null,

    init: function init() {
      this._super.apply(this, arguments);
    },
    didUpdate: function didUpdate() {}
  });
});