define('ceannate-web/services/global-error', ['exports', 'ceannate-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    errors: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('errors', []);

      Ember.onerror = function (error) {
        if (error.message && error.message.includes('The adapter operation was aborted')) {
          _this.addError('Not connected to network please check your internet connection.');
        } else if (error.message && !error.message.includes('409')) {
          _this.addError('Something went wrong. Please reload your page and try again.');

          if (_environment.default.environment !== 'production') {
            throw error;
          }
        }
      };
    },
    addError: function addError(errorMessage) {
      this.get('errors').pushObject(errorMessage);
    },
    removeError: function removeError(errorMessage) {
      this.get('errors').removeObject(errorMessage);
    }
  });
});