define('ceannate-web/components/meta-form-field/component', ['exports', 'ember-form-for/components/p202/form-for/form-field/component', 'ember-cli-bem/mixins/bem'], function (exports, _component, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_bem.default, {
    blockName: 'component__meta-form-field',
    mods: ['showError:error'],

    showError: Ember.computed.and('error', 'showValidation')
  });
});