define('ceannate-web/mirage/factories/article', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    title: function title() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    summary: function summary() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    has_viewed: function has_viewed() {
      return _emberCliMirage.faker.random.boolean();
    },
    content_html: function content_html() {
      return '<b>Bruce!</b>';
    },
    status: function status() {
      return _emberCliMirage.faker.random.arrayElement(['draft', 'published']);
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    published_at: function published_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.past();
    },
    edited_at: function edited_at() {
      return _emberCliMirage.faker.date.past();
    }
  });
});