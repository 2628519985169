define('ceannate-web/adapters/application', ['exports', 'ember-data', 'ceannate-web/config/environment', 'ember-resource-metadata/adapter-mixin'], function (exports, _emberData, _environment, _adapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BANNED_CODE = '10004';

  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterMixin.default, {
    host: _environment.default.apiUrl,
    namespace: _environment.default.apiNS,
    session: Ember.inject.service(),

    headers: Ember.computed('session.data.authenticated.token', function () {
      var token = this.get('session.data.token');
      if (token) {
        return { Authorization: 'Bearer ' + token };
      }
      return {};
    }),

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 403 && payload.errors && payload.errors[0].code === BANNED_CODE) {
        this.get('session').invalidate();
        return false;
      }

      if (status === 403 && payload.errors && payload.errors[0].detail === 'Your profile is marked as inactive. Please re-login to re-activate your account.') {
        this.get('session').invalidate();
        return false;
      }

      return this._super(status, headers, payload, requestData);
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      if (snapshot.adapterOptions && snapshot.adapterOptions.paceIgnore) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Pace.ignore(function () {
            return _this._super(store, type, snapshot).then(resolve).catch(reject);
          });
        });
      } else {
        return this._super(store, type, snapshot);
      }
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      var query = Ember.$.param(this.buildQuery(snapshot));

      if (Ember.isPresent(query)) {
        url = url + '?' + query;
      }

      return this.ajax(url, 'PATCH', { data: data });
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var _this2 = this;

      if (snapshot.adapterOptions && snapshot.adapterOptions.paceIgnore) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Pace.ignore(function () {
            return _this2._super(store, type, id, snapshot).then(resolve).catch(reject);
          });
        });
      } else {
        return this._super(store, type, id, snapshot);
      }
    },
    query: function query(store, modelClass, _query) {
      var _this3 = this;

      var adapterOptions = _query.adapterOptions;
      delete _query.adapterOptions;

      if (adapterOptions && adapterOptions.paceIgnore) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Pace.ignore(function () {
            return _this3._super(store, modelClass, _query).then(resolve).catch(reject);
          });
        });
      } else {
        return this._super(store, modelClass, _query);
      }
    }
  });
});