define('ceannate-web/web/provider/workspace/settings/edit-profile/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      var profession = this.get('currentUser.user.profession'),
          provider = this.get('currentUser.user');

      return Ember.RSVP.hash({
        specialtyCategory: this.store.query('specialty-category', {
          include: 'specialties',
          filter: {
            profession: profession
          }
        }),
        user: this.store.peekRecord('provider', provider.get('id')),
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'specialties, faiths, languages, therapy_styles, gender, metadatas'
        }),
        faith: this.store.query('faith', {}),
        language: this.store.query('language', {}),
        therapyStyle: this.store.query('therapy-style', {}),
        gender: this.store.query('gender', {}),
        metadataTypes: this.store.query('metadata-type', {
          include: 'metadata_sub_types.metadatas,metadatas'
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      controller.set('user', this.get('currentUser.user'));
      controller.set('provider', models.user);
      controller.set('specialtyCategories', models.specialtyCategory);
      controller.set('faiths', models.faith);
      controller.set('languages', models.language);
      controller.set('therapyStyles', models.therapyStyle);
      controller.set('genders', models.gender);
    },
    deactivate: function deactivate() {
      var provider = this.modelFor(this.routeName).provider;
      provider.rollbackAttributes();
    }
  });
});