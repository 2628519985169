define('ceannate-web/mirage/factories/document', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    type: function type() {
      return 'eula';
    },
    html: function html() {
      return '<h1>Sign this EULA</h1>';
    }
  });
});