define('ceannate-web/web/provider/workspace/settings/deactivate/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.password', function () {
        return _typeof(this.get('model.password')) === 'object';
      })
    })]
  });

  exports.default = Ember.Controller.extend(Validations, {
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),

    user: Ember.computed.alias('currentUser.provider'),
    email: null,
    password: null,
    error: null,

    resetForm: function resetForm() {
      this.setProperties({
        password: null,
        error: null
      });
    },

    isFormValid: Ember.computed('validations.isTruelyValid', 'password', function () {
      if (this.get('password') === null) return false;
      return this.get('validations.isTruelyValid');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // Assign user email on init to set the initial value to display
      this.set('email', this.get('user.email'));
    },


    hasError: Ember.computed.notEmpty('error'),

    hasEmailFocusOut: false,
    hasEmailContent: Ember.computed.notEmpty('email'),
    shouldShowEmailValidations: Ember.computed.or('hasEmailFocusOut'),
    shouldShowEmailErrors: Ember.computed.and('shouldShowEmailValidations', 'validations.attrs.email.isInvalid'),

    hasPasswordFocusOut: false,
    hasPasswordContent: Ember.computed.notEmpty('password'),
    shouldShowPasswordValidations: Ember.computed.or('hasPasswordFocusOut'),
    shouldShowPasswordErrors: Ember.computed.and('shouldShowPasswordValidations', 'validations.attrs.password.isInvalid'),

    actions: {
      processDeactivation: function processDeactivation() {
        this.send('deactivateUser', this.getProperties('email', 'password'));
      },
      passwordFocusOut: function passwordFocusOut() {
        this.set('hasPasswordFocusOut', true);
      },
      emailFocusOut: function emailFocusOut() {
        this.set('hasEmailFocusOut', true);
      }
    }
  });
});