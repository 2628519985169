define('ceannate-web/mixins/text-counter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    charLimit: 1000,
    showCharLimit: true,
    charCounted: 0,

    init: function init() {
      this._super.apply(this, arguments);

      (true && Ember.warn('mixins/text-counter will be going away soon.  Please switch to over to using components/meta-textarea which encapsulates the logic and presentation into a single component', false, { id: 'mixins/text-counter' }));
    },


    textCounterCurrent: Ember.computed('charCounted', function () {
      return this.get('charCounted') + '/' + this.get('charLimit');
    }),

    actions: {
      calculateTextCount: function calculateTextCount(value) {
        this.set('charCounted', value.length);
      }
    }
  });
});