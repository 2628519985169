define('ceannate-web/mirage/resources/subscriptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockSubscriptions;
  function mockSubscriptions(server) {
    server.post('/subscriptions');
    server.patch('/subscriptions');
    server.delete('/subscriptions');
  }
});