define('ceannate-web/mirage/resources/eulas', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockEulas;
  function mockEulas(server) {
    server.get('/eula', function () {
      return new _emberCliMirage.Response(200, { 'X-Eula-Revision': 1 }, '<h1>End User License Agreement</h1><p>This is where content goes.</p>');
    });
  }
});