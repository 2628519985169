define('ceannate-web/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    provider: null,
    currentUser: null,
    emailRecentlyVerified: false,
    user: Ember.computed('provider', function () {
      (true && Ember.warn('current-user.user property is confusing and will be removed soon.  Please switch to using current-user.provider', false, { id: 'current-user.user' }));

      return this.get('provider');
    }),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').findRecord('session', this.get('session.data.authenticated.data.id'), {
          include: 'provider,provider.user,provider.degrees,provider.credentials,provider.tags,provider.subscription_orders,provider.user_subscriptions'
        }).then(function (s) {
          _this.setProperties({
            provider: s.get('provider.content'),
            currentUser: s.get('provider.user.content')
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});