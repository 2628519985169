define('ceannate-web/mirage/serializers/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    }
  });
});