define('ceannate-web/web/provider/register/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    activate: function activate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').addClass('beige');
    },
    deactivate: function deactivate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').removeClass('beige');
    },
    beforeModel: function beforeModel() {
      switch (this.get('currentUser.provider.registrationStep')) {
        case 'email_verification':
          this.replaceWith('web.provider.register.verify-account');
          break;

        case 'general_information':
          this.replaceWith('web.provider.register.information');
          break;

        case 'credential_information':
          this.replaceWith('web.provider.register.credentials');
          break;

        case 'insurance_and_legal_information':
          this.replaceWith('web.provider.register.insurance');
          break;

        case 'practice_information':
          this.replaceWith('web.provider.register.practice');
          break;

        case 'subscription_selection':
          this.replaceWith('web.provider.register.subscription');
          break;

        case 'admin_verification':
          this.replaceWith('web.provider.register.thanks');
          break;

        case 'registration_complete':
          this.replaceWith('web.provider.workspace.chats');
          break;

        default:
          return;
      }
    },
    model: function model() {
      return this.store.createRecord('provider', {});
    }
  });
});