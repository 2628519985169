define('ceannate-web/components/meta-input-with-validation/component', ['exports', 'ember-core-components-addon/components/shared/input-with-validation/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KEY_ENTER = 13;

  exports.default = _component.default.extend({
    optional: true,

    onEnter: null,

    keyDown: function keyDown(e) {
      if (e.keyCode === KEY_ENTER && this.get('onEnter')) {
        e.preventDefault();
        this.get('onEnter')(e);
      }
    },
    input: function input(e) {
      var handler = this.get('onInput');
      if (handler) {
        handler(e);
      }
    }
  });
});