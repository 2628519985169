define('ceannate-web/mirage/factories/thread', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    unread_messages: function unread_messages() {
      return _emberCliMirage.faker.random.number();
    },

    createdAt: function createdAt() {
      return new Date('December 17, 1995 03:24:00');
    },


    withProvider: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(thread, server) {
        var provider = server.create('provider');
        thread.update({
          provider: provider
        });
      }
    }),

    withStudent: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(thread, server) {
        var student = server.create('student');
        thread.update({
          student: student
        });
      }
    }),

    withMessages: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(thread, server) {
        server.createList('message', 5, { thread: thread });
      }
    })

    // afterCreate(thread, server) {
    //   let provider = server.create('provider');
    //   let student = server.create('student');
    //   //let messages = server.createList('message', 5, {thread, student});

    //   thread.update({
    //     provider,
    //     student,
    //   });
    // }
  });
});