define('ceannate-web/web/provider/workspace/protips/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      var articles = model.articles.filter(function (a) {
        return a.get('status') === 'published';
      });

      if (articles.length) {
        this.transitionTo('web.provider.workspace.protips.article', articles.get('firstObject.id'));
      }
    }
  });
});