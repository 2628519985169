define('ceannate-web/components/meta-footer/component', ['exports', 'ceannate-web/config/environment', 'ember-cli-bem/mixins/bem'], function (exports, _environment, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('copyrightYear', new Date().getFullYear().toString());
    },

    blockName: 'c_meta-footer',
    classNameBindings: ['mini:c_meta-footer--mini'],
    termsLink: _environment.default.termsLink,
    privacyLink: _environment.default.privacyPolicyLink
  });
});