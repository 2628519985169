define('ceannate-web/components/modals/basic-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: null,
    body: null,
    cta: null,
    modalId: Ember.computed('title', function () {
      // using string interpolation as suggested here to avoid SafeString error https://github.com/jamesarosen/ember-i18n/issues/374#issuecomment-224026904
      return 'modal-' + Ember.String.dasherize('' + this.get('title'));
    }),

    didInsertElement: function didInsertElement() {
      this.$('#' + this.get('modalId')).modal();
    }
  });
});