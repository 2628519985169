define('ceannate-web/components/provider/education-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['education-input'],
    degree: null,
    universityFocusOut: false,
    degreeFocusOut: false,
    yearFocusOut: false,

    showUniversityError: Ember.computed.and('universityFocusOut', 'degree.validations.attrs.university.isInvalid'),
    showDegreeError: Ember.computed.and('degreeFocusOut', 'degree.validations.attrs.degree.isInvalid'),
    showYearError: Ember.computed.and('yearFocusOut', 'degree.validations.attrs.graduationYear.isInvalid'),

    actions: {
      onFocusOut: function onFocusOut(field) {
        this.set(field + 'FocusOut', true);
      }
    }
  });
});