define('ceannate-web/components/file-upload-icon/component', ['exports', 'ceannate-web/mixins/file-upload-mixin'], function (exports, _fileUploadMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileUploadMixin.default, {
    classNames: ['c_file-upload-icon'],
    classNameBindings: ['document.src:c_file-upload-icon--uploaded'],
    title: null,
    desc: null,
    file: null,
    pdfUploadError: false,
    document: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var input = this.$('input');
      input.on('change', function (event) {
        var file = event.target.files[0];
        var reader = new FileReader();

        _this.set('file', file);

        if (file.type !== 'application/pdf') {
          _this.set('pdfUploadError', true);
        } else {
          reader.addEventListener('load', function (res) {
            Ember.run(function () {
              var file = _this.get('file');

              _this.generateFileRecord();
              _this.setFileRecordData(file.name, file.type);

              _this.saveRecord(file, false, res.target.result, {
                success: function success(fileUrl) {
                  var newDocument = _this.get('store').createRecord('document', {
                    type: _this.get('document.type'),
                    provider: _this.get('document.provider'),
                    src: fileUrl,
                    active: true
                  });
                  _this.get('onChange')(newDocument);
                }
              });
            });
          });

          if (file) {
            reader.readAsDataURL(file);
          }
        }
      });
    },


    actions: {
      selectFile: function selectFile() {
        var input = this.$('input');
        input.click();
      }
    }
  });
});