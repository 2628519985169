define('ceannate-web/components/provider/provider-signup-program/program/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_program',
    tagName: 'tr',
    classNames: [''],
    store: Ember.inject.service(),
    universityProgram: null,
    universityPrograms: null,
    selectedUniversityPrograms: null,
    providerUniversityPrograms: null,
    enrollUniversityProgram: null,
    deEnrollUniversityProgram: null,
    provider: null,
    errorMessage: null,
    isOpen: Ember.computed('universityProgram', 'selectedUniversityPrograms', function () {
      return this.get('selectedUniversityPrograms').findBy('id', this.get('universityProgram').id);
    }),
    providerUniversityProgram: Ember.computed('universityProgram', 'providerUniversityPrograms', function () {
      var _this = this;

      var pupObject = null;
      this.get('providerUniversityPrograms').forEach(function (pup) {
        if (pup.get('universityProgram').get('id') === _this.get('universityProgram').id) {
          pupObject = pup;
        }
      });
      return pupObject;
    }),
    comments: Ember.computed('universityProgram', 'providerUniversityPrograms', function () {
      var _this2 = this;

      var comments = '';
      this.get('providerUniversityPrograms').forEach(function (pup) {
        if (pup.get('universityProgram').get('id') === _this2.get('universityProgram').id) {
          comments = pup.get('comments');
        }
      });
      return comments;
    }),

    enrollDate: Ember.computed('universityProgram', 'providerUniversityPrograms', function () {
      var _this3 = this;

      var enroll_date = '';
      this.get('providerUniversityPrograms').forEach(function (pup) {
        if (pup.get('universityProgram').get('id') === _this3.get('universityProgram').id) {
          enroll_date = pup.get('enrollDate');
        }
      });
      return enroll_date;
    }),

    deEnrollDate: Ember.computed('universityProgram', 'providerUniversityPrograms', function () {
      var _this4 = this;

      var de_enroll_date = '';
      this.get('providerUniversityPrograms').forEach(function (pup) {
        if (pup.get('universityProgram').get('id') === _this4.get('universityProgram').id) {
          de_enroll_date = pup.get('deEnrollDate');
        }
      });
      return de_enroll_date;
    }),

    actions: {
      toggleOpen: function toggleOpen(isOpen) {
        this.set('isOpen', isOpen);
        if (isOpen) {
          if (!this.get('providerUniversityProgram')) {
            var providerUniversityProgram = this.get('store').createRecord('provider-university-program', {});
            providerUniversityProgram.set('universityProgram', this.get('universityProgram'));
            this.set('providerUniversityProgram', providerUniversityProgram);
          }

          this.get('enrollUniversityProgram')(this.get('universityProgram'), this.get('providerUniversityProgram'));
        } else {
          this.get('deEnrollUniversityProgram')(this.get('universityProgram'), this.get('providerUniversityProgram'));
        }
      },
      calculateTextCount: function calculateTextCount(value) {
        this.set('charCounted', value.length);
        this.set('errorMessage', '');
      }
    }
  });
});