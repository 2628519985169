define('ceannate-web/mirage/resources/specialties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockSpecialties;
  function mockSpecialties(server) {
    server.get('/specialties');
    server.get('/specialties/:id');
    server.post('/specialties');
    server.put('/specialties/:id');
    server.patch('/specialties/:id');
    server.del('/specialties/:id');
  }
});