define('ceannate-web/mirage/resources/documents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockDocuments;
  // import { Response } from 'ember-cli-mirage';

  function mockDocuments(server) {
    server.get('/documents', function (_ref) {
      var documents = _ref.documents;
      return documents.all();
    });
    server.get('/documents/:id');
    server.post('/documents');
    server.del('/documents/:id');
  }
});