define('ceannate-web/components/meta-topbar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    classNames: ['component__meta-topbar'],

    loginView: false,
    registerView: false,

    actions: {
      logout: function logout() {
        this.get('session').invalidate();
        this.get('routing.router').transitionTo('web.provider.login');
      }
    }
  });
});