define('ceannate-web/models/thread', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    archivedAt: _emberData.default.attr('date'),
    unreadMessages: _emberData.default.attr('number'),
    student: _emberData.default.belongsTo('student', { async: false }),
    provider: _emberData.default.belongsTo('provider', { async: false }),
    messages: _emberData.default.hasMany('message', { inverse: 'thread' }),
    archived: _emberData.default.attr('boolean', { defaultValue: false }),
    latestMessage: _emberData.default.belongsTo('message'),
    createdAt: _emberData.default.attr('date'),
    patientRelationship: _emberData.default.belongsTo('patient-relationship', { async: false })
  });
});