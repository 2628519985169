define('ceannate-web/models/provider-invoice', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    amountDue: _emberData.default.attr('number'),
    attempted: _emberData.default.attr('boolean'),
    attemptCount: _emberData.default.attr('number'),
    latestCharge: _emberData.default.belongsTo('charge'),
    subscriptionType: _emberData.default.attr('string')
  });
});