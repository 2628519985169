define('ceannate-web/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    // server.createList('post', 10);
    server.loadFixtures('insurance-companies');
    server.createList('profession', 2);
    server.createList('provider', 10);
    server.createList('specialty-category', 9);
    server.createList('thread', 5, 'withProvider', 'withStudent', 'withMessages');
    server.createList('document', 1);
    server.createList('article', 50);
  };
});