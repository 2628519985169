define('ceannate-web/web/provider/workspace/settings/credentials/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    model: function model() {
      var provider = this.get('currentUser.provider');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'degrees,credentials,credentials.documents,credentials.region,credentials.license_type.region,credentials.license_type.region'
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('provider', models.provider);
    },
    deactivate: function deactivate() {
      var provider = this.modelFor(this.routeName).provider;
      provider.get('degrees').toArray().forEach(function (d) {
        return d.rollbackAttributes();
      });
    }
  });
});