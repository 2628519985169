define('ceannate-web/mirage/resources/insurance-companies', ['exports', 'ceannate-web/mirage/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockInsuranceCompanies;
  function mockInsuranceCompanies(server) {
    server.get('/insurance_companies', function (_ref, _ref2) {
      var insuranceCompanies = _ref.insuranceCompanies;
      var queryParams = _ref2.queryParams;

      var page = +queryParams['page[number]'] || 1,
          collection = insuranceCompanies.all();

      if (queryParams['filter[name][like]']) {
        var searchCollection = collection.filter(function (term) {
          return term.name.indexOf(queryParams['filter[name][like]'].toLowerCase()) > -1;
        });

        return (0, _utils.paginatedModelCollection)('insurance_companies', searchCollection, page, queryParams['page[size]']);
      } else {
        return (0, _utils.paginatedModelCollection)('insurance_companies', collection, page, queryParams['page[size]']);
      }
    });
    server.get('/insurance_companies/:id');
    server.post('/insurance_companies');
    server.put('/insurance_companies/:id');
    server.patch('/insurance_companies/:id');
    server.del('/insurance_companies/:id');
  }
});