define('ceannate-web/web/provider/register/insurance/route', ['exports', 'ceannate-web/mixins/register-confirm-transition'], function (exports, _registerConfirmTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_registerConfirmTransition.default, {
    eula: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    },
    model: function model() {
      var provider = this.get('currentUser.provider');
      return this.get('store').findRecord('provider', provider.get('id'), {
        include: 'insurance_companies',
        reload: true
      });
    }
  });
});