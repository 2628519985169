define('ceannate-web/web/provider/workspace/settings/signup-program/route', ['exports', 'ceannate-web/mixins/signup-confirm-transition'], function (exports, _signupConfirmTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_signupConfirmTransition.default, {
    toast: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    infinity: Ember.inject.service(),

    deactivate: function deactivate() {
      this.controller.setProperties({
        isEditing: false,
        toastVariant: null,
        toastMessage: null
      });
      this.get('toast').hide();
    },
    model: function model() {
      var provider = this.get('currentUser.provider');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'user_subscriptions',
          reload: true
        }),
        /*
        universityPrograms: this.store.query('university-program', {
          'filter[active]': 1,
          include: 'university,program.program_type,payment_option.license_type.region',
          reload: true
        }),*/
        universityPrograms: this.get('infinity').model('university-program', {
          countParam: 'meta.count',
          'filter[active]': 1,
          page: {
            number: 1,
            size: 10
          },
          per_page: '',
          totalPagesParam: 'meta.total',
          include: 'university,program.program_type,payment_options',
          reload: true
        }),
        isSaving: false,

        providerUniversityPrograms: this.store.query('provider-university-program', {
          'filter[active]': 1,
          filter_full_list: 1,
          filter_priority: 1,
          'filter[provider_id]': provider.id,
          include: 'university_program'
        }).then(function (providerUniversityPrograms) {
          return providerUniversityPrograms.toArray();
        }),
        historyProviderUniversityPrograms: this.store.query('provider-university-program', {
          'filter[active]': 0,
          'filter[provider_id]': provider.id,
          include: 'university_program'
        }),
        selectedUniversityPrograms: this.store.query('provider-university-program', {
          'filter[active]': 1,
          filter_full_list: 1,
          filter_priority: 1,
          'filter[provider_id]': provider.id,
          include: 'university_program'
        }).then(function (result) {
          var sups = [];
          result.forEach(function (pup) {
            sups.push(pup.get('universityProgram'));
          });
          return sups.uniq();
        })
      });
    },
    afterModel: function afterModel(model) {
      model.isSaving = false;
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      controller.set('providerUniversityPrograms', models.providerUniversityPrograms);
      controller.set('isSaving', false);
      controller.set('hasMore', models.universityPrograms.meta.pagination.current_page <= models.universityPrograms.meta.pagination.total_pages ? true : false);
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});