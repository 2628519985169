define('ceannate-web/mirage/resources/passwords', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockPasswords;
  function mockPasswords(server) {
    server.post('/passwords');
  }
});