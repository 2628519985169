define('ceannate-web/components/credentials-forms/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['credentials-forms'],
    store: Ember.inject.service(),
    provider: null,
    isMentalHealthProvider: Ember.computed.equal('provider.profession', 'Mental Health'),
    readOnly: false,
    onChangeDocument: null,
    licenseDescription: null,

    filteredDegrees: Ember.computed.filterBy('provider.degrees', 'isDeleted', false),

    actions: {
      addNewDegree: function addNewDegree() {
        var provider = this.get('provider');
        this.get('store').createRecord('degree', {
          provider: provider
        });
      },
      deleteDegree: function deleteDegree(degree) {
        degree.destroyRecord();
        if (this.get('degreeDeleted')) {
          this.get('degreeDeleted')();
        }
      }
    }
  });
});