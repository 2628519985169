define('ceannate-web/web/provider/register/create-account/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    eula: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }

      controller.set('professions', [{ id: 1, name: 'Mental Health' }, { id: 2, name: 'Lawyer' }]);
      controller.set('user', this.modelFor('web.provider.register'));
      controller.get('actions.changeProfession').call(controller, 1); // set Mental Health as default profession
    },
    model: function model() {
      return Ember.RSVP.hash({
        practice: this.store.createRecord('practice', {
          location: {
            address: null
          }
        })
      });
    },


    actions: {
      registerUser: function registerUser() {
        var _this = this;

        this.controller.get('user').setProperties({
          registrationStep: 'confirm_email',
          location: this.controller.get('practice.location')
        });

        this.controller.set('hitError', false);

        this.controller.get('user').save().then(function () {
          window.localStorage.setItem('email', _this.controller.get('user.email'));
          var credentials = _this.controller.get('user').getProperties('email', 'password');
          _this.controller.set('error', null);
          _this.get('session').authenticate('authenticator:ceannate', credentials).then(function () {
            _this.get('eula').sign();
            _this.transitionTo('web.provider.register.verify-account');
          }).catch(function (error) {
            try {
              _this.controller.set('error', error.errors[0].detail);
            } catch (e) {
              _this.controller.set('error', 'An unknown error has occured.');
            }
          });
        }).catch(function () {
          _this.controller.set('hitError', true);
          Ember.run.scheduleOnce('afterRender', function () {
            var element = document.getElementById('register-alert');
            element.scrollIntoView({ behavior: 'smooth' });
          });
        });
      }
    }
  });
});