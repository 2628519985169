define('ceannate-web/components/provider/therapy-style-selector/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    classNames: ['component__therapyStyle-selection'],
    blockName: 'c_therapy-style-selection',

    categories: null,
    user: null,
    hasUserInteracted: false,
    hasTherapyStylesSelected: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      // offset to fix issues with not being valid on load,
      // this requires the didRender since this rendered
      // multiple times and changes so it needs to be able
      // to track this count properly
      this.set('hasTherapyStylesSelected', this.get('providerTherapyStyles.length') > 0);
    },


    actions: {
      selectTherapyStyle: function selectTherapyStyle(therapyStyle) {
        this.set('hasUserInteracted', true);
        var therapyStyles = this.get('providerTherapyStyles').indexOf(therapyStyle);
        if (therapyStyles > -1) {
          this.get('providerTherapyStyles').removeObject(therapyStyle);
        } else {
          this.get('providerTherapyStyles').addObject(therapyStyle);
        }

        this.set('hasTherapyStylesSelected', this.get('providerTherapyStyles.length') > 0);

        if (this.get('onChangeTherapyStyles')) {
          this.get('onChangeTherapyStyles')();
        }
      }
    }
  });
});