define('ceannate-web/mirage/factories/profession', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var professions = ['Mental Health', 'Lawyer'];

  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return professions[1];
    },
    createdAt: function createdAt() {
      return new Date();
    }
  });
});