define('ceannate-web/web/provider/workspace/clients/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    afterModel: function afterModel() {
      if (this.get('currentUser.provider.patientRelationships') && this.get('currentUser.provider.patientRelationships.length')) {
        this.transitionTo('web.provider.workspace.clients.client', this.get('currentUser.provider.patientRelationships.firstObject.obfuscatedId'));
      }
    }
  });
});