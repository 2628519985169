define('ceannate-web/mirage/utils', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getToken = getToken;
  exports.paginatedModelCollection = paginatedModelCollection;
  exports.token401Error = token401Error;
  exports.isAuthenticated = isAuthenticated;
  exports.authenticate = authenticate;
  exports.getCurrentUser = getCurrentUser;


  var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTUzODgwNzAyfQ.bfzW_YA57fAt5klkI5d7GRDGytww11QeFy5V7S4483E';

  function getToken() {
    return token;
  }

  function paginatedModelCollection(modelName, collection, page, limit) {
    var pages = void 0,
        next = void 0,
        prev = void 0,
        models = void 0,
        start = void 0,
        end = void 0;

    if (limit === 'all') {
      pages = 1;
    } else {
      limit = +limit;

      start = (page - 1) * limit, end = start + limit;

      pages = Math.ceil(collection.models.length / limit);
      models = collection.models.slice(start, end);

      if (start > 0) {
        prev = page - 1;
      }

      if (end < collection.models.length) {
        next = page + 1;
      }
    }

    collection.meta = {
      current_page: page,
      from: start,
      last_page: pages,
      per_page: limit,
      to: end,
      prev: prev,
      next: next,
      total: collection.models.length
    };

    if (models) {
      collection.models = models;
    }

    return collection;
  }

  function token401Error() {
    return new _emberCliMirage.Response(401, {}, {
      errors: [{
        status: '401',
        title: 'Error',
        detail: 'Email or Password is incorrect.'
      }]
    });
  }

  function isAuthenticated() {
    var esa = 'ember_simple_auth-session',
        data = localStorage.getItem(esa);
    if (Ember.testing) return true;
    return !!data;
  }

  function authenticate(_ref, request) {
    var sessions = _ref.sessions,
        providers = _ref.providers;

    // Fetch Basic Auth Headers
    var basicAuth = request.requestHeaders.Authorization,
        key = basicAuth.split(' ')[1],
        reversed = window.atob(key).split(':'),
        email = reversed[0],
        password = reversed[1];

    var provider = providers.findBy({ email: email, password: password });

    if (!provider) {
      return token401Error();
    }

    var session = sessions.create({ providerId: provider.id });

    return new _emberCliMirage.Response(201, { Authorization: 'Bearer ' + token }, session);
  }

  function getCurrentUser(users) {
    if (Ember.testing) return users.find(1);

    var esa = 'ember_simple_auth-session',
        data = localStorage.getItem(esa),
        parsed = JSON.parse(data),
        userId = parsed.authenticated.data.relationships.user.data.id;

    return users.find(userId);
  }
});