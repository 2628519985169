define('ceannate-web/web/provider/register/credentials/route', ['exports', 'ceannate-web/mixins/register-confirm-transition', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _registerConfirmTransition, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _registerConfirmTransition.default, {
    currentUser: Ember.inject.service(),
    eula: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    },
    model: function model() {
      var provider = this.get('currentUser.provider');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', provider.get('id'), {
          include: 'degrees,credentials,credentials.documents,credentials.region,credentials.license_type.region,credentials.license_type.region'
        }),
        regions: this.store.query('region', {
          filter_has_license_types: true
        })
      });
    },
    afterModel: function afterModel(models) {
      if (!models.provider.get('degrees.length')) {
        this.get('store').createRecord('degree', {
          provider: models.provider
        });
      }

      if (!models.provider.get('credentials.length')) {
        var hipaaDocument = this.get('store').createRecord('document', {
          type: 'hipaa',
          credential: this.get('credential'),
          provider: models.provider,
          active: true
        });

        var consentDocument = this.get('store').createRecord('document', {
          type: 'consent',
          credential: this.get('credential'),
          provider: models.provider,
          active: true
        });

        this.get('store').createRecord('credential', {
          licenseNumber: null,
          region: null,
          provider: models.provider,
          documents: [hipaaDocument, consentDocument]
        });
      }
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('provider', models.provider);
      controller.set('regions', models.regions);
      controller.set('steps', this.controllerFor('web.provider.register').steps);
      controller.set('models', [models.provider, models.regions]);
    }
  });
});