define('ceannate-web/components/create-invoice/component', ['exports', 'accounting'], function (exports, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__create-innvoice__item'],
    store: Ember.inject.service(),

    tagName: '',

    patientRelationship: null,
    showRelationshipRequiredModal: false,
    showCreateInvoiceModal: false,
    invoice: null,
    completedSession: true,
    chargeProviderClaimBillableAmount: 0,
    amountError: false,
    onError: false,
    errorTitle: '',
    errorDesc: '',
    invoiceType: Ember.computed('types,providerUniversityPrograms,remainingSession', function () {
      if (this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject') && this.get('remainingSession') > 0) {
        return this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType');
      } else {
        return this.get('types').objectAt(0);
      }
    }),
    selectedCounselingSessions: null,
    counselingSessions: Ember.computed('patientRelationship.student.id', function () {
      return this.get('store').query('counseling-session', {
        filter_student_id: this.get('patientRelationship.student.id'),
        sort_most_recent: true,
        filter_not_charged_sessions: true,
        filter_full_list: 1
      });
    }),

    billable_amount: Ember.computed('invoice.billable_amount', 'patientRelationship.student.university', {
      get: function get() {
        // return default value null
        if (this.get('invoice.billable_amount')) {
          return this.get('invoice.billable_amount') / 100;
        }

        return null;
      },
      set: function set(key, value) {
        var cents = _accounting.default.unformat(value) * 100;
        this.set('invoice.billable_amount', cents);
        return value;
      }
    }),

    isInValid: Ember.computed('invoice.validations.{isInvalid}', function () {
      if (this.get('invoice.validations.isInvalid')) {
        return true;
      }
      return false;
    }),

    showInvoiceDescErrors: Ember.computed('invoice.summary', function () {
      return this.get('invoice.summary.length') > 300;
    }),
    calcChargeType: function calcChargeType() {
      if (this.get('patientRelationship.isStudentEligible') === 0 || this.get('patientRelationship.isStudentEligible') === 2 || !this.get('patientRelationship.isProviderEligible')) {
        return this.set('invoiceType', this.get('types').objectAt(0));
      }
      switch (this.get('patientRelationship.student.universityProgramTypeSelector')) {
        case 'all_sessions_free':
          this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          break;
        case 'some_sessions_free':
          if (this.get('remainingSession') === 0) {
            this.set('invoiceType', this.get('types').objectAt(0));
          } else {
            this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          }
          break;
        case 'partially_paid_for_all_sessions_in_dollar':
          this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          break;
        case 'partially_paid_for_some_sessions_in_dollar':
          if (this.get('remainingSession') === 0) {
            this.set('invoiceType', this.get('types').objectAt(0));
          } else {
            this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          }
          break;
        case 'partially_paid_for_all_sessions_in_percentage':
          this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          break;
        case 'partially_paid_sessions_in_percentage':
          if (this.get('remainingSession') === 0) {
            this.set('invoiceType', this.get('types').objectAt(0));
          } else {
            this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          }
          break;
        case 'high_priority_ondeck':
          this.set('invoiceType', this.get('providerUniversityPrograms.universityProgram.paymentOptions.firstObject.chargeType'));
          break;
        default:
          this.set('invoiceType', this.get('types').objectAt(0));
          return;
      }
    },


    actions: {
      onClickedCTA: function onClickedCTA() {
        if (this.get('patientRelationship.status') !== 'active') {
          this.set('showRelationshipRequiredModal', true);
        } else if (!this.get('patientRelationship.provider.stripeLoginUrl')) {
          this.get('redirectToPayment')();
        } else {
          this.set('counselingSessions', this.get('store').query('counseling-session', {
            filter_student_id: this.get('patientRelationship.student.id'),
            sort_most_recent: true,
            filter_not_charged_sessions: true,
            filter_full_list: 1
          }));
          this.set('invoice', this.get('store').createRecord('charge', {
            include: 'creator,target,counseling_sessions,provider_university_charge.university,provider_university_charge.program,provider_university_charge.payment_option.license_type.region,student_charge',
            creator: this.get('patientRelationship.provider'),
            target: this.get('patientRelationship.student'),
            providerUniversityProgram: this.get('providerUniversityPrograms')
          }));
          this.set('selectedCounselingSessions', null);
          this.calcChargeType();
          this.set('showCreateInvoiceModal', true);
        }
      },
      dismiss: function dismiss() {
        if (this.get('amountError') === true) {
          this.set('amountError', false);
          this.set('onError', false);
          this.set('showCreateInvoiceModal', true);
        } else {
          this.set('amountError', false);
          this.set('onError', false);
          this.set('showCreateInvoiceModal', false);
        }
      },
      onSend: function onSend() {
        var _this = this;

        if (this.get('invoiceType.selector') === 'university' && this.get('invoice.billable_amount') * 100 > this.get('patientRelationship.student.universityProgram.paymentOptions.firstObject.sessionCost')) {
          this.set('showCreateInvoiceModal', false);
          this.set('onError', true);
          this.set('amountError', true);
          this.set('errorTitle', 'Error creating invoice.');
          this.set('errorDesc', 'Charge amount cannot be greater than $' + this.get('patientRelationship.student.universityProgram.paymentOptions.firstObject.sessionCost') / 100);
          return;
        }
        this.set('invoice.billable_amount', this.get('invoice.billable_amount') * 100);
        this.set('showCreateInvoiceModal', false);
        this.set('invoice.type', this.get('invoiceType.selector'));
        if (this.get('providerUniversityPrograms')) {
          this.set('invoice.providerUniversityProgram', this.get('providerUniversityPrograms'));
        }

        this.set('invoice.completedSession', this.get('completedSession'));
        if (this.get('selectedCounselingSessions')) {
          this.get('selectedCounselingSessions').forEach(function (cs) {
            _this.get('invoice.counselingSessions').addObject(cs);
          });
        }

        this.get('invoice').save({
          adapterOptions: {
            include: 'include-all-again'
          }
        }).then(function (invoice) {
          _this.get('store').query('charge', {
            page: {
              number: _this.pageNumber,
              size: 3
            },
            filter_student_id: _this.get('patientRelationship.student.id'),
            providerId: _this.get('patientRelationship.provider.id'),
            'filter[id]': invoice.id,
            include: 'creator,target,counseling_sessions,provider_university_charge.university,provider_university_charge.program,provider_university_charge.payment_option.license_type.region,student_charge'
          }).then(function (res) {
            _this.get('patientRelationship.charges').addObject(res.get('firstObject'));

            _this.get('refreshRoute')();

            // this.send('refreshModel');
            // this.sendAction('refreshRoute');
            // this.send('refreshRoute');
            // refreshRoute();
          });
        }).catch(function (errorRes) {
          _this.set('onError', true);
          var errors = errorRes.errors[0];
          if (errors) {
            _this.set('errorTitle', 'Error creating invoice');
            _this.set('errorDesc', errors.detail);
          }
        });
      }
    }
  });
});