define('ceannate-web/mirage/resources/email-verifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockEmailVerifications;
  function mockEmailVerifications(server) {
    server.post('/email_verifications');
  }
});