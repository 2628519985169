define('ceannate-web/components/student-insurance/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_student-insurance',

    patientRelationship: null,

    insurancePlans: null,
    activeInsurancePlan: Ember.computed.readOnly('insurancePlans.lastObject'),

    verifyInsurance: null,
    recentlyVerified: false,
    showDetailModal: false,
    block: null,

    disableVerifyInsurance: Ember.computed('student.{banned}', 'block', 'verifyInsurance.{isRunning}', function () {
      return this.get('block') || this.get('student.banned') || this.get('verifyInsurance.isRunning');
    }),

    insuranceDetails: Ember.computed('patientRelationship.insuranceData', function () {
      var data = JSON.parse(this.get('patientRelationship.insuranceData'));

      return renderjson(data);
    })
  });
});