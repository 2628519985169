define('ceannate-web/helpers/date-format', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateFormat = dateFormat;
  function dateFormat(params /*, hash*/) {
    return (0, _moment.default)(params[0]).format(params[1].toString());
  }

  exports.default = Ember.Helper.helper(dateFormat);
});