define('ceannate-web/helpers/meta-for-resource', ['exports', 'ember-resource-metadata/helpers/meta-for-resource'], function (exports, _metaForResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _metaForResource.default;
    }
  });
  Object.defineProperty(exports, 'metaForResource', {
    enumerable: true,
    get: function () {
      return _metaForResource.metaForResource;
    }
  });
});