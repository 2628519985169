define('ceannate-web/web/reset-password/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isResetPasswordButtonDisabled: Ember.computed('model.{password,passwordConfirm}', function () {
      return !(this.get('model.password') && this.get('model.passwordConfirm') && this.get('model.password') === this.get('model.passwordConfirm'));
    }),

    hasPasswordFocusOut: false,
    hasPasswordContent: Ember.computed.notEmpty('model.password'),
    shouldShowPasswordValidations: Ember.computed.or('hasPasswordFocusOut'),
    shouldShowPasswordErrors: Ember.computed.and('shouldShowPasswordValidations', 'model.validations.attrs.password.isInvalid'),

    hasPasswordConfirmationFocusOut: false,
    hasPasswordConfirmationContent: Ember.computed.notEmpty('model.passwordConfirm'),
    shouldShowPasswordConfirmationValidations: Ember.computed.or('hasPasswordConfirmationFocusOut'),
    shouldShowPasswordConfirmationErrors: Ember.computed.and('shouldShowPasswordConfirmationValidations', 'model.validations.attrs.passwordConfirm.isInvalid'),

    actions: {
      passwordFocusOut: function passwordFocusOut() {
        this.set('hasPasswordFocusOut', true);
      },
      passwordConfirmationFocusOut: function passwordConfirmationFocusOut() {
        this.set('hasPasswordConfirmationFocusOut', true);
      }
    }
  });
});