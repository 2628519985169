define('ceannate-web/utils/thread-infinity-model-options', ['exports', 'ceannate-web/utils/jsonapi-infinity-model-options'], function (exports, _jsonapiInfinityModelOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Object.assign({
    infinityCache: 31536000000,
    sort: '-last_message_created_at',
    per_page: '',
    include: 'student.university,student.student_free_session,provider,provider.patient_relationships,latest_message,patient_relationship,patient_relationship.provider,patient_relationship.student.university'
  }, _jsonapiInfinityModelOptions.default);
});