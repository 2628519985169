define('ceannate-web/helpers/equals', ['exports', 'ember-core-components-addon/helpers/equals'], function (exports, _equals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _equals.default;
    }
  });
  Object.defineProperty(exports, 'equals', {
    enumerable: true,
    get: function () {
      return _equals.equals;
    }
  });
});