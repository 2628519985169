define('ceannate-web/components/provider/edit-profile/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component_edit-profile'],
    provider: null,
    isLawyer: Ember.computed.equal('provider.profession', 'Lawyer'),
    filterNumbers: function filterNumbers(e) {
      var key = e.charCode || e.keyCode || 0;
      return key === 13 || key === 8 || key === 9 || key === 46 || key >= 35 && key <= 40 || key >= 48 && key <= 57;
    },


    actions: {
      selectMetadata: function selectMetadata(metadata) {
        this.set('hasUserInteracted', true);
        var metadatas = this.get('providerMetadatas').indexOf(metadata);
        if (metadatas > -1) {
          this.get('providerMetadatas').removeObject(metadata);
        } else {
          this.get('providerMetadatas').addObject(metadata);
        }

        this.set('hasMetadatasSelected', this.get('providerMetadatas.length') > 0);

        if (this.get('onChangeMetadatas')) {
          this.get('onChangeMetadatas')();
        }
      }
    }
  });
});