define('ceannate-web/serializers/charge', ['exports', 'ceannate-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var value = snapshot.attr(key);
      if (Ember.isNone(value)) {
        return;
      }
      this._super(snapshot, json, key, attributes);
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      var belongsTo = snapshot.belongsTo(key);
      key = this.keyForRelationship ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;
      if (key == 'counseling_sessions' && Ember.isNone(belongsTo)) {
        json = null;
      } else {
        this._super(snapshot, json, relationship);
      }
    }
  });
});