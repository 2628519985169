define('ceannate-web/components/image-upload/component', ['exports', 'ceannate-web/mixins/file-upload-mixin', 'ember-lifeline', 'ember-form-for/utils/require-properties'], function (exports, _fileUploadMixin, _emberLifeline, _requireProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileUploadMixin.default, {
    classNames: ['component__image-upload'],

    init: function init() {
      this._super.apply(this, arguments);

      (0, _requireProperties.default)(this, ['onFileUploaded']);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _emberLifeline.addEventListener)(this, getFileInput(this), 'change', function (event) {
        // Let's prepare to grab the presigned url
        var file = event.target.files[0];

        _this.generateFileRecord();
        _this.setFileRecordData(file.name, file.type);
        _this.saveRecord(file, true, null, {
          success: function success(imageUrl) {
            _this.get('onFileUploaded')(imageUrl, file);
          }
        });
      });
    },
    destroy: function destroy() {
      (0, _emberLifeline.runDisposables)(this);
    },


    actions: {
      clickFileInput: function clickFileInput() {
        getFileInput(this).click();
      }
    }
  });


  function getFileInput(component) {
    return component.element.querySelector('input[type=file]');
  }
});