define('ceannate-web/services/tokbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    inactivity: Ember.inject.service(),

    enabled: false,
    call: null,

    session: null,
    publisher: null,
    connectionId: null,

    outgoingCallTimeout: 15,
    outgoingCallWaitTime: 0,

    currentUser: Ember.inject.service(),

    incoming: Ember.computed.alias('isIncomingCall'),
    outgoing: Ember.computed.alias('isOutgoingCall'),
    connected: false,

    apiKey: Ember.computed.alias('call.tokboxMeta.tokbox_api_key'),
    sessionId: Ember.computed.alias('call.tokboxSessionId'),
    token: Ember.computed.alias('call.tokboxMeta.tokbox_token'),

    callEnded: false,
    callWasAccepted: false,

    micOn: true,
    videoOn: true,

    initializeSession: function initializeSession(call) {
      var auto = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!this.get('connected')) {
        this.set('call', call);
        this.startTokboxSession(opts, auto);
        // Let the UI know we're ready to show the call screen
        this.set('enabled', true);
      }
    },


    isOutgoingCall: Ember.computed('call.creator.id', function () {
      return this.get('currentUser.user.id') === this.get('call.creator.id');
    }),

    isIncomingCall: Ember.computed('isOutgoingCall', function () {
      return !this.get('isOutgoingCall');
    }),

    toggleAudio: function toggleAudio() {
      var micOn = this.toggleProperty('micOn');
      if (this.get('publisher')) {
        this.get('publisher').publishAudio(micOn);
      }
    },
    toggleVideo: function toggleVideo() {
      var videoOn = this.toggleProperty('videoOn');
      if (this.get('publisher')) {
        this.get('publisher').publishVideo(videoOn);
      }
    },
    startPublishing: function startPublishing() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      // Don't publish again if we already have a publisher.
      if (this.get('publisher')) return;
      var publisher = OT.initPublisher('', {
        insertDefaultUI: false,
        publishAudio: this.get('micOn'),
        publishVideo: opts.video && this.get('videoOn')
      }, this.handleError);

      this.get('session').publish(publisher, this.handleError);

      this.set('publisher', publisher);
    },
    acceptStream: function acceptStream() {
      var props = this.getProperties(['session', 'stream']);
      props.session.subscribe(props.stream, '', {
        insertDefaultUI: false
      }, this.handleError);
      this.set('connected', true);
    },
    acceptCall: function acceptCall() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.startTokboxSession(opts, false);
      this.startPublishing(opts);
    },
    sendDisconnect: function sendDisconnect() {
      if (this.get('session')) {
        this.get('session').signal({ type: 'DECLINE' });
      }
      this.disconnect();
    },
    startTokboxSession: function startTokboxSession() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var auto = arguments[1];

      var self = this;
      var session = OT.initSession(this.get('apiKey'), this.get('sessionId'));
      this.set('session', session);
      this.set('callEnded', false);

      session.on('sessionConnected', function () {
        if (auto) {
          _this.startPublishing(opts);
        }
      });

      // Subscribe to a newly created stream
      session.on('streamCreated', function (event) {
        _this.set('stream', event.stream);
      });

      session.on('streamDestroyed', function () {
        _this.disconnect();
      });

      session.on('signal:DECLINE', function () {
        _this.disconnect();
      });

      session.on('connectionDestroyed', function () {
        _this.disconnect();
      });

      // Connect to the session
      session.connect(this.get('token'), function (error) {
        // If the connection is successful, initialize a publisher and publish to the session
        if (error) {
          self.handleError(error);
        } else {
          self.get('inactivity').stopTimer();
          self.set('connectionId', session.connection.connectionId);
        }
      });
    },
    handleError: function handleError(error) {
      if (error) {
        // alert(error.message);
      }
    },


    // eslint-disable-next-line ember-best-practices/no-observers
    watchStream: Ember.observer('session', 'stream', 'publisher', function () {
      var props = this.getProperties(['session', 'stream', 'publisher']);
      // If we have a publisher, we've already accepted the call
      // But sometimes we don't have a stream to subscribe to
      // So we wait for that here. Checking session just for safe-keeping
      if (props.session && props.stream && props.publisher) {
        props.session.subscribe(props.stream, '', {
          insertDefaultUI: false
        }, this.handleError);
        this.set('callWasAccepted', true);
        this.set('connected', true);
      }
    }),

    resetSession: function resetSession() {
      this.setProperties({
        enabled: false,
        session: null,
        publisher: null,
        connectionId: null,
        connected: false,
        sessionId: null,
        token: null,
        stream: null,
        callEnded: true,
        micOn: true,
        videoOn: true
      });
      this.get('inactivity').startTimer();
    },
    disconnect: function disconnect() {
      var streamType = this.get('call.streamType');
      if (this.get('session')) {
        this.get('session').disconnect();
      }
      this.resetSession();
      this.get('tokbox.call.streamType') === 'audio';
      if (streamType === 'audio' && this.onStudentAudioDisconnect) this.onStudentAudioDisconnect();
      if (streamType === 'video' && this.onStudentVideoDisconnect) this.onStudentVideoDisconnect();
    }
  });
});