define('ceannate-web/mirage/resources/messages', ['exports', 'ceannate-web/mirage/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockMessages;
  function mockMessages(server) {
    //  server.get('/messages');
    server.get('/messages', function (_ref, _ref2) {
      var threads = _ref.threads;
      var queryParams = _ref2.queryParams;

      var page = +queryParams['page[number]'] || 1;
      if (queryParams['filter_thread_id']) {
        var searchCollection = threads.find(queryParams['filter_thread_id']);
        return (0, _utils.paginatedModelCollection)('messages', searchCollection.messages, page, queryParams['page[size]']);
      } else {
        return (0, _utils.paginatedModelCollection)('messages', threads.messages, page, queryParams['page[size]']);
      }
    });
    server.post('/messages');
    server.patch('/messages/:id');
  }
});