define('ceannate-web/services/toast', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    message: '',
    visible: false,
    primaryAction: null,
    primaryActionLabel: '',
    secondaryAction: null,
    secondaryActionLabel: '',
    variant: null,
    showActions: true,

    show: function show() {
      this.set('visible', true);
    },
    hide: function hide() {
      this.set('visible', false);
    }
  });
});