define('ceannate-web/utils/transform-query-params', ['exports', 'ember-transition-helper/utils/transform-query-params'], function (exports, _transformQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _transformQueryParams.default;
    }
  });
});