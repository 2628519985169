define('ceannate-web/authenticators/ceannate', ['exports', 'ember-simple-auth/authenticators/base', 'ceannate-web/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    eula: Ember.inject.service(),
    authUrl: 'sessions',
    timeTolerance: 60,

    restore: function restore(data) {
      return new Promise(function (resolve) {
        resolve(data);
      });
    },
    authenticate: function authenticate(args) {
      var _this = this;

      var authField = 'Basic ' + btoa([args.email, args.password].join(':'));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          method: 'POST',
          url: _environment.default.apiUrl + '/' + _environment.default.apiNS + '/' + _this.get('authUrl') + '?include=provider,provider.user,provider.tags',
          accepts: { json: 'application/vnd.api+json' },
          dataType: 'json',
          contentType: 'application/vnd.api+json',
          data: JSON.stringify({
            data: {
              type: 'sessions'
            }
          }),
          headers: {
            Authorization: authField
          }
        }).then(function (data, status, jqXHR) {
          // Success.
          var token = jqXHR.getResponseHeader('Authorization').split(' ')[1];
          var store = _this.get('store');
          Ember.run(function () {
            store.pushPayload(data);
            _this.get('session').set('data.token', token);
            resolve(data);
          });
        }, function (jqXHR) {
          // Failure.
          reject(jqXHR.responseJSON || jqXHR.responseText);
        });
      });
    },
    invalidate: function invalidate(data) {
      var _this2 = this;

      this.get('eula').set('acceptedEula', false);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (data.authenticated) {
          var sessionId = data.authenticated.id;
          var baseUrl = _environment.default.apiUrl + '/' + _environment.default.apiNS;
          Ember.$.ajax({
            method: 'DELETE',
            url: baseUrl + '/' + _this2.get('authUrl') + '/' + sessionId,
            dataType: 'json'
          }).then(function () {
            // Success.
            delete data.token;
            resolve();
          }, function (jqXHR) {
            // Failure.
            reject(jqXHR.responseJSON || jqXHR.responseText);
          });
        } else {
          resolve();
        }
      });
    }
  });
});