define('ceannate-web/adapters/password', ['exports', 'ceannate-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    session: Ember.inject.service(),

    headers: Ember.computed('session.data.resetToken', function () {
      var token = this.get('session.data.resetToken');
      return {
        Authorization: 'Basic ' + token
      };
    })
  });
});