define('ceannate-web/components/step-header/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_step-header',
    currentStep: null,
    steps: null
  });
});