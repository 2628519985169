define('ceannate-web/web/provider/register/information/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ceannate-web/mixins/register-confirm-transition'], function (exports, _authenticatedRouteMixin, _registerConfirmTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _registerConfirmTransition.default, {
    eula: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    },
    model: function model() {
      var profession = this.get('currentUser.user.profession'),
          provider = this.get('currentUser.user');

      return Ember.RSVP.hash({
        specialtyCategory: this.store.query('specialty-category', {
          include: 'specialties',
          filter: {
            profession: profession
          }
        }),
        user: this.store.findRecord('provider', provider.get('id'), {
          include: 'specialties, faiths, languages, therapy_styles, gender, metadatas'
        }),
        faith: this.store.query('faith', {}),
        language: this.store.query('language', {}),
        therapyStyle: this.store.query('therapy-style', {}),
        gender: this.store.query('gender', {}),
        metadataTypes: this.store.query('metadata-type', {
          include: 'metadata_sub_types.metadatas,metadatas'
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('user', this.get('currentUser.user'));
      controller.set('provider', models.user);
      controller.set('specialtyCategories', models.specialtyCategory);
      controller.set('models', [models.user]);
      controller.set('faiths', models.faith);
      controller.set('languages', models.language);
      controller.set('therapyStyles', models.therapyStyle);
      controller.set('genders', models.gender);
    }
  });
});