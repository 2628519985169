define('ceannate-web/mirage/config', ['exports', 'ceannate-web/mirage/resources/sessions', 'ceannate-web/mirage/resources/users', 'ceannate-web/mirage/resources/eulas', 'ceannate-web/mirage/resources/password-resets', 'ceannate-web/mirage/resources/passwords', 'ceannate-web/mirage/resources/professions', 'ceannate-web/mirage/resources/providers', 'ceannate-web/mirage/resources/specialties', 'ceannate-web/mirage/resources/insurance-companies', 'ceannate-web/mirage/resources/specialty-categories', 'ceannate-web/mirage/resources/files', 'ceannate-web/mirage/resources/s3', 'ceannate-web/mirage/resources/subscriptions', 'ceannate-web/mirage/resources/credentials', 'ceannate-web/mirage/resources/degrees', 'ceannate-web/mirage/resources/email-verifications', 'ceannate-web/mirage/resources/threads', 'ceannate-web/mirage/resources/messages', 'ceannate-web/mirage/resources/blocks', 'ceannate-web/mirage/resources/pusher', 'ceannate-web/mirage/resources/documents', 'ceannate-web/mirage/resources/signatures', 'ceannate-web/mirage/resources/regions', 'ceannate-web/mirage/resources/articles'], function (exports, _sessions, _users, _eulas, _passwordResets, _passwords, _professions, _providers, _specialties, _insuranceCompanies, _specialtyCategories, _files, _s, _subscriptions, _credentials, _degrees, _emailVerifications, _threads, _messages, _blocks, _pusher, _documents, _signatures, _regions, _articles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // These comments are here to help you get started. Feel free to delete them.
    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.urlPrefix = 'http://localhost'; // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = '/api/v1'; // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing
    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/
    */

    this.passthrough('/write-coverage'); // Code Coverage Pass-Through
    this.passthrough('https://dev.api.meta.app/api/v1/**');
    this.passthrough('https://dev.api.meta.app/**');
    this.passthrough('https://dev.api1.meta.app/api/v1/**');
    this.passthrough('https://dev.api1.meta.app/**');
    this.passthrough('http://localhost:8080/api/v1/**');
    this.passthrough('http://localhost:8080/**');
    this.passthrough('http://localhost:32769/api/v1/**');
    this.passthrough('http://localhost:32769/**');
    this.passthrough('https://meta-api-dev-privateassetsbucket-18m6prl2mw22n.s3.amazonaws.com/**');
    this.passthrough('https://meta-api-dev-publicassetsbucket-199af9kzn35an.s3.amazonaws.com/**');
    this.passthrough('https://hlg.tokbox.com/**');
    this.passthrough('https://config.opentok.com/**');
    this.passthrough('https://anvil.opentok.com/**');
    this.passthrough('https://api.opentok.com/**');
    this.passthrough('https://api-standard.opentok.com/**');

    (0, _sessions.default)(this);
    (0, _users.default)(this);
    (0, _passwordResets.default)(this);
    (0, _passwords.default)(this);
    (0, _professions.default)(this);
    (0, _providers.default)(this);
    (0, _specialties.default)(this);
    (0, _insuranceCompanies.default)(this);
    (0, _specialtyCategories.default)(this);
    (0, _subscriptions.default)(this);
    (0, _files.default)(this);
    (0, _s.default)(this);
    (0, _credentials.default)(this);
    (0, _degrees.default)(this);
    (0, _emailVerifications.default)(this);
    (0, _threads.default)(this);
    (0, _messages.default)(this);
    (0, _documents.default)(this);
    (0, _signatures.default)(this);
    (0, _regions.default)(this);
    (0, _blocks.default)(this);
    (0, _articles.default)(this);

    this.namespace = null;
    // If you need to not have the /api/v1 prefix, add configs here

    (0, _eulas.default)(this);
    (0, _pusher.default)(this);
  };
});