define('ceannate-web/components/chat/call-quality/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__chat__call-quality'],
    currentUser: Ember.inject.service(),
    tokbox: Ember.inject.service(),
    store: Ember.inject.service(),

    actions: {
      rate: function rate(rating) {
        var tokboxSessionRating = this.get('store').createRecord('tokbox-session-rating', {
          rating: rating,
          creator: this.get('currentUser.user'),
          tokboxSession: this.get('tokbox.call')
        });

        tokboxSessionRating.save();

        // Reset visibility for the next call
        this.set('tokbox.callWasAccepted', false);
        this.set('showCallQuality', false);
      }
    }
  });
});