define('ceannate-web/web/reset-password/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    isStudent: null,

    activate: function activate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').addClass('beige');
    },
    deactivate: function deactivate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').removeClass('beige');
    },
    model: function model(params, transition) {
      // Fetch email
      var email = window.localStorage.getItem('reset-email');

      if (transition.queryParams.student === 'true') {
        this.set('isStudent', true);
      }

      return this.store.createRecord('password', {
        token: params.token,
        email: email
      });
    },
    clearResetData: function clearResetData() {
      window.localStorage.removeItem('reset-email');
      window.localStorage.removeItem('reset-token');
    },


    actions: {
      submitPasswordReset: function submitPasswordReset() {
        var _this = this;

        var token = window.btoa(this.controller.get('model').get('email') + ':' + this.controller.get('model').get('token'));
        this.get('session').set('data.resetToken', token);

        this.controller.get('model').save().then(function () {
          _this.clearResetData();

          if (_this.get('isStudent')) {
            _this.transitionTo('web.reset-success.student');
          } else {
            _this.transitionTo('web.reset-success.provider');
          }
        }).catch(function () {});
      }
    }
  });
});