define('ceannate-web/web/provider/workspace/chats/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    unreadThreads: Ember.computed('model.threads.@each.unreadMessages', function () {
      return this.get('model.threads').filter(function (t) {
        return t.get('unreadMessages') > 0;
      });
    }),

    unreadTitle: Ember.computed('unreadThreads.length', function () {
      if (this.get('unreadThreads.length')) {
        return this.get('i18n').t('chats.new-message');
      }
      return this.get('i18n').t('chats.select-a-chat');
    }),

    unreadDescription: Ember.computed('unreadThreads.length', function () {
      if (this.get('unreadThreads.length')) {
        return this.get('i18n').t('chats.you-have-unread-chats', {
          count: this.get('unreadThreads.length')
        });
      }
      return this.get('i18n').t('chats.your-chats-are-available-to-the-left-select-one');
    }),

    filteredThreads: Ember.computed('model.threads.@each.{archived,archivedAt}', function () {
      return this.get('model.threads').filter(function (t) {
        return t.get('archived') === false && !t.get('archivedAt');
      });
    })
  });
});