define('ceannate-web/models/emergency-contact', ['exports', 'ember-data', 'ceannate-web/utils/model-utils'], function (exports, _emberData, _modelUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    relationship: _emberData.default.attr('string'),

    dashNotatedPhone: (0, _modelUtils.dashNotatedPhone)('phone')
  });
});