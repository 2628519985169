define('ceannate-web/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var users = ['Andre Sugai', 'Kyle Haptonstall', 'Parker Cameron', 'Peter Wensel', 'Brice Hartmann', 'Sean Clayton', 'Julian Miller', 'Gregory Avery-Weir', 'Shannon Balmat', 'Donnie Detcheva'];

  exports.default = _emberCliMirage.Factory.extend({
    email: function email(i) {
      if (users[i]) {
        return users[i].split(' ')[0].toLowerCase() + '@makewithora.com';
      } else {
        return _emberCliMirage.faker.internet.email();
      }
    },
    username: function username(i) {
      if (users[i]) {
        return users[i].split(' ')[0].toLowerCase();
      } else {
        return _emberCliMirage.faker.internet.userName();
      }
    },
    firstName: function firstName(i) {
      if (users[i]) {
        return users[i].split(' ')[0];
      } else {
        return _emberCliMirage.faker.name.firstName();
      }
    },
    lastName: function lastName(i) {
      if (users[i]) {
        return users[i].split(' ')[1];
      } else {
        return _emberCliMirage.faker.name.lastName();
      }
    },
    suffix: function suffix() {
      var random = _emberCliMirage.faker.random.boolean();
      if (random) {
        return _emberCliMirage.faker.name.suffix();
      }
      return null;
    },
    prefix: function prefix() {
      var random = _emberCliMirage.faker.random.boolean();
      if (random) {
        return _emberCliMirage.faker.name.prefix();
      }
      return null;
    },
    verified: function verified() {
      return _emberCliMirage.faker.random.boolean();
    },
    eulaRevision: function eulaRevision() {
      return _emberCliMirage.faker.random.number();
    },
    createdAt: function createdAt() {
      return _emberCliMirage.faker.date.past();
    },
    profession: function profession() {
      var professions = ['Mental Health', 'Lawyer'];

      return _emberCliMirage.faker.random.arrayElement(professions);
    },
    photoUrl: function photoUrl() {
      return 'https://fakeimg.pl/100/282828/eae0d0/?&retina=1text=Hello';
    },


    password: 'supersecret'
  });
});