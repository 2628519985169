define('ceannate-web/web/provider/eula/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    eula: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    provider: Ember.computed.alias('currentUser.provider'),

    activate: function activate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').addClass('beige');
    },
    deactivate: function deactivate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').removeClass('beige');
    },
    model: function model() {
      return this.get('store').query('document', {
        'filter[type]': 'eula',
        'filter[user_type]': 'providers',
        'filter[required]': 1
      });
    },
    afterModel: function afterModel(model) {
      if (this.get('eula.acceptedEula') || this.get('provider') && model.get('firstObject.signedDate')) {
        this.get('eula').set('acceptedEula', true);
        this.get('eula').handleRedirect();
      }

      return this._super.apply(this, arguments);
    }
  });
});