define('ceannate-web/adapters/metadata-sub-type', ['exports', 'ceannate-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return pluralize(underscore(modelName));
    },
    keyForRelationship: function keyForRelationship(rawKey) {
      return underscore(rawKey);
    }
  });
});