define('ceannate-web/services/registration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),

    steps: Ember.computed('i18n.locale', function () {
      return [this.get('i18n').t('register.steps.intro'), this.get('i18n').t('register.steps.credentials'), this.get('i18n').t('register.steps.insurance'), this.get('i18n').t('register.steps.practice')];
    })
  });
});