define('ceannate-web/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/article.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/article.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/block.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/block.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/document.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/document.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/practice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/practice.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/profession.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/profession.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/provider.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/provider.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/session.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/specialty-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/specialty-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/specialty.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/specialty.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/student.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/student.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/thread.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/thread.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/insurance-company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/insurance-company.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/professions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/professions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/articles.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/articles.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/blocks.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/blocks.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/credentials.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/credentials.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/degrees.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/degrees.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/documents.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/documents.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/email-verifications.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/email-verifications.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/eulas.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/eulas.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/files.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/files.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/insurance-companies.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/insurance-companies.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/messages.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/messages.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/password-resets.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/password-resets.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/passwords.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/passwords.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/practices.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/practices.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/professions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/professions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/providers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/providers.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/pusher.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/pusher.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/regions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/regions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/s3.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/s3.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/sessions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/sessions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/signatures.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/signatures.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/specialties.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/specialties.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/specialty-categories.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/specialty-categories.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/subscriptions.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/subscriptions.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/threads.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/threads.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/resources/users.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/resources/users.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/password-reset.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/password-reset.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/provider.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/provider.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/session.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/utils.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils.js should pass ESLint\n\n');
  });
});