define('ceannate-web/serializers/application', ['exports', 'ember-data', 'ember-inflector', 'ember-resource-metadata/serializer-mixin'], function (exports, _emberData, _emberInflector, _serializerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend(_serializerMixin.default, {
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    },
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.underscore(key);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    },
    serializeIntoHash: function serializeIntoHash(data, type, snapshot, options) {
      snapshot.modelName = (0, _emberInflector.pluralize)(Ember.String.underscore(snapshot.modelName));
      return this._super(data, type, snapshot, options);
    }
  });
});