define('ceannate-web/serializers/thread', ['exports', 'ceannate-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      var updatePatientRelationshipLink = function updatePatientRelationshipLink(relationshipId, providerId, studentId) {
        var relationshipRec = payload.included.find(function (r) {
          return r.type === 'patient_relationships' && r.id === relationshipId;
        });
        relationshipRec.relationships = {
          provider: { data: { type: 'providers', id: providerId } },
          student: { data: { type: 'students', id: studentId } }
        };
      };

      payload.data.forEach(function (t) {
        if (t.relationships.provider.data && t.relationships.student.data && t.relationships.patient_relationship.data) {
          updatePatientRelationshipLink(t.relationships.patient_relationship.data.id, t.relationships.provider.data.id, t.relationships.student.data.id);
        }
      });

      return this._super.apply(this, arguments);
    }
  });
});