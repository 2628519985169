define('ceannate-web/router', ['exports', 'ceannate-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('web', { path: '/' }, function () {
      this.route('reset-password', { path: '/reset_password/:token' });
      this.route('reset-success', { path: '/reset-success' }, function () {
        this.route('student');
        this.route('provider');
      });

      this.route('provider', { path: '/' }, function () {
        this.route('login');
        this.route('eula');
        this.route('forgot-password');
        this.route('verify-email', { path: '/verify_email/:token' });

        this.route('register', function () {
          this.route('create-account');
          this.route('information');
          this.route('subscription');
          this.route('insurance');
          this.route('thanks');
          this.route('verify-account');
          this.route('credentials');
          this.route('practice');
        });
        this.route('registration');

        this.route('workspace', { path: '/' }, function () {
          this.route('chats', function () {
            this.route('index', { path: '/' });
            this.route('thread', { path: '/thread/:thread_id' });
          });

          this.route('signup-program', function () {
            this.route('signup-program');
          });

          this.route('settings', function () {
            this.route('payment-method', function () {
              this.route('update-stripe-code');
            });
            this.route('subscription');
            this.route('edit-profile');
            this.route('notifications');
            this.route('change-password');
            this.route('change-email-address');
            this.route('deactivate');
            this.route('previous-invoices');
            this.route('credentials');
            this.route('insurance');
            this.route('practice');
            this.route('signup-program');
          });

          this.route('clients', function () {
            this.route('index', { path: '/' });
            this.route('client', { path: '/:patient_relationship_id' }, function () {
              this.route('report');
            });
          });
          this.route('protips', function () {
            this.route('article', { path: '/article/:article_id' });
          });
        });
      });
      this.route('verify-email-success');
    });
    this.route('dev-playground');
    this.route('page-not-found', { path: '/*path' });
  });

  exports.default = Router;
});