define('ceannate-web/models/insurance-verification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    student: _emberData.default.belongsTo('student'),
    provider: _emberData.default.belongsTo('provider')
  });
});