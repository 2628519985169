define('ceannate-web/models/user', ['exports', 'ember-data', 'ceannate-web/utils/model-utils'], function (exports, _emberData, _modelUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userUuid: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    bio: _emberData.default.attr('string', { defaultValue: '' }),
    passwordConfirm: _emberData.default.attr('string'),
    initials: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    verified: _emberData.default.attr('boolean'),
    banned: _emberData.default.attr('boolean'),
    photoSrc: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    phone: _emberData.default.attr('string'),

    userSubscriptions: _emberData.default.hasMany('subscription'),
    threads: _emberData.default.hasMany('thread'),
    subscriptionType: _emberData.default.attr('string'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      if (this.get('firstName') && this.get('lastName')) {
        return this.get('firstName') + ' ' + this.get('lastName');
      } else if (this.get('firstName')) {
        return '' + this.get('firstName');
      } else if (this.get('lastName')) {
        return '' + this.get('lastName');
      } else {
        return '' + this.get('email');
      }
    }),

    dashNotatedPhone: (0, _modelUtils.dashNotatedPhone)('phone')
  });
});