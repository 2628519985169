define('ceannate-web/models/report', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    reason: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 1000
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    reason: _emberData.default.attr('string'),
    provider: _emberData.default.belongsTo('provider'),
    student: _emberData.default.belongsTo('student'),
    creator: _emberData.default.belongsTo('user')
  });
});