define('ceannate-web/web/provider/eula/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    checkboxes: null,

    session: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    eula: Ember.inject.service(),

    showModal: false,

    /**
     * @computed canSubEULA
     * @description This should check whether we want to allow submission of EULA
     * @return boolean
     *
     * This is a simple function to check if we can submit the EULA.
     */
    isEULASubmitDisabled: Ember.computed('checkboxes.{first,second,third,fourth}', function () {
      var cbs = this.get('checkboxes').getProperties('first', 'second', 'third', 'fourth');
      return !(cbs.first && cbs.second && cbs.third && cbs.fourth);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var checkboxObject = new Ember.Object({
        first: false,
        second: false,
        third: false,
        fourth: false
      });

      this.set('checkboxes', checkboxObject);
    },


    actions: {
      acceptedEula: function acceptedEula() {
        var _this = this;

        var signature = this.get('eula').accept(this.get('model.firstObject'));

        if (this.get('session.isAuthenticated')) {
          signature.then(function () {
            _this.get('eula').handleRedirect();
          });
        } else {
          this.transitionToRoute('web.provider.register.create-account');
        }
      }
    }
  });
});