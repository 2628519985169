define('ceannate-web/mirage/serializers/session', ['exports', 'ceannate-web/mirage/serializers/application', 'ceannate-web/mirage/utils'], function (exports, _application, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['provider'],

    serialize: function serialize() {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _application.default.prototype.serialize.apply(this, arguments);

      // Add metadata, sort parts of the response, etc.
      json.data.id = (0, _utils.getToken)();

      return json;
    }
  });
});