define('ceannate-web/mixins/signup-confirm-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        if ((this.controller.get('enrollProviderUniversityPrograms').length > 0 || this.controller.get('deEnrollProviderUniversityPrograms').length > 0) && !this.controller.get('isSaving')) {
          var confirmTransition = confirm('Are you sure you want to leave, your changes will not be saved.');
          if (!confirmTransition) {
            transition.abort();
          }
        }
      }
    }
  });
});