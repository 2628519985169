define('ceannate-web/web/provider/workspace/settings/practice/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    model: function model() {
      var localProvider = this.get('currentUser.provider');

      return Ember.RSVP.hash({
        provider: this.store.findRecord('provider', localProvider.get('id'), {
          include: 'practice.business_hours',
          reload: true
        })
      });
    },
    deactivate: function deactivate() {
      var provider = this.modelFor(this.routeName).provider;
      provider.get('practice').rollbackAttributes();
      provider.get('practice.businessHours').toArray().forEach(function (h) {
        return h.rollbackAttributes();
      });
    },
    setupController: function setupController(controller, model) {
      var isAcceptingClients = model.provider.get('isAcceptingClients');
      model.provider.set('practice.isAcceptingClients', isAcceptingClients);

      var shouldNotifySms = model.provider.get('shouldNotifySms');
      model.provider.set('practice.shouldNotifySms', shouldNotifySms);

      this._super(controller, model);
    }
  });
});