define('ceannate-web/components/provider/practice-input/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    blockName: 'c_practice-input',
    classNames: ['component__practice-input'],
    practice: null,
    provider: null,
    placeSelected: Ember.computed.bool('practice.location.latitude'),
    resourceMetadata: Ember.inject.service(),
    smsVerificationCode: null,
    smsVerificationCodeErrors: null,
    verificationMessage: null,
    shouldShowSMSFields: false,
    phoneNumberVerifiedMsg: Ember.computed('provider.{phoneNumber,phoneNumberVerifiedAt}', function () {
      if (this.get('provider.phoneNumber') && this.get('provider.phoneNumberVerifiedAt') && this.get('provider.shouldNotifySms')) {
        return 'Successfully verified phone number';
      } else if (this.get('provider.phoneNumber') && this.get('provider.phoneNumberVerifiedAt')) {
        return "Successfully verified phone number. But stopped any sms from Meta, please send 'UNSTOP' to 1(844)317-8335 to receive SMS from Meta. Data charges might apply.";
      } else {
        return 'Please input and verfiy your mobile number to receive instant sms notifications if any student tries to contact you.';
      }
    }),

    showVerificationPhone: Ember.computed('provider.{phoneNumber,phoneNumberVerifiedAt}', 'provider.validations.attrs.{phoneNumber}.isValid', function () {
      var hasChanged = false;
      if (this.get('provider') && this.get('provider').changedAttributes()) {
        hasChanged = Ember.isPresent(this.get('provider').changedAttributes().phoneNumber);
      }

      var phoneNumberChange = void 0;
      if (hasChanged) {
        var changedNumber = this.get('provider').changedAttributes().phoneNumber;
        if (changedNumber[0] === null) {
          hasChanged = true;
        } else {
          var phoneNumberOriginal = changedNumber[0].replace(/-/g, '');
          phoneNumberChange = changedNumber[1].replace(/-/g, '');
          hasChanged = phoneNumberChange !== phoneNumberOriginal;
        }
      }

      if (hasChanged && this.get('provider.validations.attrs.phoneNumber.isValid') && this.get('provider.phoneNumber') !== '' || !hasChanged && this.get('provider.phoneNumber') !== null && this.get('provider.validations.attrs.phoneNumber.isValid') && this.get('provider.phoneNumberVerifiedAt') === null) {
        return true;
      }
    }),

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$(function () {
        var practice = _this.get('practice');
        _this.$('.place-autocomplete--input').on('change keyup keydown input', function () {
          if (practice && practice.get('location')) {
            var value = _this.$('.place-autocomplete--input').val();
            if (value === '') {
              _this.send('clearPlaceSelected');
            }
          }
        });
        _this.$('#practice-tel').mask('000-000-0000');
      });
    },
    init: function init() {
      this.set('provider', this.get('currentUser.provider'));
      return this._super.apply(this, arguments);
    },


    focusedOutPracticeAddress: false,
    hasPracticeAddressContent: Ember.computed.notEmpty('practice.location.address'),
    showPracticeAddressValidations: Ember.computed.or('focusedOutPracticeAddress', 'hasPracticeAddressContent'),
    showPracticeAddressErrors: Ember.computed.and('showPracticeAddressValidations', 'practice.validations.attrs.location.address.isInvalid'),
    showPracticeLatErrors: Ember.computed.and('hasPracticeAddressContent', 'focusedOutPracticeAddress', 'practice.validations.attrs.location.latitude.isInvalid'),

    actions: {
      toggleAcceptingClients: function toggleAcceptingClients() {
        this.get('practice').toggleProperty('isAcceptingClients');
      },
      focusOutPracticeAddress: function focusOutPracticeAddress() {
        this.set('focusedOutPracticeAddress', true);
      },
      onPlaceChange: function onPlaceChange(place) {
        this.send('clearPlaceSelected');
        var practice = this.get('practice');
        if (place && place.geometry) {
          practice.set('location.latitude', place.geometry.location.lat());
          practice.set('location.longitude', place.geometry.location.lng());
          this.set('placeSelected', true);
        } else {
          this.set('placeSelected', false);
        }
      },
      clearPlaceSelected: function clearPlaceSelected() {
        var practice = this.get('practice');
        practice.set('location', Ember.Object.create());
        this.set('placeSelected', false);
      }
    }
  });
});