define('ceannate-web/models/provider-university-program', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    comments: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    enrollDate: _emberData.default.attr('string'),
    deEnrollDate: _emberData.default.attr('string'),
    provider: _emberData.default.belongsTo('provider'),
    universityProgram: _emberData.default.belongsTo('university-program'),
    program: _emberData.default.belongsTo('program'),
    university: _emberData.default.belongsTo('university')
  });
});