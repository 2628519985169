define('ceannate-web/mirage/serializers/password-reset', ['exports', 'ceannate-web/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    typeKeyForModel: function typeKeyForModel() {
      return 'password_resets';
    }
  });
});