define('ceannate-web/components/loading-spinner/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_loading-spinner',
    attributeBindings: ['data-test'],
    mods: ['size']
  });
});