define('ceannate-web/data-adapter', ['exports', '@ember-data/debug'], function (exports, _debug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _debug.default;
    }
  });
});