define('ceannate-web/adapters/voice-phone-number-verification', ['exports', 'ceannate-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'voice_phone_number_verifications';
    }
  });
});