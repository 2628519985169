define('ceannate-web/components/photo-cropper/component', ['exports', 'ember-cli-image-cropper/components/image-cropper'], function (exports, _imageCropper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _imageCropper.default.extend({
    classNames: ['component__photo-cropper'],

    original: null,
    cropper: null,

    aspectRatio: 1,
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    croppedAvatar: null,
    viewMode: 1,
    dragMode: 'move',
    modal: true,

    // eslint-disable-next-line ember-best-practices/no-observers
    observesOriginalChange: Ember.observer('original', function () {
      var original = this.get('original');

      if (this.$() && original) {
        var $cropperContainer = this.$(this.get('cropperContainer'));

        if (!$cropperContainer.data('cropper')) {
          this.initImageCropper();
        }

        $cropperContainer.cropper('replace', this.get('original'));
      }
    }),

    actions: {
      cropImage: function cropImage() {
        var container = this.$(this.get('cropperContainer')),
            croppedImage = container.cropper('getCroppedCanvas');
        this.get('cropper')(croppedImage.toDataURL());
      }
    }
  });
});