define('ceannate-web/models/student-free-session', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    noOfFreeSessions: _emberData.default.attr('string'),
    noOfUsedSessions: _emberData.default.attr('string'),
    student: _emberData.default.belongsTo('student'),
    program: _emberData.default.belongsTo('program'),
    university: _emberData.default.attr('university'),

    didLoad: function didLoad() {
      var self = this;

      self.reload();
    }
  });
});