define('ceannate-web/models/password', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    }), (0, _emberCpValidations.validator)('ds-error')],
    password: [(0, _emberCpValidations.validator)('length', {
      min: 8
    }), (0, _emberCpValidations.validator)('ds-error')],
    passwordConfirm: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: '',
      messageKey: 'form.password-no-match'
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    email: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirm: _emberData.default.attr('string')
  });
});