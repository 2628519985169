define('ceannate-web/models/tokbox-session', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    streamType: _emberData.default.attr('string'),
    thread: _emberData.default.belongsTo('thread'),
    creator: _emberData.default.belongsTo('user'),
    tokboxSessionId: _emberData.default.attr('string'),
    subscribers: _emberData.default.hasMany('user', { inverse: null }),
    tokboxMeta: _emberData.default.attr('object')
  });
});