define('ceannate-web/components/provider/practice-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    practice: null,

    focusedOutPaymentOptions: false,
    businessHours: null,

    showPaymentOptionsErrors: Ember.computed.and('focusedOutPaymentOptions', 'practice.validations.attrs.paymentOptions.isInvalid'),

    actions: {
      onFocusOutPaymentOptions: function onFocusOutPaymentOptions() {
        this.set('focusedOutPaymentOptions', true);
      }
    }
  });
});