define('ceannate-web/components/hours-of-operation/business-hour/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_business-hour',
    businessHour: Ember.computed('businessHours.[]', function () {
      if (this.get('businessHours')) {
        return this.get('businessHours').findBy('day', this.get('day'));
      }
    }),
    day: null,
    selectableTimes: null,
    timezones: null,

    isOpen: Ember.computed.bool('businessHour'),

    startDefault: '9AM',
    start: Ember.computed.or('businessHour.start', 'startDefault'),

    endDefault: '5PM',
    end: Ember.computed.or('businessHour.end', 'endDefault'),

    timezoneDefault: 'CST',
    localTimezone: Ember.computed.or('businessHour.timezone', 'timezoneDefault'),

    actions: {
      toggleOpen: function toggleOpen(isOpen) {
        if (isOpen) {
          this.get('createBusinessHour')(this.get('day'));
        } else {
          this.get('destroyBusinessHour')(this.get('businessHour'));
        }
      }
    }
  });
});