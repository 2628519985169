define('ceannate-web/components/step-header/step-badge/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    tagName: 'span',
    classNames: ['c_step-badge'],
    classNameBindings: ['active:c_step-badge--active', 'checked:c_step-badge--checked'],
    active: false,
    checked: false,
    steps: null,
    step: 0,
    currentStep: 0,

    click: function click() {
      var _this = this;

      if (this.currentStep >= this.step) {
        switch (this.step) {
          case 0:
            this.set('currentUser.provider.registrationStep', 'general_information');
            this.get('currentUser.provider').save().then(function () {
              _this.get('routing.router').transitionTo('web.provider.register.information');
            });
            break;
          case 1:
            this.get('routing.router').transitionTo('web.provider.register.credentials');
            break;
          case 2:
            this.get('routing.router').transitionTo('web.provider.register.insurance');
            break;
          case 3:
            this.get('routing.router').transitionTo('web.provider.register.practice');
            break;
          default:
            return;
        }
      }
    }
  });
});