define('ceannate-web/web/provider/workspace/protips/article/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('article', params.article_id);
    },
    setupController: function setupController(controller, model) {
      model.reload();
      this.controllerFor('web.provider.workspace.protips').set('selectedArticleId', model.get('id'));
      return this._super.apply(this, arguments);
    }
  });
});