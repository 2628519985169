define('ceannate-web/models/charge', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    billable_amount: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true
    })],
    summary: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('length', { max: 300 })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    type: _emberData.default.attr('string'),
    billable_amount: _emberData.default.attr('number'),
    summary: _emberData.default.attr('string'),
    counselingSessions: _emberData.default.hasMany('counseling-session'),
    target: _emberData.default.belongsTo('student'),
    creator: _emberData.default.belongsTo('provider'),
    createdAt: _emberData.default.attr('date'),
    failureMessage: _emberData.default.attr('string'),
    paymentSucceeded: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    providerClaimBillableAmount: _emberData.default.attr('string'),
    providerUniversityCharge: _emberData.default.belongsTo('provider-university-charge'),
    studentCharge: _emberData.default.belongsTo('student-charge'),
    completedSession: _emberData.default.attr('boolean'),
    providerUniversityProgram: _emberData.default.belongsTo('provider-university-program'),
    cardDetails: _emberData.default.attr('array'),
    amount: _emberData.default.attr('number')
  });
});