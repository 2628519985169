define('ceannate-web/mirage/resources/sessions', ['exports', 'ceannate-web/mirage/utils', 'ember-cli-mirage'], function (exports, _utils, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockSessions;


  var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTUzODgwNzAyfQ.bfzW_YA57fAt5klkI5d7GRDGytww11QeFy5V7S4483E';

  function mockSessions(server) {
    server.post('/sessions', function (schema, request) {
      return (0, _utils.authenticate)(schema, request);
    });
    server.get('/sessions/:id', function (_ref) {
      var providers = _ref.providers,
          sessions = _ref.sessions;

      var provider = providers.find(1);

      if (!provider) {
        return (0, _utils.token401Error)();
      }

      var session = sessions.create({ providerId: provider.id });

      return new _emberCliMirage.Response(200, { Authorization: 'Bearer ' + token }, session);
    });
    server.del('/sessions/:id', function () {
      if (!(0, _utils.isAuthenticated)()) return (0, _utils.token401Error)();
      return (0, _emberCliMirage.Response)(204, {}, {});
    });
  }
});