define('ceannate-web/mirage/resources/pusher', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockPusher;
  function mockPusher(server) {
    server.post('/broadcasting/auth', function () {
      return new _emberCliMirage.Response(201);
    });
  }
});