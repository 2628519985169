define('ceannate-web/components/consent-documents/license-selector/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_license-selector',
    store: Ember.inject.service(),
    states: Ember.computed('patientRelationship.provider.credentials.[]', function () {
      return this.get('patientRelationship.provider.credentials').map(function (credential) {
        return credential.get('licenseType.region');
      }).uniqBy('id');
    }),
    selectedState: null,
    disabled: false,

    credentialsByState: Ember.computed('patientRelationship.provider.credentials.[]', 'selectedState', function () {
      return this.get('patientRelationship.provider.credentials').filterBy('licenseType.region.id', this.get('selectedState.id'));
    }),
    selectedCredential: null,

    formInvalid: Ember.computed('selectedState', 'selectedCredential', function () {
      return !(this.get('selectedState') && this.get('selectedCredential'));
    }),

    actions: {
      onChangeState: function onChangeState(state) {
        this.set('selectedState', state);
        this.set('selectedCredential', null);
      }
    }
  });
});