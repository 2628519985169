define('ceannate-web/components/article-nav-item/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_article-nav-item',

    article: null,
    selectedId: null,
    viewArticleAction: null,

    actions: {
      viewArticle: function viewArticle(article) {
        this.get('viewArticleAction')(article);
      }
    }
  });
});