define('ceannate-web/web/provider/register/thanks/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    eula: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('eula.acceptedEula')) {
        this.transitionTo('web.provider.eula');
      }
    }
  });
});