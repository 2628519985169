define('ceannate-web/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    general: {
      'new-to-meta': 'New to meta?',
      'already-have-an-account': 'Already have an account?',
      'create-an-account': 'Create an Account',
      copyright: 'Copyright {{year}} by META',
      yes: 'Yes',
      no: 'No',
      confirm: 'Confirm',
      cancel: 'Cancel',
      close: 'Close',
      'your-account-is-deactivated': 'Your account is deactivated. To reactivate your account please contact Meta support at support@meta.app or call +1(833) 844-6382.',
      required: 'Required',
      optional: 'Optional',
      delete: 'delete',
      add: 'add',
      'are-you-sure': 'Are you sure?',
      terms: 'Terms of Use',
      'privacy-policy': 'Privacy Policy',
      'back-to-previous-step': 'Back to Previous Step',
      'documents-sent-to-email': 'Documents will be sent to your email.',
      'download-documents': 'Download Documents',
      'download-my-signed-documents': 'Download My Signed Documents',
      'documents-have-been-sent-to-email': 'The documents have been sent to your email.'
    },
    'sign-in': {
      'sign-in': 'Sign In',
      'start-session': 'to start your session',
      'forgot-password': 'Forgot Password?',
      'inactivity-message': 'You have been logged out due to inactivity',
      error: 'Email or Password is incorrect.'
    },
    'sign-up': {
      'sign-in': 'Sign In',
      'basic-information': 'Basic Information',
      'step-1-of-3': 'Sign Up: Step 1 of 3'
    },
    'user-availability': {
      online: 'Online',
      offline: 'Offline'
    },
    register: {
      thanks: {
        heading: 'Thanks for <br> Completing Registration',
        description: 'We will review your credentials and respond within 3 business days. If you have any questions, please reach out to '
      },
      steps: {
        intro: 'Intro Information',
        credentials: 'Credentials &amp; Practice Forms',
        insurance: 'Insurance &amp; ID',
        practice: 'Practice Information'
      },
      overview: {
        'provider-profile': 'Provider Profile',
        introTitle: 'Your Introduction &ndash; This information is shared with the public.',
        introBody: 'Use a photo, video or short bio to introduce yourself.',
        credentialsTitle: 'Credentials & Client Forms – Some of this information is shared with public.',
        credentialsBody: 'Academic degrees, state licenses and HIPAA/Consent forms are all required. Only State and forms will be shared with public.',
        insuranceTitle: 'Insurance & Legal – Only Accepted Insurance will be shared with public',
        insuranceBody: 'Select insurance related to states you have licenses to practice. Legal Id is needed to verify a provider’s identity.  Including malpractice insurance and tax information is optional, but recommended.',
        practiceTitle: 'Practice Information – This informaiton is shared with public.',
        practiceBody: 'Clients will need to know practice name, location, payment options and hours of operations. We will need your tax id and business phone number verifiy your place of business. This information will remain private.'
      },
      information: {
        'title-required': 'Title is required and credentials are recommended. This information will display on your public profile.',
        'about-me-placeholder': 'Consider describing how you may assist a client, your core values, treatment approach, background, languages spoken, and if required by law list any other required information.',
        'phone-placeholder': 'Phone Format 1XXXXXXXXXX',
        'title-placeholder': 'Select One',
        'gender-placeholder': 'Select One',
        'credentials-suffix': 'Credentials / Suffix',
        'character-max': '7 character max',
        'gender-required': 'Gender is used to assist students in narrowing down their provider searches.'
      },
      insurance: {
        'accepted-header': 'Accepted Insurance',
        'accepted-subtitle': 'Please choose the insurance you accept. This will display on your public profile and potential clients can filter by accepted insurance.',
        'accepted-btn': 'Add Insurance',
        modal: {
          header: 'Add Insurance Selections',
          'search-label': 'Insurance Search',
          subtitle: 'Select insurance based on states you are licensed to practice.',
          'select-all': 'Select All',
          'select-btn': 'Select',
          'count-text': 'Selected'
        },
        'does-not-accept-label': 'I do not accept insurance.',
        'malpractice-header': 'Malpractice Insurance',
        'malpractice-subtitle': 'Please provide your Malpractice Insurance information. This information will not display on your public profile.',
        'malpractice-placeholder': 'Include the name of the insurance company, policy number, and expiration date.',
        'id-header': 'Legal Identification',
        'id-subtitle': 'As a part of the verification process, we need to review your government issued id. This information will not display on your public profile.',
        id: {
          'add-btn': 'Add ID',
          'change-btn': 'Change ID',
          'image-limits': 'Maximum size limitation 2 MB',
          'todo-header': "To-Do's",
          'image-types': 'Acceptable file types are .jpg, .jpeg and .png',
          todo1: 'Make sure your ID is photographed on a plain background.',
          todo2: "Don't cover the text or image with your fingers.",
          todo3: 'Ensure the text and image are clear and free of dirt.',
          todo4: 'Confirm no light reflections prevent the legibility of the text and image.',
          'forms-header': 'Accepted Forms of ID',
          form1: "Driver's License",
          form2: 'State ID',
          form3: 'Military ID',
          form4: 'Passport'
        }
      },
      credentials: {
        'academic-degrees': 'Academic Degrees',
        'state-licenses': 'State Licenses',
        'license-desc': 'At least one is required and will not be displayed on your public profile.',
        'license-desc-edit': 'If you need to update and or add a license please contact us at <a href="mailto:support@meta.app">support@meta.app</a> or call +1(833) 844-6382.',
        'state-licensed': 'Licensing State',
        'license-type': 'License Type',
        'file-error-title': 'File Type Not Recognized',
        'file-error-body': 'Please upload a pdf version of the document',
        change: 'Change',
        upload: 'Upload',
        hipaa: 'HIPAA',
        state: 'State'
      },
      practice: {
        finished: 'Finished',
        'payment-options': 'Payment Options',
        'payment-options-desc': 'Share information pertaining to your billing practices. This information will be displayed on your public profile.',
        'payment-options-placeholder': 'You must include a fee schedule. Consider also including: session length, sliding scale options, special offers, etc.',
        disclaimer: 'The practice phone number will not be a part of your public profile. This information will only be used by Meta support.',
        hours: 'Hours of Operation',
        'hours-desc': 'At least one day of operations for a practice must be provided.',
        'title-placeholder': 'E.g. Psychiatrist, Counselor',
        'credentials-suffix': 'Credentials/Suffix',
        'character-max': '7 character max',
        'is-accepting-clients': 'Accepting new clients',
        'is-not-accepting-clients': 'Currently not accepting new clients'
      },
      'help-prompt': 'Questions?',
      'help-contact': 'Contact us at'
    },
    form: {
      'counseling-session-time': 'Counseling Session Time',
      'charge-type': 'Charge Type',
      gender: 'Gender',
      select: 'Select',
      email: 'Email Address',
      'new-email': 'New Email Address',
      password: 'Password',
      'current-password': 'Current Password',
      'new-password': 'New Password',
      'confirm-new-password': 'Confirm New Password',
      'enter-password': 'Enter Password',
      're-enter-password': 'Re-enter Password',
      'confirm-password': 'Confirm Password',
      'password-input-placeholder': 'Minimum 8 characters',
      'password-confirm-input-placeholder': 'Re-enter Password',
      profession: 'Profession',
      next: 'Next',
      submit: 'Submit',
      'first-name': 'First Name',
      'last-name': 'Last Name',
      prefix: 'Prefix',
      suffix: 'Suffix',
      'are-you-a-doctor': 'Do you have a Dr. title?',
      'password-no-match': 'Must match Password',
      'about-you': 'About You',
      'about-me': 'About Me',
      phone: 'Phone',
      'about-you-label': 'Introduce yourself. (Optional)',
      'phone-label': 'Provide your phone number to be able to contacted by the student',
      'enter-text-here': 'Enter text here…',
      'profile-video': 'Profile Video',
      specializations: 'Specializations',
      education: 'Education',
      'medical-credentials': 'Medical Credentials',
      'attorney-credentials': 'Attorney Credentials',
      'insurance-plans': 'Insurance Plans Accepted',
      'insurance-plans-input-placeholder': 'Please select all that apply',
      'practice-info': 'Practice Info',
      'enter-text': 'Enter Text',
      'enter-name': 'Enter Name',
      address: 'Address',
      'address-instruction': 'Type and select your address',
      'address-error': 'Must select a result from the list',
      'practice-name': 'Practice Name',
      'practice-address': 'Practice Address',
      'credit-card-number-invalide': 'Card Number Invalid',
      'credit-card-expiration-invalid': 'Expiration Date Invalid',
      'credit-card-cvv-invalid': 'CVV Invalid',
      'credit-card-zip-invalid': 'Zip Code Invalid',
      done: 'Done',
      save: 'Save',
      'enter-title': 'Enter Title',
      'enter-title-error': 'Please enter your professional title',
      'enter-title-placeholder': 'E.g. Psychiatrist, Counselor',
      'enter-title-law-placeholder': 'E.g. Personal Injury Lawyer, Criminal, Patent',
      'must-selector-one-specialisation': 'Must choose at least 1 specialization',
      'must-selector-one-metadatas': 'Must choose at least 1 {{metadataType}}',
      optional: 'Optional',
      'something-went-wrong': 'Something went wrong.',
      'please-try-again-later': 'Please try again later.',
      title: 'Title',
      'hours-of-operation': 'Hours of Operation',
      'change-email-success': 'We have sent an email to {{email}} to verify your requested email change.',
      'avatar-max-size': 'Maximum size limitation 2 MB',
      'deactivate-account-text': 'Enter your account info below to deactivate your account. \nYou can reactivate at any time.',
      'profile-updated': 'Profile Updated',
      'practice-updated': 'Practice Updated',
      'change-email-updated': 'Email Updated',
      'verification-email-sent': 'Verification Email Sent!',
      'invalid-password': 'Invalid Password',
      'practice-phone': 'Practice Phone Number',
      'main-number': 'Main Number',
      faiths: 'Faiths',
      languages: 'Languages',
      'therapy-styles': 'Therapy styles',
      'must-selector-one-faiths': 'Must choose at least 1 faith',
      'must-selector-one-languages': 'Must choose at least 1 language',
      'must-selector-one-therapy-styles': 'Must choose at least 1 therapy styles',
      'mobile-phone-number': 'Mobile Phone Number',
      'opt-in-sms': 'Opt in for SMS notifications',
      'opt-out-sms': 'Opt out of SMS notifications',
      'phone-placeholder': 'xxx-xxx-xxxx',
      'send-invoice': 'Send Invoice',
      'create-charge': 'Create Charge',
      'sms-verificaiton-code': 'SMS Verification Code',
      'submit-code': 'Submit Code',
      'resend-code': 'Resend Verification Code',
      'verify-phone-number': 'Verify Phone Number',
      'counseling-session': 'Select Counseling Session'
    },
    welcome: {
      'welcome-to': 'Welcome to',
      meta: 'Meta Logo',
      'welcome-paragraph-one': 'Welcome back. Remember to set your presence indicator to “On,” and turn it off if you don’t want to be disturbed.',
      'welcome-paragraph-two': 'Congratulations on taking your practice to the next level. Please keep the following handy during the sign-up process: a hi-res photo, personal bio, practice and license information, and an optional video introduction.'
    },
    eula: {
      header: 'End-User License Agreement',
      subheader: '("Agreement")',
      accept: 'Accept',
      agree: 'Agree',
      'modal-title': 'EULA Signature Required',
      'modal-body': 'You must sign the latest End-User License Agreement to continue using the application.',
      'agree-privacy-policy': 'I have read and agree to the Privacy Policy',
      'agree-terms': 'I have read and agree to the Terms of Use',
      'agree-privacy-practices': 'I have read and agree to the Notice of Privacy Practices',
      'agree-mental': 'I have read and agree the Mental Wellness & Institution Subscription Agreements and Business Associate Agreement'
    },
    'forgot-password': {
      header: 'Forgot Your Password?',
      'expanded-text': 'Enter the email address you used when creating your Meta account and click Reset Password. An email will be sent containing a link to reset your password. If you don’t receive an email within a few minutes, be sure to check your spam folder.',
      'send-password-reset-link': 'Reset Password',
      success: "We've sent a password reset request, if an account is found with the email entered you will receive an email soon.",
      error: 'An unknown error has occured while trying to request a password reset. Please try again later.'
    },
    insurance: {
      'save-success-message': 'Insurance and Identity Updated'
    },
    'reset-password': {
      confirm: 'Confirm Reset',
      'new-password': 'New Password',
      'confirm-new-password': 'Confirm New Password',
      'reenter-new-password': 'Re-Enter New Password',
      header: 'Reset Password',
      'student-content': 'Please log back in to your meta app on your mobile device to continue.',
      'change-completed': 'Password Change Completed',
      'incorrect-password': 'Password Incorrect',
      success: 'Your password has been succesfully changed, please click the button below to go back to login.',
      'go-to-login': 'Go to Login'
    },
    professions: {
      'mental-health': 'Mental Health',
      lawyer: 'Lawyer',
      'mental-health-professional': 'Mental Health Professional',
      'lawyer-professional': 'Legal Professional'
    },
    'verify-account': {
      header: 'Verify Account',
      subheader: 'Check your email to verify account',
      'sent-link': 'We sent a verification link to <strong class="text-pale-teal">{{email}}</strong>',
      'email-sent': 'An email will be sent containing a link to verify your email address. If you don’t receive an email within a few minutes, be sure to check your spam folder.',
      'please-click': 'Need a new verification email? Click below to resend.',
      resend: 'Resend Verification Email'
    },
    'create-profile': {
      header: 'Create Your Profile',
      subheader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at dictum eros. Suspendisse eleifend massa ut sem feugiat, id feugiat justo laoreet. Nam convallis gravida porta.'
    },
    'verify-email': {
      'verified-heading': 'Email Address Verified',
      'verified-content': 'Thank you for verifying your email address. Please log back in to the meta app on your mobile device to continue registration.'
    },
    subscriptions: {
      'credit-card-info': 'Credit Card Info',
      'cc-number': 'Card Number',
      'cc-expiration': 'Expiration',
      'cc-cvv': 'CVV',
      'cc-zip': 'Billing Zip Code',
      'cc-promo': 'Promo Code (Optional)',
      'cc-promo-placeholder': 'Enter code here',
      'cc-promo-code-error': 'Promo Code Invalid',
      'silver-description': 'Minimum membership required to be promoted in student search results.',
      'gold-description': 'Promoted above silver members in student search results.',
      'silver-member': 'Early Adopter Monthly Membership Fee',
      'silver-monthly': 'Silver Monthly',
      'gold-member': 'Gold Member',
      'early-member': 'Early Adopter Monthly Membership Fee',
      'gold-monthly': 'Gold Monthly',
      'silver-price': '$79/Month',
      'early-price': '$59.99/month',
      'gold-price': '$100/Month',
      'page-title': 'Subscribe',
      'page-description': 'Receive your introductory period for free and lock in this rate now for the next two years.  As an early adopter, you will also receive discounted rates and/or offers on all forthcoming premium features or products.',
      'card-description': 'This payment method will be used for monthly membership charges.',
      'select-subscription': 'Select',
      'selected-subscription': 'Selected',
      'change-subscription-confirmation': 'Are you sure you want to change your subscription?',
      'subscription-changed': 'Subscription Changed'
    },
    'payment-method': {
      updated: 'Payment Method Updated',
      error: 'Payment Method could not be updated'
    },
    card: {
      'credit-card-info': 'Card Info',
      'replace-card': 'Replace Card'
    },
    chats: {
      'welcome-to-meta': 'Welcome to meta!',
      'you-dont-have-any-chats': "You don't have any chats at this time. ",
      'select-a-chat': 'Select a Chat',
      'your-chats-are-available-to-the-left-select-one': 'Your chats are available to the left. Select one to continue chatting.',
      'new-message': 'New Message',
      'you-have-unread-chats': {
        one: 'You have unread chats from 1 student',
        other: 'You have unread chats from {{count}} students'
      },
      'typing-box-placeholder': 'Type your message here...',
      'send-message': 'Send message',
      'chat-removed': 'Chat Removed',
      'remove-chat-confirmation': 'Are You Sure You Want Remove this Chat?',
      'remove-chat': 'Remove',
      connected: 'Connected',
      'outgoing-audio-call': 'Outgoing Phone Call...',
      'incoming-audio-call': 'Incoming Phone Call...',
      'outgoing-video-call': 'Outgoing Video Call...',
      'incoming-video-call': 'Incoming Video Call...',
      'feedback-question': 'How was the connection quality?',
      'student-unavailable': 'Student Unavailable',
      'provider-blocked-by-student': 'The student is no longer in our system, contact support@meta.app if you have any questions.'
    },
    clients: {
      warning: {
        message: 'Warning:  Before sending HIPAA Document and Informed Consent, please ensure the user has provided <br>1) Government Issued ID 2) Contact Authorization 3) Emergency Contact Information and 4) Credit Card Information if session is paid for by the student. <br>In the case of emergency, after notifying appropriate authorities, please notify University staff.  Click on University Name for further information.',
        'no-identification-id': 'No Legal Identification',
        'no-emergency-contact': 'No Emergency Contact',
        'no-contact-authorization': 'No Contact Authorization'
      },
      header: 'Client Management',
      headers: {
        'contact-information': 'Contact Information',
        'legal-identification': 'Legal Identification',
        'consent-documents': 'Consent Documents',
        insurance: 'Insurance',
        'recent-invoices': 'Recent Charges'
      },
      'welcome-title': 'Welcome to Client Management',
      'welcome-desc': 'After a client contacts you, they will appear here and you can learn more about them.',
      invoice: {
        'charge-cta': 'Create Charge',
        'section-title': 'New Invoice',
        title: 'Create Charge',
        desc: 'Provide charge information to the client. Ensure you include any legally required charge disclosure information.',
        amount: 'Charge Amount',
        'claim-amount': 'Claim Amount',
        intro: 'The following charge will automatically be billed to the client based on insurance and/or payment options.',
        'error-title': 'Client Relationship Required',
        'error-desc': 'You must send the consent documents to the student to initiate a relationship, and the student must sign the documents prior to invoicing.',
        'textarea-label': 'Charge Description (Required)',
        'university-billable-amount-label': 'University Per Session Amount',
        'university-billable-amount-percentage-label': 'University Pays Session Cost Percentage',
        'minimum-charge': 'Minimum Charge',
        'session-not-found': 'Session Not Found'
      },
      invitation: 'Invitation Sent',
      'revoke-relationship': 'Revoke Relationship',
      'relationship-warning': 'You are terminating your provider relationship with this client. By terminating you are confirming that you have fulfilled your legal obligations.',
      'section-headers': {
        'personal-contact-information': 'Personal Contact',
        'emergency-contact-information': 'Emergency Contact'
      },
      blocked: 'Blocked',
      permissions: {
        phone: 'Phone',
        email: 'Email',
        text: 'Text',
        voicemail: 'Voicemail'
      },
      'list-items': {
        name: 'Name',
        email: 'Email',
        relationship: 'Relationship',
        'phone-number': 'Phone Number',
        'date-of-birth': 'Date of Birth',
        address: 'Address',
        university: 'University',
        'university-student-id': 'University Student Id',
        'university-no-free-sessions': 'Available Free Sessions',
        'university-program': 'University program student eligible',
        'university-no-free-sessions-remaining': 'Free Sessions Remaining',
        'university-total-sessions': 'Total Sessions',
        'contact-permissions': 'Contact Authorization',
        'eligibility-criteria-with-free-sessions': 'Student eligible for {{studentFreeSession.noOfFreeSessions}} free sessions covered by their institution. {{remainingSession}} sessions remaining.',
        'no-university-student-id': 'Student might not be eligible for Institution paid sessions. Please advise student to update Student ID in App Settings.',
        'eligibility-criteria-no-provider-signup': 'Institution will cover session fees for this student. Participate in their <a href="/settings/signup-program">institution\'s program</a> to ensure session coverage.',
        'no-university-student-id-or-provider-signup': 'Student might not be eligible for Institution paid sessions. Please advise student to update Student ID in App Settings. You have not signed up for program.',
        'student-did-not-provide-consent': 'Student must accept in-App consent to receive paid sessions by their institution.',
        'no-student-consent-or-provider-signup': 'No Student consent or provider signed up for program.'
      },
      insurance: {
        'student-insurance': 'Student Insurance',
        pop: {
          subtitle: 'No Insurance',
          body: "I've verified cost and payment preferences with the client."
        },
        'no-npi': {
          subtitle: 'No NPI number on file',
          body: "Must have a NPI number to see the client's insurance information."
        },
        manual: 'Manual Verification Required',
        verified: 'Verified On',
        'view-extended': 'To view details you can expand each section.'
      }
    },
    'account-settings': {
      'previous-invoices': {
        'payment-date': 'Payment Date',
        'invoice-amount': 'Invoice Amount',
        'subscription-tier': 'Subscription Tier',
        'payment-method': 'Payment Method'
      },
      payment: {
        'update-stripe': 'Join or update your Stripe Connect account to invoice and receive payments from your clients.',
        'go-to-stripe': 'Go to Stripe'
      }
    },
    'report-user': {
      'report-user': 'Report User',
      'reason-placeholder': 'This information is available to Meta support staff. Do not enter confidential information or share information that violates client-provider privilege.',
      'report-warning': 'This action will block all communications between yourself and this user. If this user is currently a client, you must consider your legal and ethical obligations before terminating this relationship.',
      'report-success': 'The student has been reported to META. We will review your comment and reach out for more information if necessary.'
    },
    modals: {
      verified: {
        'email-address-verified': 'Email Address Verified',
        'verified-text': 'Your email has been verified, you can now complete registration.'
      },
      dismiss: 'Dismiss'
    },

    components: {
      'avatar-upload': {
        'profile-photo': 'Profile Photo',
        'upload-profile-photo': 'Upload Profile Photo',
        'avatar-files': 'Acceptable file formats: .png, .jpg, .jpeg'
      },
      chat: {
        'incoming-chats': {
          'incoming-chats': 'Incoming Chats'
        },
        'name-bar': {
          'report-link': 'Report User',
          'remove-chat-link': 'Remove Chat'
        }
      },
      'top-nav': {
        chats: 'Chats',
        clients: 'Clients',
        'account-settings': 'Account Settings',
        'pro-tips': 'Pro Tips',
        logout: 'Logout'
      },
      'education-input': {
        'university-name-label': 'University',
        'university-name-placeholder': 'Enter Name',
        'degree-name-label': 'Degree',
        'degree-name-placeholder': 'Enter Degree',
        'year-name-label': 'Grad Year',
        'year-name-placeholder': 'Year'
      },
      'video-upload': {
        'choose-file': 'Choose File',
        'time-limit': 'Upload a 30 second video about yourself.',
        website: 'See our website for tips on creating your video.',
        limitations: 'Your video file must not be larger than 80 MB.'
      },
      'photo-cropper': {
        'choose-profile-picture': 'Choose Profile Picture'
      },
      'hours-of-operation': {
        opened: 'Open',
        closed: 'Closed',
        'empty-error': 'Must be open at least one day a week'
      },
      'provider-signup-program': {
        enroll: 'Enroll',
        'de-enroll': 'De-enroll',
        'comment-placeholder': 'Add your comments'
      },
      'license-credentials': {
        'npi-number': 'NPI Number - will not display on public profile',
        'npi-placeholder': 'NPI Number (Active License Only)',
        'licenses-desc': 'You will need to provide at least one active State License. State, License type, and License number(s) will display on public profile. Please upload a PDF for the HIPAA and consent forms.',
        'medical-license': 'Provider License Number',
        'bar-license': 'Bar License',
        'enter-license-number': 'Enter License Number',
        'active-license-only': 'Active License Only',
        'license-number': 'License Number',
        'expiration-date': 'Expiration Date'
      },
      'settings-side-nav': {
        'edit-profile': 'Edit Profile',
        notifications: 'Notification Settings',
        'email-notification-description': 'Email notifications will be delivered 24/7/365.',
        'sms-voice-notification-description': 'SMS and Voice notifications will be delivered during 8am and 8pm Central. When a notification occurs after 8pm Central, you will receive the notification the following day at 8am.',
        'notifications-title': 'Where do you want to receive notifications?',
        'enter-phone-number': 'Enter Your Phone Number',
        'phone-number-accept-info': 'We will send you an activation code to confirm your notification setup.',
        'subscription-info': 'Subscription Info',
        'payment-method': 'Payment Method',
        'signup-program': 'Programs',
        'previous-invoices': 'Previous Invoices',
        'change-email': 'Change Email Address',
        'change-password': 'Change Password',
        'deactivate-account': 'Deactivate Account',
        subheader: 'To edit please reach out to <a href="mailto:support@meta.app">support@meta.app</a>',
        'credentials-forms': 'Credentials &amp; Practice Forms',
        insurance: 'Insurance &amp; Identity',
        practice: 'Practice Info'
      },
      'pro-tips': {
        'meta-pro-tips': 'Meta Pro-Tips',
        index: {
          header: 'Welcome to Pro Tips',
          desc: 'Come here to gain insights and learn more about how MetaPro can help you.'
        }
      }
    },
    errors: {
      description: 'This field',
      inclusion: '{{description}} is not included in the list',
      exclusion: '{{description}} is reserved',
      invalid: '{{description}} is invalid',
      confirmation: "{{description}} doesn't match {{on}}",
      accepted: '{{description}} must be accepted',
      empty: 'Required Field',
      blank: 'Required Field',
      present: '{{description}} must be blank',
      collection: '{{description}} must be a collection',
      singular: "{{description}} can't be a collection",
      tooLong: 'Must be under {{max}} characters',
      tooShort: 'Minimum {{min}} characters',
      before: '{{description}} must be before {{before}}',
      after: '{{description}} must be after {{after}}',
      wrongDateFormat: '{{description}} must be in the format of {{format}}',
      wrongLength: '{{description}} is the wrong length (should be {{is}} characters)',
      notANumber: '{{description}} must be a number',
      notAnInteger: '{{description}} must be an integer',
      greaterThan: '{{description}} must be greater than {{gt}}',
      greaterThanOrEqualTo: '{{description}} must be greater than or equal to {{gte}}',
      equalTo: '{{description}} must be equal to {{is}}',
      lessThan: '{{description}} must be less than {{lt}}',
      lessThanOrEqualTo: '{{description}} must be less than or equal to {{lte}}',
      otherThan: '{{description}} must be other than {{value}}',
      odd: '{{description}} must be odd',
      even: '{{description}} must be even',
      positive: '{{description}} must be positive',
      date: '{{description}} must be a valid date',
      onOrAfter: '{{description}} must be on or after {{onOrAfter}}',
      onOrBefore: '{{description}} must be on or before {{onOrBefore}}',
      email: 'Please enter a valid email address',
      phone: '{{description}} must be a valid phone number',
      url: '{{description}} must be a valid url'
    },
    'date-formats': {
      // formatting done with moment.js
      // http://momentjs.com/docs/#/displaying/
      'numeric-full-year': 'MM.DD.YYYY'
    }
  };
});