define('ceannate-web/adapters/credential', ['exports', 'ceannate-web/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    keyForRelationship: function keyForRelationship(rawKey) {
      return Ember.String.underscore(rawKey);
    }
  });
});