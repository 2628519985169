define('ceannate-web/mirage/resources/files', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockFiles;
  function mockFiles(server) {
    server.get('/files');
    server.get('/files/:id');
    server.post('/files', function (_ref) {
      var files = _ref.files;

      var attrs = this.normalizedRequestAttrs();

      // Include src attrs
      attrs.src = 'https://ora-bucket.s3.amazonaws.com/video/' + attrs.filename + '?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIT2MV63VJN6XIS7A%2F20180129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20180129T215335Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=b13f0da720a4aa926c515cb195dd95b53c0bffd1bcdbc0adc2b2c500aa09f2ba';

      return files.create(attrs);
    });
    server.put('/files/:id');
    server.patch('/files/:id');
    server.del('/files/:id');
  }
});