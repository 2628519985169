define('ceannate-web/models/credential', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    licenseNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 20
    }), (0, _emberCpValidations.validator)('ds-error')],
    expiresAt: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      onOrAfter: 'now',
      precision: 'month',
      message: 'Expiration date is invalid',
      format: 'MM/YY'
    }), (0, _emberCpValidations.validator)('ds-error')],
    licenseType: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('ds-error')],
    documents: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 2 })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    licenseNumber: _emberData.default.attr('string'),
    provider: _emberData.default.belongsTo('provider'),
    expiresAt: _emberData.default.attr('string'),

    licenseType: _emberData.default.belongsTo('license-type'),
    isValid: Ember.computed('validations.{isValid,isTruelyValid}', function () {
      return this.get('validations.isValid') || this.get('validations.isTruelyValid');
    }),
    documents: _emberData.default.hasMany('document', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});