define('ceannate-web/serializers/tokbox-session', ['exports', 'ceannate-web/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = {
        data: payload.data,
        included: payload.included || [],
        meta: payload.meta
      };

      newPayload['data']['attributes']['tokbox_meta'] = payload.meta;

      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });
});