define('ceannate-web/web/provider/workspace/clients/client/index/route', ['exports', 'ceannate-web/utils/thread-infinity-model-options', 'ceannate-web/utils/encrypt'], function (exports, _threadInfinityModelOptions, _encrypt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model: function model() {
      var relationshipId = (0, _encrypt.decode)(this.paramsFor('web.provider.workspace.clients.client').patient_relationship_id);
      var providerId = this.store.peekRecord('patient-relationship', relationshipId).get('provider').id;
      var studentId = this.store.peekRecord('patient-relationship', relationshipId).get('student').id;
      var universityId = this.store.peekRecord('patient-relationship', relationshipId).get('student.university.id');

      return Ember.RSVP.hash({
        patientRelationship: this.modelFor('web.provider.workspace.clients.client').patientRelationship,
        threadList: this.get('infinity').model('thread', _threadInfinityModelOptions.default),
        block: this.modelFor('web.provider.workspace.clients').blocks.findBy('student', this.store.peekRecord('patient-relationship', relationshipId).get('student')),
        types: this.store.query('charge-type', {}),
        studentFreeSession: this.get('store').query('student-free-session', {
          filter_student: studentId,
          filter_university: universityId,
          'filter[active]': 1,
          include: 'program',
          reload: true
        }),
        universityProgram: this.get('store').query('university_program', {
          'filter[university_id]': universityId,
          'filter[active]': 1,
          filter_highest_priority: 1,
          include: 'university,program.program_type'
        }).then(function (result) {
          return result.objectAt(0);
        }),
        providerUniversityPrograms: this.get('store').query('provider-university-program', {
          'filter[provider_id]': providerId,
          filter_university: universityId,
          filter_student: studentId,
          'filter[active]': 1,
          include: 'provider,university_program.program.program_type,university_program.payment_options.charge_type,university_program.payment_options.license_type,university,program.program_type'
        }).then(function (result) {
          return result.objectAt(0);
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('types', models.types);
      controller.set('counselingSessions', models.counselingSessions);
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var scrollable = document.getElementsByClassName('scrollable');
        if (scrollable.length) {
          scrollable[0].scrollTop = 0;
        }
      },
      refreshModel: function refreshModel() {
        var relationshipId = (0, _encrypt.decode)(this.paramsFor('web.provider.workspace.clients.client').patient_relationship_id);
        var studentFreeSessiontmp = this.get('store').peekRecord('student-free-session', {
          filter_student: this.store.peekRecord('patient-relationship', relationshipId).get('student').id,
          filter_university: this.store.peekRecord('patient-relationship', relationshipId).get('student.university.id'),
          'filter[active]': 1,
          include: 'program',
          reload: true
        });
        this.set('model.studentFreeSession', studentFreeSessiontmp);
        this.refresh();
        window.location.reload();
      }
    }
  });
});