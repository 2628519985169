define('ceannate-web/components/client-name-bar/button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['c_client-name-bar-button'],
    attributeBindings: ['data-toggle', 'aria-haspopup', 'aria-expanded'],

    icon: null
  });
});