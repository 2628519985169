define('ceannate-web/mirage/factories/provider', ['exports', 'ceannate-web/mirage/factories/user', 'ember-cli-mirage'], function (exports, _user, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend({
    title: function title() {
      return _emberCliMirage.faker.lorem.word();
    },
    bio: function bio() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    afterCreate: function afterCreate(provider, server) {
      server.createList('degree', 2, {
        provider: provider,
        degree: _emberCliMirage.faker.lorem.word(),
        universityName: _emberCliMirage.faker.lorem.word(),
        graduationYear: 1990
      });

      server.create('credential', {
        provider: provider,
        licenseNumber: 'PZ108AFJ',
        region: 'IL'
      });

      server.create('practice', {
        provider: provider,
        name: _emberCliMirage.faker.lorem.word(),
        location: {
          latitude: 81.123,
          longitude: -42.123,
          address: '5419 West Schubert, Chicago IL 60643'
        }
      });

      var specialties = server.schema.specialties.all();
      provider.update({ specialties: specialties });
    }
  });
});