define('ceannate-web/components/thread-channel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var threadChannelName = function threadChannelName(threadId) {
    return 'presence-threads-' + threadId;
  };

  exports.default = Ember.Component.extend({
    tagName: '',

    currentUser: Ember.inject.service(),
    websocketService: Ember.inject.service(),

    publicAPI: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.subscribe();

      this.updateState({
        subscriptionSucceeded: false,
        actions: {
          sendTypingSignal: function sendTypingSignal() {
            return _this.sendTypingSignal.apply(_this, arguments);
          }
        }
      });
    },
    updateState: function updateState(changes) {
      this.set('publicAPI', Object.assign({}, this.get('publicAPI'), changes));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _Ember$getProperties = Ember.getProperties(this, 'activeChannelThreadId', 'threadId'),
          activeChannelThreadId = _Ember$getProperties.activeChannelThreadId,
          threadId = _Ember$getProperties.threadId;

      if (activeChannelThreadId && activeChannelThreadId !== threadId) {
        this.unsubscribe();
      }

      this.subscribe();
    },
    willDestroyElement: function willDestroyElement() {
      this.unsubscribe();
    },
    subscribe: function subscribe() {
      var _this2 = this;

      var threadId = this.get('threadId');

      if (threadId) {
        this.set('activeChannelThreadId', threadId);

        var channel = this.get('websocketService').subscribe(threadChannelName(threadId));

        channel.bind('message.created', function (data) {
          Ember.run(_this2, 'onMessageCreated', data);
        });
        channel.bind('client-user.typed', function (data) {
          Ember.run(_this2, 'onUserTyped', data);
        });
        channel.bind('pusher:subscription_succeeded', function () {
          Ember.run(function () {
            return _this2.updateState({ subscriptionSucceeded: true });
          });
        });

        this.set('channel', channel);
      }
    },
    unsubscribe: function unsubscribe() {
      var threadId = this.get('activeChannelThreadId');
      this.set('channel', null);
      this.get('websocketService').unsubscribe(threadChannelName(threadId));
    },
    onUserTyped: function onUserTyped(_ref) {
      var id = _ref.id,
          typing = _ref.meta.typing;

      if (id !== this.get('currentUser.provider.id')) {
        this.updateState({ otherUserIsTyping: typing });
      }
    },
    sendTypingSignal: function sendTypingSignal(isTyping) {
      this.get('channel').trigger('client-user.typed', {
        type: 'users',
        id: this.get('currentUser.provider.id'),
        meta: {
          typing: isTyping
        }
      });
    }
  });
});