define('ceannate-web/components/thread-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    blocks: null,
    thread: null,
    relationship: Ember.computed('thread.provider.patientRelationships.@each.{status}', 'thread.student', function () {
      return this.get('thread.provider.patientRelationships').filterBy('student', this.get('thread.student'));
    })
  });
});