define('ceannate-web/helpers/is-checked', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isChecked = isChecked;
  function isChecked(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(isChecked);
});