define('ceannate-web/components/provider/faith-selector/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    classNames: ['component__faith-selection'],
    blockName: 'c_faith-selection',

    faiths: null,
    user: null,
    hasUserInteracted: false,
    hasFaithsSelected: false,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.set('hasFaithsSelected', this.get('providerFaiths.length') > 0);
    },

    actions: {
      selectFaith: function selectFaith(faith) {
        this.set('hasUserInteracted', true);
        var faiths = this.get('providerFaiths').indexOf(faith);
        if (faiths > -1) {
          this.get('providerFaiths').removeObject(faith);
        } else {
          this.get('providerFaiths').addObject(faith);
        }
        this.set('hasFaithsSelected', this.get('providerFaiths.length') > 0);

        if (this.get('onChangeFaiths')) {
          this.get('onChangeFaiths')();
        }
      }
    }
  });
});