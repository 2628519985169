define('ceannate-web/mirage/factories/practice', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    /*afterCreate(practice, server) {
      // Create Hours by Default...
      let timezone = 'CST';
      
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'monday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'tuesday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'wednesday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'thursday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'friday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'saturday',
        timezone
      });
      server.create('businessHours', {
        practice,
        start: '9 AM',
        end: '2 PM',
        day: 'sunday',
        timezone
      });
    }*/
  });
});