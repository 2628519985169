define('ceannate-web/services/toastr', ['exports', 'ember-core-components-addon/services/toastr'], function (exports, _toastr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _toastr.default;
    }
  });
});