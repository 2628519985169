define('ceannate-web/components/meta-page-scroll-layout/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    loginView: false,
    registerView: false,
    createAccountView: false
  });
});