define('ceannate-web/helpers/remove-whitespace', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeWhitespace = removeWhitespace;
  function removeWhitespace(params /*, hash*/) {
    return params[0].replace(/\s/g, '');
  }

  exports.default = Ember.Helper.helper(removeWhitespace);
});