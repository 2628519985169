define('ceannate-web/components/meta-settings-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['component__meta-settings-header'],
    tagName: 'header'
  }).reopenClass({
    positionalParams: ['label']
  });
});