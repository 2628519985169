define('ceannate-web/mirage/resources/blocks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockSpecialtyCategories;
  function mockSpecialtyCategories(server) {
    server.get('/blocks');
    server.get('/blocks/:id');
    server.post('/blocks');
    server.put('/blocks/:id');
    server.patch('/blocks/:id');
    server.del('/blocks/:id');
  }
});