define('ceannate-web/serializers/patient-relationship', ['exports', 'ceannate-web/serializers/application', 'ceannate-web/config/environment'], function (exports, _application, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      payload.data.forEach(function (relationship) {
        if (relationship.relationships && relationship.relationships.provider) {
          var providerId = relationship.relationships.provider.data.id;
          var studentId = relationship.relationships.student.data.id;
          /*relationship.relationships.studentCharges = {
            links: {
              related: `${ENV.apiUrl}/${
                ENV.apiNS
              }/providers/${providerId}/relationships/student_charges/?filter_student_id=${studentId}&limit=3&include=creator,target`
            }
          };*/
          relationship.relationships.charges = {
            links: {
              related: _environment.default.apiUrl + '/' + _environment.default.apiNS + '/providers/' + providerId + '/relationships/charges/?filter_student_id=' + studentId + '&page[size]=3&page[number]=1&include=creator,target,counseling_sessions,provider_university_charge.university,provider_university_charge.program,student_charge'
            }
          };
        }
      });

      return this._super.apply(this, arguments);
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload) {
      var normalizedResponse = this._super.apply(this, arguments);
      if (payload.meta && payload.meta.created_resources) {
        normalizedResponse.data.attributes.replacementRelationshipId = parseInt(payload.meta.created_resources[0].data.id, 10);
      }
      return normalizedResponse;
    }
  });
});