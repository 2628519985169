define('ceannate-web/components/meta-settings-panel/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_meta-settings-panel',
    toastVisible: null,
    toastVariant: null,
    toastMessage: null,
    toastAutoHide: true,
    toastPrimaryAction: null,
    toastPrimaryActionLabel: null,
    toastSecondaryAction: null,
    toastSecondaryActionLabel: null,
    toastAbsolute: true,
    headerText: null
  });
});