define('ceannate-web/web/provider/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    eula: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    infinity: Ember.inject.service(),

    actions: {
      error: function error(_error) {
        if (_error.errors && _error.errors.isAny('code', '10001')) {
          this.get('infinity')._cachedCollection = {}; // blow away infinity model cacheing, so that models reload
          this.controllerFor('web.provider.eula').set('showModal', true);
          this.get('eula').assignRedirect(this.get('routing.router').currentPath);
        } else {
          return true;
        }
      },


      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        gtag('send', 'pageview', {
          page: this.router.currentURL,
          title: this.router.currentRouteName
        });
      }
    }
  });
});