define('ceannate-web/web/provider/register/verify-account/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller) {
      controller.set('email', this.get('currentUser.user.email'));
    }
  });
});