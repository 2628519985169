define('ceannate-web/mirage/resources/professions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockProfessions;
  function mockProfessions(server) {
    server.get('/professions');
    server.get('/professions/:id');
    server.post('/professions');
    server.put('/professions/:id');
    server.patch('/professions/:id');
    server.del('/professions/:id');
  }
});