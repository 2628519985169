define('ceannate-web/web/provider/workspace/settings/practice/controller', ['exports', 'ceannate-web/web/provider/register/practice/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    store: Ember.inject.service(),

    toastVisible: false,

    provider: Ember.computed.readOnly('model.provider'),
    practice: Ember.computed.readOnly('model.provider.practice'),

    hasChanged: Ember.computed('practice.{name,location,phone,paymentOptions,isAcceptingClients,shouldNotifySms}', 'businessHours.@each.{start,end,timezone}', 'provider.{phoneNumber}', function () {
      return this.get('practice.hasDirtyAttributes') || this.get('provider.hasDirtyAttributes') || this.get('businessHours') && this.get('businessHours').any(function (businessHour) {
        return businessHour.get('hasDirtyAttributes');
      }) || this.get('toDestroyBusinessHours.length');
    }),

    formInvalid: Ember.computed('practice.{name,location,phone,paymentOptions,isAcceptingClients}', 'businessHours.@each.{start,end,timezone}', 'provider.validations.attrs.phoneNumber.{isValid}', function () {
      return !(this.get('hasChanged') && this.get('practice.name') && this.get('practice.location.address') && this.get('practice.validations.attrs.phone.isValid') && this.get('practice.validations.attrs.paymentOptions.isValid') && this.get('provider.phoneNumber') && this.get('provider.validations.attrs.phoneNumber.isValid'));
    }),

    persistBusinessHours: function persistBusinessHours() {
      var _this = this;

      return Ember.RSVP.hash({
        save: Ember.RSVP.all(this.get('businessHours').filterBy('hasDirtyAttributes').map(function (businessHour) {
          if (businessHour.get('isNew')) {
            businessHour.set('practice', _this.get('practice'));
          }
          return businessHour.save();
        })),
        destroy: Ember.RSVP.all(this.get('toDestroyBusinessHours').map(function (businessHour) {
          _this.get('toDestroyBusinessHours').removeObject(businessHour);
          return businessHour.destroyRecord();
        }))
      });
    },


    actions: {
      save: function save() {
        var _this2 = this;

        var oldphone = this.get('provider.phoneNumber');
        this.set('provider.isAcceptingClients', this.get('practice.isAcceptingClients'));

        var shouldNotifySms = this.get('practice.shouldNotifySms');
        this.set('provider.shouldNotifySms', shouldNotifySms);

        if (this.get('practice.provider.phoneNumber') && this.get('practice.provider.phoneNumber').length) {
          this.set('provider.phoneNumber', this.get('practice.provider.phoneNumber').replace(/-/g, ''));
        } else {
          // this.set('provider.phoneNumber', null);
          this.set('practice.shouldNotifySms', false);
          this.set('provider.shouldNotifySms', false);
        }

        return Ember.RSVP.hash({
          practice: this.get('practice').save(),
          businessHours: this.persistBusinessHours(),
          provider: this.get('provider').save()
        }).then(function () {
          _this2.set('toastVisible', true);
          _this2.set('provider.phoneNumber', oldphone);
        }).catch(function () {
          //eslint-disable-next-line ember-best-practices/no-global-jquery
          Ember.$('.c_meta-settings-panel__content').animate({ scrollTop: 0 });
          _this2.set('provider.phoneNumber', oldphone);
        });
      },
      onDestroyBusinessHour: function onDestroyBusinessHour(businessHour) {
        this.get('toDestroyBusinessHours').pushObject(businessHour);
        this.get('businessHours').removeObject(businessHour);
      },
      onCreateBusinessHour: function onCreateBusinessHour(day) {
        var businessHour = this.get('store').createRecord('business-hour', {
          day: day,
          start: '9AM',
          end: '5PM',
          timezone: 'CST'
        });
        this.get('businessHours').pushObject(businessHour);
        this.get('toDestroyBusinessHours').removeObject(businessHour);
      }
    }
  });
});