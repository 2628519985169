define('ceannate-web/serializers/metadata-sub-type', ['exports', 'ceannate-web/serializers/application', 'ember-inflector'], function (exports, _application, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.underscore(key);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    },
    payloadTypeFromModelName: function payloadTypeFromModelName(modelName) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    }
  });
});