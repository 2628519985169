define('ceannate-web/models/provider', ['exports', 'ember-data', 'ceannate-web/models/user', 'ember-cp-validations', 'ceannate-web/utils/model-utils', 'ceannate-web/config/environment'], function (exports, _emberData, _user, _emberCpValidations, _modelUtils, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var suffixValidation = [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { allowBlank: true, max: 7 }), (0, _emberCpValidations.validator)('format', {
    regex: /^[A-Za-z]+$/,
    message: 'Alphabetic characters only',
    allowBlank: true
  })];

  var phoneValidation = [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { allowBlank: false, max: 11 }), (0, _emberCpValidations.validator)('format', {
    regex: /^[0-9]+$/,
    message: 'Numbers only',
    allowBlank: false
  })];

  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'form.enter-title-error'
    }), (0, _emberCpValidations.validator)('ds-error')],
    bio: [(0, _emberCpValidations.validator)('length', { max: 1000 }), (0, _emberCpValidations.validator)('ds-error')],
    malpracticeInsuranceInformation: [(0, _emberCpValidations.validator)('length', { max: 500 }), (0, _emberCpValidations.validator)('ds-error')],
    photoSrc: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)],
    identificationSrc: [(0, _emberCpValidations.validator)('ds-error')],
    videoSrc: [(0, _emberCpValidations.validator)('ds-error')],
    npiNumber: [(0, _emberCpValidations.validator)('length', { allowBlank: true, max: 10 }), (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      integer: true,
      positive: true
    })],
    suffixOne: suffixValidation,
    suffixTwo: suffixValidation,
    suffixThree: suffixValidation,
    suffixFour: suffixValidation,
    suffixFive: suffixValidation,
    gender: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'form.enter-title-error'
    }), (0, _emberCpValidations.validator)('ds-error')],
    phone: phoneValidation,
    phoneNumber: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone'
    })],
    voicePhoneNumber: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone'
    })]
  });

  exports.default = _user.default.extend(Validations, {
    user: _emberData.default.belongsTo('user'),
    approved: _emberData.default.attr('boolean'),
    prefix: _emberData.default.attr('string'),
    profession: _emberData.default.attr('string'),
    isOnline: _emberData.default.attr('boolean', { defaultValue: false }),
    isAcceptingClients: _emberData.default.attr('boolean', { defaultValue: true }),
    registrationStep: _emberData.default.attr('string'),
    videoSrc: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    faiths: _emberData.default.hasMany('faith'),
    languages: _emberData.default.hasMany('language'),
    therapyStyles: _emberData.default.hasMany('therapy-style'),
    gender: _emberData.default.belongsTo('gender'),
    metadatas: _emberData.default.hasMany('metadatas'),

    // Compile multiple fields into a final full name
    fullName: Ember.computed('firstName', 'lastName', 'suffix', 'prefix', function () {
      var nameObject = Object.values(this.getProperties('firstName', 'lastName', 'suffix'));

      if (this.get('prefix')) {
        nameObject.unshift(this.get('prefix'));
      }

      return nameObject.join(' ');
    }),

    professionDisplay: Ember.computed('profession', function () {
      var profession = this.get('profession');
      if (profession === 'Mental Health') {
        return 'Mental Health Professional';
      }
      return profession;
    }),

    specialties: _emberData.default.hasMany('specialty'),
    insuranceCompanies: _emberData.default.hasMany('insurance-company', { async: false }),
    patientRelationships: _emberData.default.hasMany('patient-relationship', { async: false }),
    practice: _emberData.default.belongsTo('practice', { async: false }),
    degrees: _emberData.default.hasMany('degree'),
    credentials: _emberData.default.hasMany('credential'),
    subscriptionType: _emberData.default.attr('string'),
    npiNumber: _emberData.default.attr('string'),
    suffixOne: _emberData.default.attr('string'),
    suffixTwo: _emberData.default.attr('string'),
    suffixThree: _emberData.default.attr('string'),
    suffixFour: _emberData.default.attr('string'),
    suffixFive: _emberData.default.attr('string'),
    malpracticeInsuranceInformation: _emberData.default.attr('string'),
    stripeAuthCode: _emberData.default.attr('string'),
    stripeLoginUrl: _emberData.default.attr('string'),
    identificationSrc: _emberData.default.attr('string'),
    documents: _emberData.default.hasMany('document'),

    phoneNumber: _emberData.default.attr('string', { defaultValue: null }),
    phoneNumberVerifiedAt: _emberData.default.attr('date'),
    shouldNotifySms: _emberData.default.attr('boolean'),

    voicePhoneNumber: _emberData.default.attr('string', { defaultValue: null }),
    voicePhoneNumberVerifiedAt: _emberData.default.attr('date'),
    voiceShouldNotifySms: _emberData.default.attr('boolean'),

    dashNotatedPhoneNumber: (0, _modelUtils.dashNotatedPhone)('phoneNumber'),

    tags: _emberData.default.hasMany('tag'),
    isEligible: _emberData.default.attr('boolean'),
    location: _emberData.default.attr('object'),

    didLoad: function didLoad() {
      if (this.get('registrationStep') === 'registration_complete' && this.get('approved') === true) {
        // reload the model every five mins
        var self = this;
        var intervalId = void 0;
        clearInterval(intervalId);
        intervalId = setInterval(function () {
          self.reload();
        }, _environment.default.providerRefreshInterval * 60 * 1000);
      }
    }
  });
});