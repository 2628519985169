define('ceannate-web/initializers/ember-data-data-adapter', ['exports', '@ember-data/debug/setup'], function (exports, _setup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _setup.default;
    }
  });
});