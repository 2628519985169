define('ceannate-web/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    globalError: Ember.inject.service(),
    session: Ember.inject.service(),

    inactivity: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('inactivity').addTimerListener(function () {
        localStorage.setItem('inactivityLogout', true);
        _this.get('session').invalidate();
      });
    },


    errors: Ember.computed.alias('globalError.errors'),

    actions: {
      dismissError: function dismissError(errorMessage) {
        this.get('globalError').removeError(errorMessage);
        if (errorMessage.includes('Not connected to network please check your internet connection.')) {
          window.location.reload();
        }
      }
    }
  });
});