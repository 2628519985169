define('ceannate-web/helpers/format-cents', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCents = formatCents;
  function formatCents(params /*, hash*/) {
    var amount = params[0];

    if (!Number.isInteger(amount)) {
      throw 'format-cents helper expects an integer of cents';
    }
    return (0, _formatMoney.default)(amount / 100);
  }

  exports.default = Ember.Helper.helper(formatCents);
});