define('ceannate-web/models/credit-card', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'form.credit-card-number-invalid'
    }), (0, _emberCpValidations.validator)('valid-credit-card-number', {
      messageKey: 'form.credit-card-number-invalid'
    }), (0, _emberCpValidations.validator)('ds-error')],
    cvv: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '',
      messageKey: 'form.credit-card-cvv-invalid'
    }), (0, _emberCpValidations.validator)('length', {
      messageKey: 'form.credit-card-cvv-invalid',
      min: 3,
      max: 4
    }), (0, _emberCpValidations.validator)('ds-error')],
    expiration: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'form.credit-card-expiration-invalid'
    }), (0, _emberCpValidations.validator)('date', {
      after: 'now',
      precision: 'month',
      format: 'MM/YY',
      messageKey: 'form.credit-card-expiration-invalid'
    }), (0, _emberCpValidations.validator)('ds-error')],
    zip: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'form.credit-card-zip-invalid'
    }), (0, _emberCpValidations.validator)('length', {
      messageKey: 'form.credit-card-zip-invalid',
      is: 5
    }), (0, _emberCpValidations.validator)('ds-error')]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    number: _emberData.default.attr('string'),
    cvv: _emberData.default.attr('string'),
    expiration: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string')
  });
});