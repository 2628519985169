define('ceannate-web/components/client-nav-item/component', ['exports', 'ember-macro-helpers/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',

    blockName: 'c_client-nav-item',

    highlight: false,
    student: null,
    provider: null,
    patientRelationship: null,
    studentFreeSession: (0, _computed.default)('student.{university}', function () {
      return this.get('store').query('student-free-session', {
        filter_student: this.get('student.id'),
        filter_university: this.get('student.university.id'),
        'filter[active]': 1,
        include: 'program'
      });
    }),
    remainingSession: (0, _computed.default)('studentFreeSession', function () {
      var remainingSessions = 0;
      var freeSession = this.get('studentFreeSession').get('firstObject');

      remainingSessions = parseInt(freeSession.get('noOfFreeSessions')) - parseInt(freeSession.get('noOfUsedSessions'));

      return remainingSessions < 0 ? 0 : remainingSessions;
    }),

    linkClass: (0, _computed.default)('blockName', 'isActive', 'bgHighlight', 'highlight', function (blockName, isActive, bgHighlight, highlight) {
      var classes = [blockName, isActive ? blockName + '--active ' : null, bgHighlight ? blockName + '--bg-highlight ' : null, highlight ? blockName + '--highlight ' : null];
      return classes.filter(function (n) {
        return n;
      }).join(' ');
    }),

    router: Ember.inject.service(),

    isActive: (0, _computed.default)('router', 'routeName', 'itemId', 'router.currentURL', function (router, routeName, itemId) {
      return router.isActive(routeName, itemId);
    })
  }).reopenClass({
    positionalParams: ['routeName', 'itemId']
  });
});