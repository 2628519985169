define('ceannate-web/models/payment-option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    licenseType: _emberData.default.belongsTo('license-type'),
    chargeType: _emberData.default.belongsTo('charge-type'),
    allowedSessionLengthMins: _emberData.default.attr('number'),
    sessionCost: _emberData.default.attr('number'),
    settlementDays: _emberData.default.attr('number'),
    noOfFreeSessions: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    sessionPercentage: _emberData.default.attr('number')
  });
});