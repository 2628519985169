define('ceannate-web/components/recent-invoices/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_recent-invoices',
    invoices: null,
    providerId: null,
    studentId: null,
    store: Ember.inject.service(),
    pageNumber: 1,
    pageLimit: 3,

    hasMore: Ember.computed.alias('invoices.meta.cursor.has_more'),
    lastItem: Ember.computed.alias('invoices.meta.cursor.last_item'),

    invoicesSorting: Object.freeze(['createdAt:desc']),
    sortedInvoices: Ember.computed.sort('invoices', 'invoicesSorting'),

    actions: {
      loadMore: function loadMore() {
        var _this = this;

        if (this.get('hasMore')) {
          this.pageNumber = this.pageNumber + 1;
          try {
            this.get('store').query('charge', {
              page: {
                number: this.pageNumber,
                size: 3
              },
              filter_student_id: this.get('studentId'),
              providerId: this.get('providerId'),
              include: 'creator,target,counseling_sessions,provider_university_charge.university,provider_university_charge.program,student_charge'
            }).then(function (res) {
              if (res.toArray() == '') {
                _this.set('hasMore', null);
                _this.set('lastItem', null);
              }
              _this.get('invoices').pushObjects(res.toArray());
              return _this.get('invoices');
            });
          } catch (error) {
            alert(error);
          }
        } else {
          return;
        }
      }
    }
  });
});