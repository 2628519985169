define('ceannate-web/components/provider/subscription-cc-input/component', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bem.default, {
    blockName: 'c_subscription-cc-input',
    mods: ['displayPromoCode:promo', 'disabled'],
    couponCode: null,
    creditCard: null,
    couponCodeValidations: null,
    cardServerErrors: null,
    cardEdited: false,
    cardErrors: Ember.computed('creditCard.validations.attrs.number.isValid', 'cardServerErrors', 'cardEdited', function () {
      if (this.get('creditCard.validations.attrs.number.errors.length')) {
        return this.get('creditCard.validations.attrs.number');
      }
      return this.get('cardEdited') ? null : this.get('cardServerErrors');
    }),

    actions: {
      handleCardEdit: function handleCardEdit() {
        this.set('cardEdited', true);
      }
    }
  });
});