define('ceannate-web/mirage/resources/articles', ['exports', 'ceannate-web/mirage/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockArticles;
  function mockArticles(server) {
    server.get('/articles', function (_ref, _ref2) {
      var articles = _ref.articles;
      var queryParams = _ref2.queryParams;

      var page = +queryParams['page[number]'] || 1,
          size = queryParams['page[size]'] || 25,
          collection = articles.all();

      return (0, _utils.paginatedModelCollection)('articles', collection, page, size);
    });
    server.get('/articles/:id');
    server.post('/articles');
    server.put('/articles/:id');
    server.patch('/articles/:id');
    server.del('/articles/:id');
  }
});