define('ceannate-web/helpers/remove-underscore', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeUnderscore = removeUnderscore;
  function removeUnderscore(params /*, hash*/) {
    return params[0].replace(/_/g, ' ');
  }

  exports.default = Ember.Helper.helper(removeUnderscore);
});