define('ceannate-web/web/provider/workspace/chats/controller', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_bem.default, {
    blockName: 'r_chats',

    removedChat: false,

    filteredThreads: Ember.computed('model.threads.@each.{archived,archivedAt}', function () {
      return this.get('model.threads').filter(function (t) {
        return t.get('archived') === false && !t.get('archivedAt');
      });
    })
  });
});