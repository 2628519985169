define('ceannate-web/mirage/resources/regions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockRegions;
  function mockRegions(server) {
    server.get('/regions');
  }
});