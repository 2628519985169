define('ceannate-web/web/provider/workspace/settings/notifications/controller', ['exports', 'ember-cli-bem/mixins/bem'], function (exports, _bem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_bem.default, {
    blockName: 'r_settings-notifications',
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    infinity: Ember.inject.service(),
    toDestroyUniversityProgram: null,
    provider: Ember.computed.readOnly('model.provider'),
    notifications: Ember.computed.readOnly('model.notifications'),
    providerNotifications: Ember.computed.readOnly('model.providerNotifications'),
    providerNotificationsArray: Ember.computed.readOnly('model.providerNotificationsArray'),

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      onChangeProviderNotifications: function onChangeProviderNotifications() {
        this.notifyPropertyChange('providerNotificationsArray');
      }
    }
  });
});