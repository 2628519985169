define('ceannate-web/validators/valid-credit-card-number', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ValidCreditCardNumber = _base.default.extend({
    stripe: Ember.inject.service(),
    validate: function validate(value, _ref) {
      var disabled = _ref.disabled;

      if (disabled) {
        return false;
      }
      return value ? this.get('stripe').card.validateCardNumber(value.trim()) || 'Not a valid card number' : 'Not a valid card number';
    }
  });

  ValidCreditCardNumber.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = ValidCreditCardNumber;
});