define('ceannate-web/mirage/resources/providers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockProviders;
  function mockProviders(server) {
    server.get('/providers');
    server.get('/providers/:id');
    server.post('/providers');
    server.put('/providers/:id');
    server.patch('/providers/:id');
    server.del('/providers/:id');
  }
});