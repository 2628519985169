define('ceannate-web/mirage/resources/practices', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mockPractices;
  function mockPractices(server) {
    server.get('/practices');
    server.get('/practices/:id');
    server.post('/practices');
    server.put('/practices/:id');
    server.patch('/practices/:id');
    server.del('/practices/:id');
  }
});