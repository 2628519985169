define('ceannate-web/web/verify-email-success/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').addClass('beige');
    },
    deactivate: function deactivate() {
      // eslint-disable-next-line ember-best-practices/no-global-jquery
      Ember.$('body').removeClass('beige');
    }
  });
});